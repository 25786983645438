import React from "react";
import logo from "../../../images/logo.svg";
import { UiState } from "./JobSelection";
import RadioButton from "../components/RadioButton";
import Title from "../components/Title";
import ActionButton from "../components/ActionButton";
import DisabledActionButton from "../components/DisabledActionButton";

export function JobSelectionView(props: {
  jobs: Array<Array<string>>;
  onChange: (e: any) => void;
  error: boolean;
  onJobSelectedSave: () => void;
  jobSelected: string;
  uiState: UiState;
}) {
  return (
    <div className="mx-auto w-px-375 sm:w-px-640 h-full relative z-10 pb-14">
      <div className={"pt-6 md:py-8"}>
        <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
      </div>
      <div
        className={`w-px-375 md:w-px-600 h-full ${
          window.innerHeight < 800 ? "space-y-20" : "space-y-40"
        } sm:space-y-0 sm:pt-20`}
      >
        <div className="pt-8 flex flex-col h-full">
          <Title
            title={"What brings you to RISE?"}
            subtitle={`Choose the one that matters most to you. This will help us personalize your experience.`}
          />
          <div className="mt-4 pt-4">
            <div className="flex flex-col mt-2" onChange={props.onChange}>
              {props.jobs.map(([jobValue, jobLabel], i) => {
                return (
                  <RadioButton
                    jobOption={"jobOption"}
                    jobValue={jobValue}
                    jobLabel={jobLabel}
                    key={i}
                    jobSelected={props.jobSelected}
                  />
                );
              })}
            </div>

            <hr className="w-full border-t border-panelGray-2" />
          </div>
          {props.uiState === "jobSelected" && (
            <div className={"px-3 pt-16 pb-10 w-full "}>
              <ActionButton title="NEXT" onClick={props.onJobSelectedSave} />
            </div>
          )}
          {props.uiState !== "jobSelected" && (
            <div className={"px-3 pt-16 pb-10 w-full"}>
              <DisabledActionButton
                title="NEXT"
                onClick={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
