import React from 'react'

import circadianCurve from '../assets/circadian-curve.png'
import {
  BodyCopy,
  BrandHeadline,
  Center,
  DoubleColumnSlide,
  GoldText,
  ImageContainer,
  LargeHeadline,
  PurpleText,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

import './CircadianRhythm.css'

export const CircadianRhythm: React.FC = () => {
  useSlideTrackEvt('CircadianRhythm')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline ternary>Getting started</BrandHeadline>
        <LargeHeadline>See your Circadian Rhythm.</LargeHeadline>
        <BodyCopy className="ob3__circadian-rhythm__copy">
          We show your Circadian Rhythm as a graph of energy peaks and dips.
        </BodyCopy>
        <BodyCopy className="ob3__circadian-rhythm__copy">
          <GoldText>Energy Peaks</GoldText> represent the times you are primed
          to be alert and productive
        </BodyCopy>
        <BodyCopy className="ob3__circadian-rhythm__copy">
          <PurpleText>Energy Dips</PurpleText> represent the times your body is
          inclined to rest and refuel.
        </BodyCopy>
      </div>
      <Center>
        <ImageContainer>
          <img alt="Circadian curve graphic" src={circadianCurve} />
        </ImageContainer>
      </Center>
    </DoubleColumnSlide>
  )
}
