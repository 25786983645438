import i18n from '../../i18n/i18n'
import Environment from '../../utils/Environment'
import { LocalizedPriceData, AnnualPriceData, SharedPriceData, annualPrices } from './prices'
import { SupportedCountryCode } from '../../utils/currency'
import { ProductCode } from './products'
import { createElement } from 'react'

const studentAnnualPrices: Record<SupportedCountryCode, AnnualPriceData> = {
  US: {
    annual: '$35.99',
    monthly: '$2.99',
  },
  GB: {
    annual: '£29.99',
    monthly: '£2.49',
  },
  CA: {
    annual: 'CA$47.99',
    monthly: 'CA$3.99',
  },
  AU: {
    annual: 'AU$50.99',
    monthly: 'AU$4.25',
  },
  // DE formatting is shared with FR
  DE: {
    annual: '34,99 €',
    monthly: '2,92 €',
  },
}

export const createPriceDisplayData = (
  countryCode: SupportedCountryCode,
  introPrice: number,
  introPriceDisplay: string,
  daysFree: number,
  productCode: ProductCode,
  introPriceFullCharge: string | undefined = undefined
): SharedPriceData => {
  const planPrice = studentAnnualPrices[countryCode].annual
  const monthlyPlanPrice = studentAnnualPrices[countryCode].monthly

  const fullPlanPrice = annualPrices[countryCode].annual
  const fullMonthlyPlanPrice = annualPrices[countryCode].monthly
  const introPriceText = i18n.t('checkout_screen.intro_price_text', {
    daysFree,
    introPrice: introPrice === 0 ? i18n.t('common.free') : introPriceDisplay,
  })

  const strikeOutFullPrice = createElement('del', {}, fullPlanPrice)
  const strikeOutMonthlyPrice = createElement('del', {}, fullMonthlyPlanPrice)
  const planYearPriceText = i18n.t('checkout_screen.plan_year_price_text', {
    price: planPrice,
  })
  const planMonthPriceText = i18n.t('checkout_screen.plan_month_price_text', {
    price: monthlyPlanPrice,
  })

  const pricingText = createElement('p', {}, [
    introPriceText,
    ' ',
    strikeOutFullPrice,
    ' ',
    planYearPriceText,
    ' (',
    strikeOutMonthlyPrice,
    ' ',
    planMonthPriceText,
    ')',
  ])

  const crossOutTitle = createElement('del', {}, introPriceFullCharge)
  const title = introPriceFullCharge
    ? createElement('p', {}, [crossOutTitle, ` ${introPriceDisplay}`])
    : introPriceDisplay

  return {
    price: introPrice,
    daysFree: daysFree,
    sheetLabel: i18n.t('checkout_screen.sheet_label', {
      daysFree,
      planPrice,
    }),
    productCode: productCode,
    title: title,
    subtitle: i18n.t('checkout_screen.days_free', { daysFree }),
    pricingText: pricingText,
  }
}

const displayData_annual_36 = (countryCode: SupportedCountryCode) => {
  return createPriceDisplayData(countryCode, 0, i18n.t('common.Free'), 7, 'annual_36')
}

export const get_localizedPrices_annual_36 = (): LocalizedPriceData => ({
  US: {
    code: Environment.isProduction ? 'price_1POltnH7jib4x1Gy47JHQwZu' : 'price_1POlqhH7jib4x1GyydL0lUL7',
    ...displayData_annual_36('US'),
  },
  GB: {
    code: Environment.isProduction ? 'price_1POltpH7jib4x1GyVs6nUJxv' : 'price_1POlqiH7jib4x1GyluxtW8Jf',
    ...displayData_annual_36('GB'),
  },
  CA: {
    code: Environment.isProduction ? 'price_1POltpH7jib4x1Gyg45u0eYz' : 'price_1POlqhH7jib4x1Gy1IyIvXyh',
    ...displayData_annual_36('CA'),
  },
  AU: {
    code: Environment.isProduction ? 'price_1POltpH7jib4x1GysmYgdoRu' : 'price_1POlqiH7jib4x1Gy74wfj2Ql',
    ...displayData_annual_36('AU'),
  },
  DE: {
    code: Environment.isProduction ? 'price_1POltoH7jib4x1GyEEHEx2N9' : 'price_1POlqhH7jib4x1GyaqgsbZQr',
    ...displayData_annual_36('DE'),
  },
})

const displayData_30d249_annual_36 = (
  countryCode: SupportedCountryCode,
  introPrice: number,
  introPriceDisplay: string,
  fullPriceIntro: string
) => {
  return createPriceDisplayData(countryCode, introPrice, introPriceDisplay, 30, '30d249_annual_36', fullPriceIntro)
}

export const get_localizedPrices_30d249_annual_36 = (): LocalizedPriceData => {
  return {
    US: {
      code: Environment.isProduction ? 'price_1POltfH7jib4x1GyJm4ECZ8A' : 'price_1POlseH7jib4x1GyArKdMORY',
      ...displayData_30d249_annual_36('US', 249, '$2.49', '$4.99'),
    },
    GB: {
      code: Environment.isProduction ? 'price_1POlthH7jib4x1Gyr0y9ZpWp' : 'price_1POlsgH7jib4x1Gyz7pK503s',
      ...displayData_30d249_annual_36('GB', 229, '£2.29', '£3.99'),
    },
    CA: {
      code: Environment.isProduction ? 'price_1POltgH7jib4x1Gyej5CZHya' : 'price_1POlsfH7jib4x1Gyqns9XZCo',
      ...displayData_30d249_annual_36('CA', 349, 'CA$3.49', 'CA$6.99'),
    },
    AU: {
      code: Environment.isProduction ? 'price_1POltgH7jib4x1GyD5zdAff0' : 'price_1POlsgH7jib4x1GyKDG5NwYG',
      ...displayData_30d249_annual_36('AU', 399, 'AU$3.99', 'AU$7.99'),
    },
    DE: {
      code: Environment.isProduction ? 'price_1POltfH7jib4x1GyXLbIoVlN' : 'price_1POlsfH7jib4x1GyvNCqjgEG',
      ...displayData_30d249_annual_36('DE', 199, '1,99 €', '4,99 €'),
    },
  }
}
