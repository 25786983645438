import React from "react";
import RedAlert from "../images/RedAlert.svg";
interface AlertMessageProps {
  title: string;
  subtitle?: string;
}
const AlertMessage = ({ title, subtitle }: AlertMessageProps) => {
  return (
    <div className={`bg-panelGray-1 flex flex-row text-white p-1 mt-6 py-2`}>
      <div className={`pl-2`}>
        <img src={RedAlert} alt="" className="inline w-5" />
      </div>
      <div className={`flex flex-col pl-2`}>
        <text className={`text-sm font-bold pb-1`}>{title}</text>
        <text className={`text-sm`}>{subtitle}</text>
      </div>
    </div>
  );
};

export default AlertMessage;
