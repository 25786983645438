import React from 'react'
import SleepNeedLine from "../images/SleepNeedLine.svg";

const SLEEP_NEED_RANGE = { min: 7 * 60, max: 9 * 60 }
const TYPICAL_SLEEP_NEED_RANGE = { min: 7.5 * 60, max: 8.6667 * 60 }

const getPositionPercent = (sleepNeedMinutes: number): number => {
  const {min, max} = SLEEP_NEED_RANGE
  const percent = (sleepNeedMinutes - min)/(max-min)
  return Math.min(Math.max(percent, 0),1)*100
};

interface SleepNeedChartProps{
  width: number | string,
  sleepNeed:number
}

export default function SleepNeedChart({sleepNeed, width}:SleepNeedChartProps) {
  return (
    <div
    className={'flex self-center relative'}
    style={{ width }}
  >
    <img
      src={SleepNeedLine}
      alt=""
      className={`z-20 absolute`}
      style={{
        marginLeft: -2,
        left: getPositionPercent(sleepNeed) + '%',
        bottom:23
      }}
    />
    <div style={{ width: '100%', height: 48 }}>
      <div
        style={{
          width: '100%',
          height: 25,
          borderRadius: 12.5,
          backgroundColor: '#721CFF',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left:
              getPositionPercent(TYPICAL_SLEEP_NEED_RANGE.min) +
              '%',
            width:
              getPositionPercent(TYPICAL_SLEEP_NEED_RANGE.max) -
              getPositionPercent(TYPICAL_SLEEP_NEED_RANGE.min) +
              '%',
            height: 25,
            borderRadius: 12.5,
            backgroundColor: '#3E1493',
            textAlign: 'center',
          }}
        >
          <text className={`text-white text-xs`} style={{color:'#721CFF'}}>
            Most people
          </text>
        </div>
      </div>
      <text className={`text-white text-xs absolute`}
      style={{left:0}}>
        7h
      </text>
      <text className={`text-white text-xs absolute`}
        style={{left: getPositionPercent(TYPICAL_SLEEP_NEED_RANGE.min) + "%"}}
      >
        7h 30m
      </text>
      <text className={`text-white text-xs absolute`}
        style={{ marginLeft:-45, left: getPositionPercent(TYPICAL_SLEEP_NEED_RANGE.max) + "%"}}
      >
        8h 40m
      </text>
      <text className={`text-white text-xs absolute`}
        style={{right: 0}}
      >
        9h
      </text>
    </div>
  </div>
  )
}
