import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { Nav } from '../onboarding-v3/components'
import appIcon from '../onboarding-v3/assets/app-icon-on-background.png'
import riseBrandLogo from '../onboarding-v3/assets/rise-logo.png'
import Analytics from '../utils/Analytics'

import './KeywordGreeting.css'

enum GreetingVersion {
  Default,
  SleepTracking,
  AppleWatch,
  SleepFaster,
}

type LandingProps = {
  /** Content to render */
  children: React.ReactNode
  /** Headline text */
  headline: string
  /** The version of the greeting */
  version: GreetingVersion
}

/**
 * Get label to identify the greeting version
 *
 * @returns value to be interpolated into analytics event
 */
const versionAnalyticsLabel = (version: GreetingVersion) => {
  switch (version) {
    case GreetingVersion.Default:
      return 'Default'
    case GreetingVersion.AppleWatch:
      return 'Apple watch'
    case GreetingVersion.SleepFaster:
      return 'Fall asleep faster'
    case GreetingVersion.SleepTracking:
      return 'Sleep tracking'
  }
}

/**
 * Generic landing component that accepts dynamic copy
 */
export const GreetingScreen: React.FC<LandingProps> = (props) => {
  const { children, headline, version } = props
  const history = useHistory()

  useEffect(() => {
    Analytics.onReady(() =>
      Analytics.track(`greeting viewed`, {
        greeting: versionAnalyticsLabel(version),
      })
    )
  }, [version])

  const navigateToIntro = () => history.push('/intro-to-rise')
  const navigateToPaywall = () => {
    Analytics.track('Intro To Rise - Rise Checkout Clicked', {})
    history.push(`/offer/intro-to-rise`)
  }

  const greetingCls = classNames('keyword-greeting', {
    'keyword-greeting--fall-asleep-faster':
      version === GreetingVersion.SleepFaster,
    'keyword-greeting--sleep-tracking':
      version === GreetingVersion.SleepTracking,
    'keyword-greeting--watch': version === GreetingVersion.AppleWatch,
  })

  return (
    <div className={greetingCls}>
      <Nav hideBrandLogo transparent onSignUp={navigateToPaywall} />
      <div className="keyword-greeting__container">
        <div className="keyword-greeting__main">
          <div className="keyword-greeting__graphics">
            <img
              alt="RISE app icon"
              className="keyword-greeting__graphics__icon"
              src={appIcon}
            />
            <img
              alt="RISE brand logo"
              style={{ height: '12px' }}
              src={riseBrandLogo}
            />
          </div>
          <p className="keyword-greeting__main__headline">{headline}</p>
          <p className="keyword-greeting__main__body">{children}</p>
        </div>
        <div className="keyword-greeting__cta-container">
          <div className="keyword-greeting__cta-container__gradient" />
          <div className="keyword-greeting__cta-container__cta">
            <button
              className="keyword-greeting__cta-container__cta__btn"
              onClick={navigateToIntro}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Render different views based on the url. The urls will be generated based on ads.
 */
export const KeywordGreeting: React.FC = () => {
  const { path } = useRouteMatch()

  // Make the div#root height 100% so we don't have to use 100vh
  useEffect(() => {
    const el = document.querySelector('#root')
    el?.classList?.add('full-height')

    return () => {
      el?.classList?.remove('full-height')
    }
  }, [])

  return (
    <Switch>
      <Route path={`${path}/sleep-tracker`}>
        <GreetingScreen
          headline="So much more than a Sleep tracker."
          version={GreetingVersion.SleepTracking}
        >
          RISE is the only tool that not only tracks your sleep at night, but
          also gives you actionable info about your energy during your day.
        </GreetingScreen>
      </Route>
      <Route path={`${path}/apple-watch`}>
        <GreetingScreen
          headline="Works seamlessly with your Apple Watch."
          version={GreetingVersion.AppleWatch}
        >
          Sync sleep data from your Apple Watch and access tools and reminders
          that empower you to be super productive during your day.
        </GreetingScreen>
      </Route>
      <Route path={`${path}/fall-asleep-faster`}>
        <GreetingScreen
          headline="Make a habit of falling asleep faster."
          version={GreetingVersion.SleepFaster}
        >
          Use RISE to build habits that help you to fall asleep faster and wake
          up easier. RISE gives you the superpower to see and change your
          biology.
        </GreetingScreen>
      </Route>
      <Route>
        <GreetingScreen
          headline="Take control of your sleep and energy."
          version={GreetingVersion.Default}
        >
          RISE gives you tools, using science-backed methods, to work with your
          body&apos;s biology to feel more energy during the day.
        </GreetingScreen>
      </Route>
    </Switch>
  )
}
