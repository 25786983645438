import * as Sentry from '@sentry/react';

import React, { useEffect, useState } from 'react';
import {
  UserConfiguration,
  fetchUserConfiguration,
} from '../../utils/fetchUserConfiguration';

import Analytics from '../../utils/Analytics';
import MarketingEvents from '../../utils/MarketingEvents';
import Background from '../../components/Background';
import ButtonError from '../../components/ButtonError';
import ButtonProcessing from '../../components/ButtonProcessing';
import ButtonRedeem from '../../components/ButtonRedeem';
import { ErrorMessage } from '../../utils/ErrorMessage';
import List from '../../components/List';
import MaybeStagingBadge from '../../components/MaybeStagingBadge';
import getDiscountOffer from '../../utils/getDiscountOffer';
import logo from '../../images/logo.svg';
import profile from '../../images/profile.svg';
import { redirectToStripe } from '../../utils/redirectToStripe';
import stripeLogo from '../../images/stripe.png';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useTokenFromParams } from '../../hooks/useTokenFromParams';
import { useMarketingSourceCodeFromParams } from '../../hooks/useMarketingSourceCodeFromParams';
import Cookies from 'js-cookie'


const listItems = [
  'Predict daily energy levels',
  'Manage your Sleep Debt',
  '16 scientifically proven habits',
  'Automatically track sleep',
];

type UiState = 'loading' | 'ready' | 'processing' | 'error';
const StartTrialScreen = () => {
  const [userConfig, setUserConfig] = useState<UserConfiguration | null>(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<ErrorMessage | null>(null);
  const token = useTokenFromParams();
  //const couponCode = useCouponCodeFromParams();
  const code = useMarketingSourceCodeFromParams();
  const offer = getDiscountOffer(code);

  useDocumentTitle(`Start Free Trial  | Rise Thanks You`);

  const uiState: UiState = ((): UiState => {
    if (error) return 'error';
    if (userConfig && !processing) return 'ready';
    if (!userConfig) return 'loading';
    if (processing) return 'processing';
    return 'ready';
  })();

  useEffect(() => {
    if (uiState === 'ready') {
      Analytics.track('Web Start Trial Page Ready', {
        stripe_price: userConfig?.stripe_price,
      });
    }
  }, [uiState, userConfig?.stripe_price]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(new Error(error));
    }
  }, [error]);

  async function redirectToStripeCheckout() {
    if (!userConfig) return;
    const personId = userConfig.person_id
    setProcessing(true);
    try {

      // Bunch of analytics and marketing jazz
      Analytics.track('Web Start Trial button clicked', {
        stripe_price: userConfig.stripe_price,
      });
      const currentTimeMs = Math.floor(Date.now())
      const eventId = `checkout_${personId}_${currentTimeMs}`
      MarketingEvents.sendMarketingEvent(MarketingEvents.FB_STANDARD_EVENTS.INITIATE_CHECKOUT, {}, {eventID: eventId})
      MarketingEvents.sendConversionAPIEvent(MarketingEvents.FB_STANDARD_EVENTS.INITIATE_CHECKOUT, eventId, {
            em: userConfig.email,
            external_id: `${userConfig.person_id}`,
            fbp: Cookies.get('_fbp')
        })

      // Now actually redirect to stripe's checkout page
      await redirectToStripe(userConfig);
    } catch (e) {
      setProcessing(false);
      setError(e);
    }
  }

  useEffect(() => {
    if (!token) {
      setError(ErrorMessage.NoToken);
      return;
    }

    fetchUserConfiguration({ token, coupon: offer?.coupon, newUser: true})
      .then((config) => {
        setUserConfig(config);
      })
      .catch((e) => {
        setError(ErrorMessage.FetchingConfiguration);
      });
  }, [offer.coupon, token]);

  return (
    <div className="w-full h-screen mx-auto" id="new-years-2021">
      <Background />

      <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
        <MaybeStagingBadge />
        <div
          className={`w-px-320 ${
            window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
          } sm:space-y-0 sm:pt-48`}
        >
          <div
            className={`opacity-0 border-b text-shadow border-white border-opacity-25 text-white flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
              token && 'opacity-100'
            }`}
            style={{ textShadow: '1px 1px 5px  #000' }}
          >
            <img src={profile} alt="" className="inline mr-2 w-5" />
            {token ?? '...'}
          </div>

          <div className="pt-8 flex flex-col">
            <div style={{ height: 20 }}>
              <img src={logo} alt="RISE" className="self-start" />
            </div>

            {/* {offer.header ?? (
                <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">

                </h1>
            )} */}
            <h1 className="text-white text-3xl font-bold ">
              {offer.header}
            </h1>
            {/* {offer.header == null && (
                <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                    Get {offer.discountPercentage} off
                </h1>
            )} */}

            <List items={listItems} />

            <div className="flex justify-end py-2">
                <div className="badge">EXCLUSIVE OFFER</div>
            </div>
            <div className="text-white pb-3">
              <p>
                  {offer.offerType === 'DISCOUNTED_PRICE' &&
                    (
                        <span className="text-2xl font-medium line-through text-gray-3">
                        {offer.originalPrice}
                        </span>
                    )
                  }
                {' '}
                <span className="text-2xl font-medium  text-white">
                  {offer.price}
                </span>
                <span> per year</span>
              </p>
              <p className="font-light text-gray-3">Cancel anytime during your trial period</p>
            </div>
            {uiState === 'error' && (
              <ButtonError
                testId="errorButton"
                title={error ? error.toUpperCase() : 'Error processing request'}
              />
            )}
            {uiState === 'loading' && (
              <ButtonProcessing
                testId="retrievingButton"
                title="RETRIEVING YOUR OFFER..."
              />
            )}
            {uiState === 'ready' && (
              <ButtonRedeem
                testId="redeemButton"
                title="REDEEM MY OFFER"
                onClick={redirectToStripeCheckout}
              />
            )}
            {uiState === 'processing' && (
              <ButtonProcessing
                testId="processingButton"
                title="PROCESSING..."
              />
            )}
            <div className="pt-8 flex justify-end">
              <img
                src={stripeLogo}
                style={{ width: 150, height: 34 }}
                alt="Powered by Stripe"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartTrialScreen;
