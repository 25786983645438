import { Elements } from '@stripe/react-stripe-js'
import { PaymentRequest, Stripe, StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import MaybeStagingBadge from '../../../components/MaybeStagingBadge'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import PaymentIntentScreen from '../../../screens/StripeCustomCheckout/PaymentIntentScreen'
import Analytics from '../../../utils/Analytics'
import Environment from '../../../utils/Environment'
import { useQueryToken, useValueParams } from '../../../utils/dynamicCodes/hooks'
import { reportRepeatCheckout } from '../../../utils/dynamicCodes/redirects'
import TrialDetailsList from '../components/TrialDetailsList'
import TrialSelectionCells from '../components/TrialSelectionCells'
import {
  createCheckoutDetails,
  createPaymentRequest,
  createStripeInstance,
} from '../setupStripeForEmailConfirmationPaywall'

import './DesignTrialCheckoutScreen.css'
import { getEmailConfirmationProducts, ProductCode } from '../products'
import { SupportedCountryCode } from '../../../utils/currency'
import { useTranslation } from 'react-i18next'
import radialBg from '../assets/radial-background.png'

import nytLogo from '../assets/nyt.png'
import todayLogo from '../assets/today.png'
import hbrLogo from '../assets/hbr.png'
import espnLogo from '../assets/espn.png'
import logo from '../assets/logo@3x.png'

export const stripeStyle = {
  colorPrimary: '#0570de',
  colorBackground: '#30313d',
  colorText: 'white',
  colorDanger: '#df1b41',
  borderRadius: '4px',
  colorPrimaryText: 'white',
  fontSizeSm: '1rem',
}

export type EmailConfirmationCheckoutScreenProps = {
  initialOption: ProductCode
  otherOption: ProductCode
  isStudent: boolean
}

const allowedLocale = (locale: string): SupportedCountryCode => {
  locale = locale.toUpperCase()

  if (['US', 'GB', 'AU', 'CA', 'DE'].includes(locale)) {
    return locale as SupportedCountryCode
  }
  const otherEuroCountries = [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'GR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PT',
    'SK',
    'SI',
    'ES',
  ]
  if (otherEuroCountries.includes(locale)) {
    // FR and DE share EUR formatting and prices
    return 'DE'
  }
  return 'US'
}

const DesignTrialCheckoutScreen = (props: EmailConfirmationCheckoutScreenProps) => {
  const { t, i18n } = useTranslation()

  const { initialOption, otherOption, isStudent } = props
  useDocumentTitle('Rise')
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null)
  const token = useQueryToken()
  const localeParam: string = useValueParams('locale') ?? 'US'
  const locale = allowedLocale(localeParam)
  const history = useHistory()
  const [trialOption, setTrialOption] = useState<ProductCode>(initialOption)
  console.log('DEBUG DesignTrialCheckoutScreen', i18n.language, isStudent)
  const emailConfirmationProducts = getEmailConfirmationProducts()
  const product = emailConfirmationProducts[trialOption][locale]

  const [options, setOptions] = useState<StripeElementsOptions>({})
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined)
  const [paymentRequestLoading, setPaymentRequestLoading] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [duplicateCustomer, setDuplicateCustomer] = useState<boolean>(false)
  const [chargeDate, setChargeDate] = useState<string>('')

  useEffect(() => {
    Analytics.track('Email Confirmation Checkout Viewed', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
      locale,
      isStudent,
    })
  }, [token, locale, isStudent])

  // Setup Client Secret and Stripe Instance
  useEffect(() => {
    const setupStripe = async () => {
      if (token && !stripeInstance) {
        const checkoutDetails = await createCheckoutDetails(token)
        if ('error' in checkoutDetails) {
          if (checkoutDetails.duplicateCustomer) {
            setDuplicateCustomer(true)
          } else {
            Analytics.track('Unable to set up Stripe Custom Checkout Page', {
              env: Environment.getVar('REACT_APP_ENV') ?? 'test',
              email: token,
            })
          }
        } else {
          const stripe = await createStripeInstance()
          if (stripe) {
            setStripeInstance(stripe)
          }
          setClientSecret(checkoutDetails.client_secret)
          setChargeDate(checkoutDetails.charge_date)
          setOptions({
            ...checkoutDetails.options,
            locale: i18n.language as StripeElementLocale,
          })
        }
      }
    }
    if (token) {
      setupStripe()
    }
  }, [i18n.language, stripeInstance, token])

  // Fire analytics for user who has a duplicate customer
  useEffect(() => {
    if (duplicateCustomer) {
      reportRepeatCheckout(product.code, token)
    }
  }, [duplicateCustomer, product.code, token])

  // Create Payment Request - this will be recreated when the user changes the trial option
  useEffect(() => {
    if (stripeInstance) {
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: product.sheetLabel,
            amount: product.price,
          },
        })
      } else {
        createPaymentRequest(stripeInstance, product.code, product.price, product.sheetLabel, locale).then(
          (paymentRequest) => {
            setPaymentRequest(paymentRequest)
            setPaymentRequestLoading(false)
          }
        )
      }
    } else {
      console.log('Unable to setup payment request, stripeInstance is null')
    }
  }, [paymentRequest, product.code, product.price, product.sheetLabel, stripeInstance, trialOption, locale])

  const handleTrialSelection = (trialOption: ProductCode) => {
    Analytics.track('Email Confirmation Trial Selection', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
      trialOption,
    })
    setTrialOption(trialOption)
  }

  const handlePurchaseSuccess = ({ existingAccount }: { existingAccount: boolean }) => {
    Analytics.track('Email Confirmation Purchase Success', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
      trialOption,
    })
    history.push(`/email-confirmation-trial?token=${token}&days=${product.daysFree}`)
  }
  const refToScroll = useRef<HTMLDivElement>(null)

  const onCreditCardChoice = () => {
    refToScroll.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        {/* Prevents zooming into the text input when entering a credit card number */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className={`w-full h-screen mx-auto`}>
        <div
          className="flex flex-col relative"
          style={{
            height: 360,
            backgroundImage: `url(${radialBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="recommendedBar">
            <span>{t('checkout_screen.recommended_by')}</span>
            <div className="recommendedLogos">
              <img src={nytLogo} alt="NYT" style={{ width: 120 }} />
              <img src={todayLogo} alt="Today" style={{ width: 30 }} />
              <img src={hbrLogo} alt="HBR" style={{ width: 35 }} />
              <img src={espnLogo} alt="ESPN" style={{ width: 50 }} />
            </div>
          </div>
          <div className="flex flex-col flex-1 px-10 items-center justify-center">
            <img src={logo} style={{ width: 60 }} alt="Rise" />
            <p className="text-white pt-2 pb-1 text-2xl leading-tight text-center">{t('checkout_screen.headline')}</p>
            <p className="text-white text-sm leading-tight text-center">{t('checkout_screen.subtitle')}</p>
          </div>
        </div>

        <div
          ref={refToScroll}
          style={{
            position: 'absolute',
            left: 0,
            top: '25vh',
            backgroundColor: 'transparent',
            height: '1px',
            width: '100%',
          }}
        />
        <div className={`design-trial-checkout-screen-content`}>
          <div style={{ minHeight: window.innerHeight }} className={`h-screen w-full`}>
            <div className="flex flex-col">
              <div style={{ backgroundColor: '#1D1D23' }}>
                <TrialSelectionCells
                  onSelect={handleTrialSelection}
                  selection={trialOption}
                  products={[
                    emailConfirmationProducts[initialOption][locale],
                    emailConfirmationProducts[otherOption][locale],
                  ]}
                  isStudent={isStudent}
                />
              </div>
              <div style={{ paddingTop: '12px' }}>
                <TrialDetailsList trialOption={emailConfirmationProducts[trialOption][locale]} />
              </div>
              <div>
                {duplicateCustomer ? (
                  <div className={'text-white'}>{t('checkout_screen.your_subscription_is_active')}</div>
                ) : Object.keys(options).length && stripeInstance ? (
                  <div className={'pl-5 pr-5'}>
                    <Elements stripe={stripeInstance} options={options}>
                      <PaymentIntentScreen
                        code={product.code}
                        paymentRequest={paymentRequest}
                        clientSecret={clientSecret}
                        daysFree={product.daysFree}
                        chargeDate={chargeDate}
                        dynamicCheckout={true}
                        onPurchaseSuccess={handlePurchaseSuccess}
                        paymentRequestLoading={paymentRequestLoading}
                        onCreditCardChoice={onCreditCardChoice}
                        ctaText={
                          product.daysFree === 7
                            ? t('checkout_screen.redeem_7_days_free')
                            : t('checkout_screen.redeem_x_days_free', {
                                daysFree: product.daysFree,
                              })
                        }
                      />
                    </Elements>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <MaybeStagingBadge />
      </div>
    </>
  )
}

export default DesignTrialCheckoutScreen
