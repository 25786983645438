import * as Sentry from '@sentry/react';

import React, { ReactElement, useEffect, useState } from 'react';

import Analytics from '../utils/Analytics';
import ButtonProcessing from './ButtonProcessing';
import initBranch from '../utils/initBranch';

function sendSMS(phone: string) {
  return new Promise((resolve, reject) => {
    const linkData = {
      tags: [],
      channel: 'Website',
      feature: 'TextMeTheApp',
      data: {
        foo: 'bar',
      },
    };
    const options = {};
    var callback = function (err: any, result: any) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    };

    window.branch.sendSMS(phone, linkData, options, callback);
  });
}

type FormState = 'processing' | 'error' | 'ready' | 'success';
function AppSMSForm(): ReactElement {
  const [phone, setPhone] = useState<string>('');
  const [formState, setFormState] = useState<FormState>('ready');
  useEffect(() => {
    initBranch();
  }, []);
  async function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    setFormState('processing');
    e.preventDefault();
    try {
      if (!phone) {
        throw new Error('No phone number provided');
      }
      await sendSMS(phone);
      setFormState('success');
      Analytics.track('SMS form submitted');
    } catch (error) {
      setImmediate(() => {
        setFormState('error');
      });
      Sentry.captureException(error);
      Analytics.track('Error submitting form', {
        error: error?.message,
      });
    }
  }
  if (formState === 'success') {
    return (
      <div className="text-white text-lg py-8 flex items-center align-middle justify-center">
        <div className="text-4xl inline-block pr-2 ">😴</div>
        <div>
          Sleep well
          <div className="text-sm">Your download link is on its way.</div>
        </div>
      </div>
    );
  }
  return (
    <form id="sms-form" onSubmit={handleFormSubmit}>
      <label className="">Mobile</label>
      <label className="block text-white text-sm mb-2 w-full" htmlFor="phone">
        Mobile #
        <input
          id="phone"
          className={`block max-w-xs text-left pt-4 pb-2 px-2 bg-black text-white border-b-2 border-purple-1 text-lg  rounded-t-md
          focus:outline-none focus:border-purple-2 focus:bg-gray-1 focus:bg-opacity-75 ${
            formState === 'error' && 'border-red-1'
          } `}
          name="phone"
          type="tel"
          placeholder="(555) 123-4567"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </label>
      <br />
      {formState === 'processing' && (
        <ButtonProcessing testId="smsProcessingButton" title="SENDING SMS" />
      )}
      {formState === 'ready' && (
        <input className={`btn h-16 `} type="submit" value="SEND SMS" />
      )}
      {formState === 'error' && (
        <input
          className={`btn h-16  animate-shake-once`}
          type="submit"
          value="SEND SMS"
        />
      )}
    </form>
  );
}

export default AppSMSForm;
