import React, { useEffect } from 'react'
import i18n, { availableLanguages } from './i18n'
import { useLanguageParam } from './useLanguageParam'
import Analytics from '../utils/Analytics'

interface Props {
  children: React.ReactChildren | React.ReactChild
}

/**
 * Wraps the app and handles the language change based on the `lang` param.
 * If a `lang` param is present it will set/update it. It sets the language
 * in the session storage and in the i18n config.
 * If the `lang` param is not present it will use the previously set language
 * or the default language set in the i18n config.
 *
 * This allows us to set the language via a query param, but not have to
 * plumb the param through on each request and allows the user to refresh the
 * page without losing their language setting.
 */
export const LocalizationHandler: React.FC<Props> = ({ children }) => {
  const lang = useLanguageParam()

  useEffect(() => {
    const prevLang = sessionStorage.getItem('lang')
    if (lang) {
      if (availableLanguages.includes(lang) && lang !== i18n.language) {
        sessionStorage.setItem('lang', lang)
        i18n.changeLanguage(lang)
        Analytics.track('Language changed', { language: lang })
      }
    } else {
      if (prevLang && i18n.language !== prevLang) {
        i18n.changeLanguage(prevLang)
        Analytics.track('Language changed', { language: lang })
      }
    }
  }, [lang])

  return <>{children}</>
}
