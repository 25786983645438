import React, {ReactElement} from "react";
import {useHistory} from "react-router-dom";
import {SelfReportedConfirmationView} from "./SelfReportedConfirmationView";
import {useStore} from "../../../state/webOnboarding2";
import {
    getLabelForSleepTime,
    inBedOptions,
    screenConfigs,
    wakeUpOptions
} from "../../../utils/webOnboarding2/constants";
import { withRedirectAndTitle } from "../withRedirectAndTitle";
import { sleepDurationIsTooShort } from "../../../utils/webOnboarding2/api";
import { sendAnalytics } from "../../../utils/webOnboarding2/webOnboardingAnalytics";

export type UiState = 'ready' | 'processing' | 'error';

function SelfReportedConfirmation(): ReactElement {
    const history = useHistory();

    const {
        error,
        isFetching,
        weekdayWakeUp,
        weekdayInBed,
        freedayWakeUp,
        freedayInBed,
        sendSelfReportedSleepTimes,
    } = useStore(state => ({
        error: state.error,
        setError: state.setError,
        isFetching: state.isFetching,
        weekdayWakeUp: state.weekdayWakeUp,
        weekdayInBed: state.weekdayInBed,
        freedayWakeUp: state.freedayWakeUp,
        freedayInBed: state.freedayInBed,
        sendSelfReportedSleepTimes: state.sendSelfReportedSleepTimes,
    }))


    const uiState: UiState = ((): UiState => {
        if (isFetching) {
            return 'processing';
        }
        if (error) {
            return 'error';
        }
        return 'ready';
    })();

    const timesAreValid: boolean = ((): boolean => {
        return !sleepDurationIsTooShort(weekdayInBed, weekdayWakeUp) &&
            !sleepDurationIsTooShort(freedayInBed, freedayWakeUp)
    })();

    async function sendSelfReportedTimes() {
        await sendSelfReportedSleepTimes()
        const error = useStore.getState().error
        if (!error) {
            history.push(screenConfigs.sleepNeed.url)
        }
    }

    const editWeekDay = () => {
        sendAnalytics('Sleep survey confirmation weekday edit clicked')
        history.push(screenConfigs.selfReportedWeekday.url)
    }

    const editFreeDay = () => {
        sendAnalytics('Sleep survey confirmation weekend edit clicked')
        history.push(screenConfigs.selfReportedFreeday.url)
    }

    return (
        <SelfReportedConfirmationView
            onClick={() => sendSelfReportedTimes()}
            error={error}
            isFetching={isFetching}
            uiState={uiState}
            weekDayInBed={getLabelForSleepTime(inBedOptions, weekdayInBed)}
            weekDayWakeup={getLabelForSleepTime(wakeUpOptions, weekdayWakeUp)}
            freeDayInBed={getLabelForSleepTime(inBedOptions, freedayInBed)}
            freeDayWakeup={getLabelForSleepTime(wakeUpOptions, freedayWakeUp)}
            timesAreValid={timesAreValid}
            goToWeekDayEdit={editWeekDay}
            goToFreeDayEdit={editFreeDay}
        />
    );
};

export default withRedirectAndTitle(SelfReportedConfirmation, screenConfigs.selfReportedConfirmation.documentTitle);
