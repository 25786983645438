import React from 'react'

import phone from '../assets/phones/track-progress-one-phone.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const TrackProgressOne: React.FC = () => {
  useSlideTrackEvt('TrackProgressOne')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline secondary>Using RISE</BrandHeadline>
        <LargeHeadline>Keep track of your weekly progress.</LargeHeadline>
        <BodyCopy>
          Monitoring your total sleep debt over time will give you an accurate
          picture of how much sleep you need in order to feel your best.
        </BodyCopy>
      </div>
      <div>
        <GradientPhone>
          <img alt="Progress on RISE app" src={phone} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
