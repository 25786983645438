import React, { HTMLAttributes } from 'react'

import './ImageContainer.css'

export const ImageContainer: React.FC<HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const { className, ...rest } = props
  const cls =
    'ob3__image-container' + (className !== undefined ? ` ${className}` : '')

  return <div className={cls} {...rest} />
}
