import React from 'react'

import './LawSidebar.css'

import lawOneGraphic from '../assets/law-one-graphic.png'
import lawTwoGraphic from '../assets/law-two-graphic.png'

type LawSidebarProps = {
  /** Show a condensed version of the sidebar */
  condensed?: boolean
  /** Law number */
  num: 1 | 2
}

export const LawSidebar: React.FC<LawSidebarProps> = (props) => {
  const { condensed = false, num } = props

  return (
    <div className="ob3__law-sidebar">
      <p className="ob3__law-sidebar__law">LAW</p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p className="ob3__law-sidebar__number">{num}</p>
        <div style={{ flex: '1' }}>
          <img
            alt={num === 1 ? 'Sleep debt graphic' : 'Circadian Rhythm graphic'}
            src={num === 1 ? lawOneGraphic : lawTwoGraphic}
            // Wave on slide 2 needs to be shifted slightly to align with circles
            style={{ marginLeft: num === 2 ? '8px' : 0 }}
          />
        </div>
      </div>
      <p className="ob3__law-sidebar__label">
        {num === 1 ? 'Sleep Debt' : 'Circadian Rhythm'}
      </p>
      {!condensed ? (
        <p className="ob3__law-sidebar__copy">
          {num === 1
            ? 'Research has shown the only sleep score worth tracking is Sleep Debt. It is an objective measure of sleep deprivation – the amount of sleep you owe your body.'
            : "Everything is easier when you understand the unique clock in your head that determines when you'll feel your best, when to rest and sleep."}
        </p>
      ) : null}
    </div>
  )
}
