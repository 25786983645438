import React from 'react'

import phone from '../assets/phones/enable-notifications-phone.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const EnableNotifications: React.FC = () => {
  useSlideTrackEvt('EnableNotifications')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline secondary>Using RISE</BrandHeadline>
        <LargeHeadline>
          Stick to a routine with custom notifications.
        </LargeHeadline>
        <BodyCopy>
          Timely reminders, including your best time to fall asleep, make it
          easy to adhere to your new habits to ensure you will get the sleep you
          need so you feel energized throughout your day.
        </BodyCopy>
      </div>
      <div>
        <GradientPhone>
          <img alt="Enable notifications on RISE app" src={phone} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
