/**
 *  Helper to add delay in the spring animations.
 *  There is probably a better way to do this, but this works.
 */
 export const delay = (duration: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};