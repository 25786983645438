import React, { HTMLAttributes } from 'react'

import './Content.css'

/**
 * Content wrapper designed to control content width based on device size
 */
export const Content: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className, ...rest } = props
  const cls =
    'ob3__text-content' + (className !== undefined ? ` ${className}` : '')

  return <div className={cls} {...rest} />
}
