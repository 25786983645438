import React from 'react'

import phone from '../assets/phones/customize-habits-phone.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const CustomizeHabits: React.FC = () => {
  useSlideTrackEvt('CustomizeHabits')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline secondary>Using RISE</BrandHeadline>
        <LargeHeadline>Create new healthy habits.</LargeHeadline>
        <BodyCopy>
          Sleep Hygiene includes changes you can make throughout the day, not
          just at night. Select from a wide list of habits that can be
          incorporated during the energy peaks and dips of your Circadian
          Rhythm.
        </BodyCopy>
      </div>
      <div>
        <GradientPhone>
          <img alt="Customize habits on RISE app" src={phone} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
