import React from "react";

interface DisabledActionButtonProps {
  title: string;
  // NOTE: not sure if we need an onClick for this
  // Do we want the user to be able to see the click and see that it does not do anything?
  onClick: () => void;
}
const DisabledActionButton = ({
  title,
  onClick,
}: DisabledActionButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`bg-panelGray-2 rounded-md text-md tracking-widest text-gray-2 p-5 w-full`}
    >
      {title}
    </button>
  );
};

export default DisabledActionButton;
