import React, { ReactElement } from 'react';

import Environment from '../utils/Environment';

function MaybeStagingBadge(): ReactElement | null {
  if (Environment.isProduction) return null;
  return (
    <div
      id="stagingBadge"
      className="badge bg-red-1 border-red-2 absolute mt-2 top-0 right-0"
    >
      STAGING
    </div>
  );
}

export default MaybeStagingBadge;
