import React, { useEffect } from 'react'

import userChase from '../assets/users/chase-m.png'
import userMorgane from '../assets/users/morgane-m.png'
import bedCurve from '../assets/bed-curve.png'
import circadianCurve from '../assets/circadian-curve.png'
import heroArc from '../assets/hero-arc.png'
import inAppInteraction from '../assets/in-app-interactions.png'
import lawOneGraphic from '../assets/law-one-graphic.png'
import lawTwoGraphic from '../assets/law-two-graphic.png'
import phoneWatch from '../assets/phone-watch.png'
import phoneZIcon from '../assets/phone-z.png'
import radialBg from '../assets/radial-bg.png'
import riseLogo from '../assets/rise-logo.png'
import satisfactionBadge from '../assets/satisfaction-badge.png'
import sleepNeed from '../assets/sleep-need.png'
import sleepNeedSm from '../assets/sleep-need-sm.png'
import trustedBy from '../assets/trusted-by.png'
import watchIcon from '../assets/watch-icon.png'

import phone1 from '../assets/phones/circadian-rhythm-to-schedule-phone.png'
import phone2 from '../assets/phones/customize-habits-phone.png'
import phone3 from '../assets/phones/enable-notifications-phone.png'
import phone4 from '../assets/phones/learn-phone.png'
import phone5 from '../assets/phones/see-how-rise-can-help-phone.png'
import phone6 from '../assets/phones/track-progress-one-phone.png'
import phone9 from '../assets/phones/track-sleep-debt-phone.png'

/**
 * Preload images so we don't end up with a jumpy ui
 */
export const ImagePreload: React.FC = () => {
  useEffect(() => {
    // List of images to preload
    const imagesToPreload: string[] = [
      phoneWatch,
      userChase,
      userMorgane,
      bedCurve,
      circadianCurve,
      heroArc,
      inAppInteraction,
      lawOneGraphic,
      lawTwoGraphic,
      phoneZIcon,
      radialBg,
      satisfactionBadge,
      riseLogo,
      sleepNeed,
      sleepNeedSm,
      trustedBy,
      watchIcon,
      phone1,
      phone2,
      phone3,
      phone4,
      phone5,
      phone6,
      phone9,
    ]

    for (const img of imagesToPreload) {
      new Image().src = img
    }
  }, [])

  return null
}
