import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locale/en.json'
import de from './locale/de.json'
import fr from './locale/fr.json'
import es from './locale/es.json'

const resources = { en, de, fr, es }
export const availableLanguages = ['en', 'de', 'fr', 'es']

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en', //Language will updated via the `lang` query param in the LocalizationHandler
  resources,
  interpolation: {
    defaultVariables: {
      brand: 'RISE',
      marque: 'RISE',
      Marke: 'RISE',
      marca: 'RISE',
      appleHealth: 'Apple Health',
      fitbit: 'Fitbit',
      appleWatch: 'Apple Watch',
      fullBrand: 'Rise Sleep',
    },
    escapeValue: false,
  },
})

export default i18n
