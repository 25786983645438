import React from 'react'

import {
  useSourceFromParams,
  useTokenFromParams,
} from '../../hooks/useTokenFromParams'
import NewUserOffer from './NewUserOffer'
import { DynamicCodeProps } from '../../utils/dynamicCodes/types'
import StripeTestFromAppOffer from './StripeTestFromAppOffer'

import WebOnboardingV3Paywall from '../../onboarding-v3/paywall/WebOnboardingV3Paywall'
import { useOffer } from '../../utils/dynamicCodes/hooks'
import LoadingSpinner from '../../components/LoadingSpinner'

const DynamicOfferScreen = (props: DynamicCodeProps) => {
  const email = useTokenFromParams()
  const source = useSourceFromParams()
  const code = props.match.params.name
  const offer = useOffer(code)

  if (email && source === 'app') {
    return <StripeTestFromAppOffer {...props} />
  }

  if (offer === null) {
    return <LoadingSpinner uiState={'loading'} />
  }

  if (offer.whitelistStatus) {
    return <NewUserOffer {...props} />
  }

  return <WebOnboardingV3Paywall {...props} offer={offer} />
}

export default DynamicOfferScreen
