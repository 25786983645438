export default function getRedirectUrlsForNewSignUpTrial(email: string, coupon: string) {
  let cancel = document?.location?.href; // what should this be?

  const redirect = {
      success: `${document.location.origin}/sign-up-success?user_id=${email}&code=${coupon}`,
      cancel,
  }

  return redirect;
}
