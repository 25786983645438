import React, { ReactElement } from 'react';
import './Background.css';

interface Props {
  disableImage?: boolean;
}
function Background({ disableImage }: Props): ReactElement {
  return (
    <div>
      <div className="absolute w-full h-full bg-opacity-50 bgGradient z-0" />
      {!disableImage && (
        <div className={`absolute w-full h-full bg-opacity-50 bgHero z-0 `} />
      )}
      <div className="absolute w-full h-full bg-opacity-50 bgFade z-0" />
    </div>
  );
}

export default Background;
