import { useLocation } from 'react-router-dom'

/**
 * Hook to get the language param from the URL.
 * @returns The language param from the URL or undefined if it is not present.
 */
export const useLanguageParam = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const lang = params.get('lang') ?? undefined
  return lang
}
