import React from 'react'

import trustedBy from '../assets/trusted-by.png'
import phone from '../assets/phones/see-how-rise-can-help-phone.png'
// import quarterPhone from '../assets/quarter-phone.png'
import { DoubleColumnSlide, LargeHeadline, GradientPhone } from '../components'
import { useSlideTrackEvt } from '../hooks'

import './SeeHowRiseCanHelp.css'

export const SeeHowRiseCanHelp: React.FC = () => {
  useSlideTrackEvt('SeeHowRiseCanHelp')

  return (
    <DoubleColumnSlide>
      <div>
        <img
          alt="Trusted by 1 million early risers"
          className="ob3__trust-graphic"
          src={trustedBy}
          width="180"
        />
        <LargeHeadline>
          See how RISE
          <br />
          can help you throughout
          <br /> your day.
        </LargeHeadline>
      </div>
      <div>
        <GradientPhone>
          <img alt="Rise app" src={phone} />
        </GradientPhone>
        {/* <div className="ob3__how-can-rise-help__background" />
        <img
          alt="RISE energy schedule on device"
          className="ob3__how-can-rise-help__phone"
          src={quarterPhone}
        /> */}
      </div>
    </DoubleColumnSlide>
  )
}
