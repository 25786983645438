export const minutesToHoursAndMinutes = (totalMinutes : number): string => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`
}

export const minutesToHoursWithDecimal = (totalMinutes : number, withLabel: boolean = false): string => {
    const hours = totalMinutes / 60;
    if (withLabel) {
        return `${hours.toFixed(1)}hrs`
    }
    return `${hours.toFixed(1)}`
}

export const asPercent = (decimalNumber : number): string => {
    const asPercent = decimalNumber * 100;
    return `${asPercent.toFixed(0)}%`
}

export const millisecondsToUserTime = (milliseconds : number): string => {
    const date = new Date(milliseconds)
    // ensuring 12 hour representation - should this be more tailored to 24h users?
    return  date.toLocaleString([], { hour: 'numeric', minute: 'numeric', hour12: true })
}

/** Convert minutes to decimal hours, rounded to 10th of an hour */
export const minutesToDecimal = (minutes: number): number => Math.round((minutes / 60) * 10) / 10;
