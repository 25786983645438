import React from "react";
import logo from "../../../images/logo.svg";
import RadioButton from "../components/RadioButton";
import MoonIcon from "../images/MoonIcon.svg";
import SunIcon from "../images/SunIcon.svg";
import ActionButton from "../components/ActionButton";
import DisabledActionButton from "../components/DisabledActionButton";
import AlertMessage from "../components/AlertMessage";
import { sleepDurationIsTooShortWithType } from "../../../utils/webOnboarding2/api";

interface NumberStringTuple extends Array<string|number>{0:number; 1:string}

export function SelfReportedWeekdayView(props: {
  inBedOptions: Array<NumberStringTuple>;
  wakeUpOptions: Array<NumberStringTuple>;
  onChangeWakeUp: (e: any) => void;
  onChangeInBed: (e: any) => void;
  error: boolean;
  onClick: () => void;
  wakeUpSelected: number | null;
  inBedSelected: number | null;
}) {

  const tooShortError = sleepDurationIsTooShortWithType(props.inBedSelected, props.wakeUpSelected, 'weekday')
  const cannotProgress = props.wakeUpSelected === null || props.inBedSelected === null || tooShortError

  return (
    <div className="mx-auto w-px-375 sm:w-px-640 relative z-10 pb-2">
      <div className={"pt-6 md:py-8"}>
        <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
      </div>
      <div
        className={`w-px-375 md:w-px-600 ${
          window.innerHeight < 800 ? "space-y-20" : "space-y-40"
        } sm:space-y-0 sm:pt-20`}
      >
        <div className="pt-8 flex flex-col">
          <text className="text-white pt-2 pb-3 text-xl font-bold px-3">
            On a normal weekday, what is your approximate sleep schedule?
          </text>
          <div className="mt-4">
            <div className={`flex flex-row align-center px-3`}>
              <img src={MoonIcon} alt="" className="inline w-3" />
              <text className="text-lg text-white pl-1">In bed</text>
            </div>
            <div className="mt-2" onChange={props.onChangeInBed}>
              {props.inBedOptions.map(([value, label], i) => {
                return (
                  <RadioButton
                    jobOption={"inBedOption"}
                    jobValue={value}
                    jobLabel={label}
                    key={i}
                    jobSelected={props.inBedSelected}
                  />
                );
              })}
            </div>
          </div>
          <hr className="w-full border-t border-panelGray-2" />
          <div className="mt-4 pt-4">
            <div className={`flex flex-row align-center px-3`}>
              <img src={SunIcon} alt="" className="inline w-5" />
              <text className="text-lg text-white pl-1">Wake up</text>
            </div>

            <div className="mt-2" onChange={props.onChangeWakeUp}>
              {props.wakeUpOptions.map(([value, label], i) => {
                return (
                  <RadioButton
                    jobOption={"wakeUpOption"}
                    jobValue={value}
                    jobLabel={label}
                    key={i}
                    jobSelected={props.wakeUpSelected}
                  />
                );
              })}
            </div>
          </div>

          <hr className="w-full border-t border-panelGray-2" />

          <div className={`py-6`}>
            {tooShortError && (
              <AlertMessage
                title={"Sleep time is too short."}
                subtitle={
                  "Please update your sleep time so that is it longer than 3 hours."
                }
              />
            )}

            {cannotProgress ?  (
                <div className={"px-3 py-4"}>
                  <DisabledActionButton
                      title="NEXT"
                      onClick={() => {}}
                  />
                </div>
            ) : (
              <div className={"px-3 py-4"}>
                <ActionButton title="NEXT" onClick={props.onClick} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
