import React from 'react'

import phoneWatch from '../assets/phone-watch.png'
import phoneZIcon from '../assets/phone-z.png'
import watchIcon from '../assets/watch-icon.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

import './Devices.css'

export const Devices: React.FC = () => {
  useSlideTrackEvt('Devices')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline ternary>Getting started</BrandHeadline>
        <LargeHeadline>Use the devices you already own.</LargeHeadline>
        <BodyCopy>
          RISE can use a few different methods to track your sleep:
        </BodyCopy>

        <div className="ob3__devices__information">
          <div className="ob3__devices__information__icon-container">
            <img alt="Sleep icon" src={phoneZIcon} width="35" />
          </div>

          <div className="ob3__devices__information__copy">
            <strong>Smart Phone (most common, no wearable required)</strong>
            <br />
            With <em>Nightstand Mode</em>, our algorithm interprets phone
            movement to detect when you fell asleep and woke up. We can also
            integrate with existing Sleep tracking Apps on your phone.
          </div>
        </div>
        <div className="ob3__devices__information">
          <div className="ob3__devices__information__icon-container">
            <img alt="Watch icon" src={watchIcon} width="28" />
          </div>

          <div className="ob3__devices__information__copy">
            <strong>Wearable</strong>
            <br />
            Wear a Apple Watch, Fitbit or other Apple Health compatible sleep
            tracking device for optimal precision. We&apos;ll check the Health
            app in the morning and automatically import your sleep data.
          </div>
        </div>
      </div>
      <div>
        <GradientPhone>
          <img alt="Rise on mobile devices" src={phoneWatch} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
