import React from "react";
import CloseIcon from "../images/CloseIcon.svg";

interface InfoModalProps {
  modalType: "calculation" | "learnMore";
  handleClose: () => void;
}

const InfoModal = ({ modalType, handleClose }: InfoModalProps) => {
  return (
    <div
      className="fixed z-30 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-panelGray-1 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-panelGray-1 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className={`flex justify-end`}>
              <button onClick={handleClose}>
                <img src={CloseIcon} alt="" className="inline w-4" />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-white pr-4"
                  id="modal-title"
                >
                  {modalType === "calculation"
                    ? calculationModalText.title
                    : learnMoreModalText.title}
                </h3>
                <div className="mt-2 pr-4 pb-8">
                  <p className="text-sm text-white">
                    {modalType === "calculation"
                      ? calculationModalText.body1
                      : learnMoreModalText.body1}
                    <br />
                    <br />
                    {modalType === "calculation"
                      ? calculationModalText.body2
                      : learnMoreModalText.body2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;

const calculationModalText = {
  title: "How is my sleep need calculated?",
  body1: `We estimate your sleep need by comparing how much sleep you usually get versus when you sleep longer than usual-something we call a sleep rebound. This is because your body tries to make up the sleep it needs when you sleep longer than usual.`,
  body2: `Your sleep need estimate will be updated after you log into the app and allow RISE to access data already on your phone.`,
};

const learnMoreModalText = {
  title: "Sleep need accuracy",
  body1: `Your personal sleep need is unique and changes very little throughout your life. The estimate you see now will improve after you log into the RISE app. Sleep analysis, steps, and motion data already on your phone will allow RISE To understand your sleep patterns and give you more accurate insights.`,
  body2: `If your sleep need still doesn’t feel accurate, you can adjust it in the profile of the RISE app.`,
};
