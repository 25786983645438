import { GetState, SetState } from "zustand";
import axios from "axios";
import { ApiError, apiUrls, getLabelForSleepTime, inBedOptions, screenConfigs, wakeUpOptions } from "./constants";
import { State } from "../../state/webOnboarding2";
import Environment from "../Environment";
import {
  identifyUserForAnalytics,
  sendAnalytics,
  sendFacebookConversionEvent,
  sendTooShortSleepEvent,
  SleepType
} from "./webOnboardingAnalytics";

const config = (get: GetState<State>) => {
  return {
    headers: {
      Authorization: `Bearer ${get().token}`,
    },
  };
};

const marketingCodeInfo = (get: GetState<State>) : object  => {
  const code = get().code;
  return {
    'marketingSourceCode': code ?? 'web_onboarding'
  }
}

export const createAccount = async (
  set: SetState<State>,
  get: GetState<State>
) => {
  set({ isFetching: true, error: false, errorInfo: ApiError.None });
  const email = get().email;

  try {
    const data = {
      email,
      signUpSource: "web",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const res = await axios.post(apiUrls.createAccount, data);

    if (res.status === 200) {
      const { person_id: personId, token } = res.data;
      set({ token, personId, signUpSuccess: true });
      const marketingSourceCodeInfo = marketingCodeInfo(get);
      identifyUserForAnalytics(marketingSourceCodeInfo);
      sendAnalytics('Web Sign Up Success', marketingSourceCodeInfo);
      sendFacebookConversionEvent(personId, email);
    } else {
      set({ error: true });
    }
  } catch (err) {
    if (err.response?.status !== 400) {
      set({ errorInfo: ApiError.NetworkError })
      sendAnalytics('Sign up Error Web', { errorType: 'API Error' });
    } else {
      set({ errorInfo: ApiError.EmailTaken })
      sendAnalytics('Sign up Error Web', { errorType: 'Email Taken' });
    }
    set({ error: true });
  } finally {
    set({ isFetching: false });
  }
};

export const sendJob = async (set: SetState<State>, get: GetState<State>) => {
  set({ isFetching: true, error: false });
  const jobSelected = get().jobSelected;
  if (jobSelected === "") {
    set({ error: true });
  } else {
    const data = [
      {
        selected: true,
        profile_tag_key: jobSelected,
      },
    ];
    try {
      const url = apiUrls.sendJob;
      const response = await axios.post(url, data, config(get));
      if (response.status !== 200) {
        set({ error: true });
      } else {
        identifyUserForAnalytics({ goal: jobSelected })
      }
    } catch (err) {
      set({ error: true });
    }
  }
  set({ isFetching: false });
};

const hasNotReportedAllSleepTimes = (get: GetState<State>): boolean => {
  const allSleepTimes = [
    get().freedayWakeUp,
    get().freedayInBed,
    get().freedayInBed,
    get().weekdayInBed,
  ]
  return allSleepTimes.some(time => time === null)
}

export const sendSelfReportedSleepTimes = async (
  set: SetState<State>,
  get: GetState<State>
) => {
  set({ isFetching: true, error: false });

  if (hasNotReportedAllSleepTimes(get)) {
    set({ error: true });
  } else {
    const data = {
      weekday_bedtime: {
        from: get().weekdayInBed as number - 0.5,
        to: get().weekdayInBed as number + 0.5,
      },
      weekday_waketime: {
        from: get().weekdayWakeUp as number - 0.5,
        to: get().weekdayWakeUp as number + 0.5,
      },
      free_day_bedtime: {
        from: get().freedayInBed as number - 0.5,
        to: get().freedayInBed as number + 0.5,
      },
      free_day_waketime: {
        from: get().freedayWakeUp as number - 0.5,
        to: get().freedayWakeUp as number + 0.5,
      },
    };
    try {
      const response = await axios.post(
        apiUrls.calculateSelfReportedSleep,
        data,
        config(get)
      );
      if (response.status !== 201) {
        set({ error: true });
      } else {
        sendAnalytics('Sleep survey submitted', {
          weekday_time_bed: getLabelForSleepTime(inBedOptions, get().weekdayInBed),
          weekday_time_wake: getLabelForSleepTime(wakeUpOptions, get().weekdayWakeUp),
          weekend_time_bed: getLabelForSleepTime(inBedOptions, get().freedayInBed),
          weekend_time_wake: getLabelForSleepTime(wakeUpOptions, get().freedayWakeUp),
        })
      }
      const { sleep_need, sleep_debt } = response.data;
      set({
        sleepNeed: sleep_need["sleep_need"],
        debtMinutes: sleep_debt["debtMinutes"],
        energyPotential: sleep_debt["energy_potential"],
      });
    } catch (err) {
      set({ error: true });
    }
  }
  set({ isFetching: false });
};


export const getStripeConfiguration = async (
  set: SetState<State>,
  get: GetState<State>
) => {
  set({ isFetching: true, error: false });

  let params = {
    token: get().email,
    env: Environment.getVar("REACT_APP_ENV") ?? test,
    success_url: `${document.location.origin}${screenConfigs.stripeConfirmation.url}`,
    cancel_url: `${document.location.origin}${screenConfigs.paywall.url}`,
    coupon: 'RISE',  // this gives 7 days
  };

  try {
    const response = await axios.get(apiUrls.getStripeConfig, { params });

    if (response.status !== 200) {
      set({ error: true });
    } else {
      set({ userConfig: response.data });
    }
    // TODO: analytics & sentry
  } catch (err) {
    set({ error: true });
  }

  set({ isFetching: false });
};

export const getCircadianCurve = async (
  set: SetState<State>,
  get: GetState<State>
) => {
  set({ isFetching: true, error: false });

  let attempts = 0;

  async function pollForCurve() {
    try {
      const response = await axios.get(apiUrls.getCircadianCurve, config(get));
      if (response.status !== 200) {
        set({ error: true });
      }

      if (response.data.length === 0) {
        if (attempts >= 20) {
          // TODO: handle polling error (never getting data) better
          // These are dummy values if it never returns
          set({ energyDipStart: 1627503540000, energyDipEnd: 1627511040000 });
          return;
        }

        setTimeout(async () => {
          console.log("polling");
          attempts += 1;
          await pollForCurve();
          return;
        }, 500);
      }

      let dipStart: number;
      let dipEnd: number;

      response.data[0]["zones"].forEach((zone: any) => {
        if (zone["key"] === "midday_dip") {
          dipStart = zone["from"];
          dipEnd = zone["to"];
        }
        set({ energyDipStart: dipStart, energyDipEnd: dipEnd });
      });
    } catch (err) {
      set({ error: true });
    }
  }

  try {
    await pollForCurve();
  } catch (err) {
    set({ error: true });
  }

  set({ isFetching: false });
};


const sleepDurationWillNotBeRefusedByAPI = (inBed: number | null, wakeUp: number | null) : boolean | undefined => {
  if (inBed === null || wakeUp === null) {
    return undefined
  }
  const totalMinutes = (wakeUp + 24 - inBed) * 60
  return totalMinutes > 200
}

export const sleepDurationIsTooShort = (inBed: number | null, wakeUp: number | null) : boolean => {
  return sleepDurationWillNotBeRefusedByAPI(inBed, wakeUp) === false
}

export const sleepDurationIsTooShortWithType = (inBed: number | null, wakeUp: number | null, sleepType: SleepType) : boolean => {
  const isTooShort = sleepDurationWillNotBeRefusedByAPI(inBed, wakeUp) === false
  if (isTooShort) {
    sendTooShortSleepEvent(sleepType)
  }
  return isTooShort;
}
