import React from "react";
import logo from "../../../images/logo.svg";
import { UiState } from "./SelfReportedConfirmation";
import Title from "../components/Title";
import ActionButton from "../components/ActionButton";
import DisabledActionButton from "../components/DisabledActionButton";
import AlertMessage from "../components/AlertMessage";

const showText = (timeSelected: string) => {
  // NOTE: timeSelected is currently a string
  // checks if the user selected a time range that started with 'after' or 'before' a certain time
  // if yes to above, then we hide the text 'between'
  // otherwise, user has chosen a time range such as '6:30a - 7:30a' and we want to show the word 'between'

  const firstChar = timeSelected[0];
  if (firstChar === "A" || firstChar === "B") {
    return " ";
  }
  return "between";
};

export function SelfReportedConfirmationView(props: {
  onClick: () => void;
  error: boolean;
  weekDayInBed: string | undefined;
  weekDayWakeup: string | undefined;
  freeDayInBed: string | undefined;
  freeDayWakeup: string | undefined;
  goToWeekDayEdit: () => void;
  goToFreeDayEdit: () => void;
  uiState: UiState;
  isFetching: boolean;
  timesAreValid: boolean;
}) {

  // We don't expect to get into this state unless user enters invalid times and then navigates to this url
  // by typing it in
  const tooShortError = !props.timesAreValid
  const generalAPIError = props.error && props.timesAreValid

  return (
    <div className="mx-auto w-px-375 sm:w-px-640 h-full relative z-10 pb-24">
      <div className={"pt-6 md:py-8"}>
        <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
      </div>
      <div
        className={`w-px-375 md:w-px-600 h-full ${
          window.innerHeight < 800 ? "space-y-20" : "space-y-40"
        } sm:space-y-0 sm:pt-20`}
      >
        <div className="pt-8 flex flex-col justify-between h-full w-full">
          <div>
            <Title
              title={`Your sleep patterns`}
              subtitle={`We'll use your self-reported sleep to get you started. Your insights
            will become more accurate as you use RISE.`}
            />
            <div className="flex justify-end mt-4 pb-2 relative">
              <hr className="w-full border-t border-panelGray-2" />
            </div>

            <div className="mt-3 text-white px-3">
              <div className={`pb-4`}>
                <text className="text-xl leading-tight">
                  Weekdays
                  <span
                    onClick={props.goToWeekDayEdit}
                    className="text-sm font-bold leading-tight text-purple-1 cursor-pointer pl-4"
                  >
                    Edit
                  </span>
                </text>
              </div>
              <p className="text-white text-base">
                In bed {props.weekDayInBed && showText(props.weekDayInBed)}{" "}
                {props.weekDayInBed?.toLowerCase()}
              </p>
              <p className="text-white text-base">
                Wake up {props.weekDayWakeup && showText(props.weekDayWakeup)}{" "}
                {props.weekDayWakeup?.toLowerCase()}
              </p>
            </div>

            <div className="flex justify-end mt-4 pb-2 relative">
              <hr className="w-full border-t border-panelGray-2" />
            </div>

            <div className="mt-3 text-white px-3">
              <div className={`pb-4`}>
                <text className="text-xl leading-tight mt">
                  Weekends
                  <span
                    onClick={props.goToFreeDayEdit}
                    className="text-sm font-bold leading-tight text-purple-1 cursor-pointer pl-4"
                  >
                    Edit
                  </span>
                </text>
              </div>

              <p className="text-white text-base">
                In bed {props.freeDayInBed && showText(props.freeDayInBed)}{" "}
                {props.freeDayInBed?.toLowerCase()}{" "}
              </p>
              <p className="text-white text-base">
                Wake up {props.freeDayWakeup && showText(props.freeDayWakeup)}{" "}
                {props.freeDayWakeup?.toLowerCase()}{" "}
              </p>
            </div>

            <div className="flex justify-end my-4 pb-2 relative">
              <hr className="w-full border-t border-panelGray-2" />
            </div>
          </div>
          <div className={"px-3 w-full"}>
            {tooShortError && (
                <div className={"px-3 py-4"}>
                  <AlertMessage
                      title={"Sleep time is too short."}
                      subtitle={
                        "Please update your sleep time so that is it longer than 3 hours."
                      }
                  />
                  <DisabledActionButton
                      title="NEXT"
                      onClick={() => {}}
                  />
                </div>
            )}

            {generalAPIError && (
                <div>
                  <div className="mt-2 text-red-1 text-sm font-bold">
                    Please try again.
                  </div>
                  <div className={"w-full"}>
                    <ActionButton
                    title="THAT LOOKS RIGHT"
                    onClick={props.onClick}
                    />
                  </div>
                </div>
            )}

            {props.uiState === "ready" && !tooShortError && (
              <div className={"w-full"}>
                <ActionButton
                  title="THAT LOOKS RIGHT"
                  onClick={props.onClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
