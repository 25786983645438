import React from 'react'

import phone from '../assets/phones/learn-phone.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const Learn: React.FC = () => {
  useSlideTrackEvt('Learn')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline secondary>Using RISE</BrandHeadline>
        <LargeHeadline>Learn about the science of sleep.</LargeHeadline>
        <BodyCopy>
          We want to help you to understand the dynamics at play during your
          sleeping and waking hours, and empower you to change your habits in
          favor of your personal sleep goals.
        </BodyCopy>
      </div>
      <div>
        <GradientPhone>
          <img alt="Learning resources on RISE app" src={phone} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
