// import { isMobile } from 'react-device-detect';
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
export default function getRedirectUrls(email: string) {
  let cancel = document?.location?.href;

  // TODO: This is hacky and ONLY APPLIES TO NEW MEMBER OFFER But enough to get us through this first test
  if (document?.location?.pathname === '/redirect') {
    cancel = `${document.location.origin}/new-member-offer?user_id=${email}`;
  }
  const redirect = {
    mobile: {
      success: 'https://rise-sleep.app.link/KlNmSZAstbb',
      cancel,
    },
    desktop: {
      success: `${document.location.origin}/thankyou?user_id=${email}`,
      cancel,
    },
  };
  const source: keyof typeof redirect = isMobile ? 'mobile' : 'desktop';

  return redirect[source];
}
