import React, { useEffect, useState } from 'react'

import Env from '../utils/Environment'

type ScrollProps = {
  /** Elements to track scroll */
  children: React.ReactNode
  /** Print useful information about the current scroll position */
  debug?: boolean
  /** Element which when visible will trigger the associated handler   */
  el?: React.RefObject<HTMLElement>
  /**
   * Number of pixels for which an element will have to scroll into
   * view before the associated handler will be triggered. Useful in cases
   * where elements may have a lot of vertical padding
   */
  elThreshold?: number
  /**
   * A number of pixels from the bottom of the screen. Once the user scrolls
   * within this threshold the associated handler, `onLowerBoundaryReached`,  will be called.
   */
  lowerBoundaryThreshold?: number
  /** Handler triggered once the el, as defined by `el`,  is scrolled into view */
  onElReached?: () => void
  /**
   * Handler triggered once the user scrolls within a certain distance,
   * as defined by the `lowerBoundaryThreshold`, of the bottom of the page.
   * Defaults to 100.
   */
  onLowerBoundaryReached?: () => void
}

/**
 * Trigger handles when a user scrolls certain elements into view or comes
 * within a distance of the bottom of the screen.
 *
 * ```javascript
 * // Track bottom of the screen
 * <Scroll onLowerBoundaryReached={() => console.log('Load paginated data')}>
 *   <div>Some context</div>
 * </Scroll>
 *
 * // Track element
 * const el = useRef<HTMLElement>(null)
 * <Scroll el={el} onElReached={() => console.log('User saw footer')}>
 *   <div>Large context</div>
 *   <footer ref={el} />
 * </Scroll>
 * ```
 */
export const Scroll: React.FC<ScrollProps> = (props) => {
  const {
    children,
    debug = false,
    el,
    elThreshold = 100,
    onLowerBoundaryReached,
    onElReached,
    lowerBoundaryThreshold = 1000,
  } = props

  const [hasNotifiedEl, setHasNotifiedEl] = useState(false)
  const [hasNotifiedLowerBoundary, setHasNotifiedLowerBoundary] =
    useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const {
        documentElement: { offsetHeight, scrollTop },
      } = document
      // Vertical position of the bottom of the screen
      const lowerYPosition = window.innerHeight + scrollTop

      if (debug && !Env.isProduction) {
        console.log(
          `Total height = ${offsetHeight}, Top distance from (0,0) = ${scrollTop}, Bottom distance from (0,0) = ${lowerYPosition}`
        )
      }

      // === Ref checking

      // If a ref element was provided, call the associated handler once it's scrolled into view
      if (el?.current !== undefined && el?.current !== null) {
        const { y: distanceFromTop } = el.current.getBoundingClientRect()
        const elDistanceFromBottom = -(distanceFromTop - window.innerHeight)
        const isBeyondEl = elDistanceFromBottom - elThreshold > 0

        // Execute the handler if the user passes the element
        if (!hasNotifiedEl && isBeyondEl) {
          setHasNotifiedEl(true)
          onElReached?.()
          // Re-enable the handler if the user scrolls back up the screen
        } else if (hasNotifiedEl && !isBeyondEl) {
          setHasNotifiedEl(false)
        }
      }

      // === Threshold checking

      const isBeyondLowerBoundary =
        lowerYPosition >= offsetHeight - lowerBoundaryThreshold
      // Trigger the boundary reached handler if the user scrolls past the threshold from the bottom
      // of the screen
      if (!hasNotifiedLowerBoundary && isBeyondLowerBoundary) {
        setHasNotifiedLowerBoundary(true)
        onLowerBoundaryReached?.()
      } else if (hasNotifiedLowerBoundary && !isBeyondLowerBoundary) {
        setHasNotifiedLowerBoundary(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return <React.Fragment>{children}</React.Fragment>
}
