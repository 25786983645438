import Analytics from '../Analytics'

export const UserAlreadySubscribedMessage = 'User already subscribed'

export const bypassCheckoutForDuplicateCustomer = (
  code: string,
  token: string | null
) => {
  reportRepeatCheckout(code, token)
  redirectToSuccess(code, token)
}

export const redirectToSuccess = (code: string, token: string | null) => {
  if (token) {
    window.location.href = `${
      document.location.origin
    }/offer/success?token=${encodeURIComponent(token)}&code=${code}`
  } else {
    window.location.href = `${document.location.origin}/offer/success?code=${code}`
  }
}

export const reportRepeatCheckout = (code: string, token: string | null) => {
  Analytics.track(`Attempted to use Stripe checkout again`, { code, token })
}
