import React from 'react'
import SubscriptionSelectionCell from '../../../components/SubscriptionSelectionCell'
import './TrialSelectionCells.css'

import { useTranslation } from 'react-i18next'
import { EmailConfirmationPrice } from '../prices'
import { ProductCode } from '../products'

type TrialSelectionCellsProps = {
  onSelect: (code: ProductCode) => void
  selection: ProductCode
  products: EmailConfirmationPrice[]
  isStudent?: boolean
}

function TrialSelectionCells({
  onSelect,
  selection,
  products,
  isStudent = false,
}: TrialSelectionCellsProps) {
  const { t } = useTranslation()
  return (
    <div>
      {isStudent ? (
        <div className={'parent'}>
          <div className="trial-selection-title">
            {t('checkout_screen.TRY_RISE_FOR')}
          </div>
          <div className="student">
            {t('checkout_screen.students_save_pct', { pct: '40' })}
          </div>
        </div>
      ) : (
        <div className={'parent'}>
          <div className="trial-selection-title">
            {t('checkout_screen.TRY_RISE_FOR')}
          </div>
        </div>
      )}
      {products.map((product) => (
        <SubscriptionSelectionCell
          title={product.title}
          subtitle={product.subtitle}
          onPress={() => {
            onSelect(product.productCode)
          }}
          selected={selection === product.productCode}
        />
      ))}
    </div>
  )
}

export default TrialSelectionCells
