export type SupportedCountryCode = 'US' | 'CA' | 'GB' | 'AU' | 'DE'
export type SupportedCurrency = 'USD' | 'CAD' | 'AUD' | 'GBP' | 'EUR'
export type SupportedCurrencySymbol = '$' | '£' | '€' | 'CA$' | 'AU$'

type FormatCurrencyArgs = {
  value: number
  countryCode: SupportedCountryCode | 'FR'
  currency: SupportedCurrency
  minimumFractionDigits?: number
}

/**
 *
 * @note: From @gosborn:
 * This function frequently will output formats that don't match Stripe or the
 * accepted formatting in the country. So gotta be super careful and/or think
 * about simpler solutions before automating how we format currencies - that's
 * why I have constants that specifies some of this like annualPrices in
 * src/projects/emailConfirmation-0424/prices.ts. I've been down this road
 * before and it has caused a lot of headaches
 *
 *  I think we're good here for now. The trickier currencies are PT, IE, NE, IT
 */
export const formatCurrency = ({
  value,
  countryCode: locale,
  currency,
  minimumFractionDigits = 2,
}: FormatCurrencyArgs) => {
  const localeCode = {
    US: 'en-US',
    GB: 'en-GB',
    FR: 'fr-FR',
    AU: 'en-AU',
    CA: 'en-CA',
    DE: 'de-DE',
  }[locale]
  const formatted = new Intl.NumberFormat(localeCode, { style: 'currency', currency, minimumFractionDigits }).format(
    value
  )
  return locale === 'CA' ? `CA${formatted}` : locale === 'AU' ? `AU${formatted}` : formatted
}

export const countryCodeToCurrency = (countryCode: SupportedCountryCode): SupportedCurrency => {
  const map: Record<SupportedCountryCode, SupportedCurrency> = {
    US: 'USD',
    CA: 'CAD',
    AU: 'AUD',
    GB: 'GBP',
    DE: 'EUR',
  }
  return map[countryCode] ?? 'USD'
}
export const countryCodeToCurrencySymbol = (countryCode: SupportedCountryCode): SupportedCurrencySymbol => {
  const map: Record<SupportedCountryCode, SupportedCurrencySymbol> = {
    US: '$',
    CA: 'CA$',
    AU: 'AU$',
    GB: '£',
    DE: '€',
  }
  return map[countryCode] ?? '$'
}
