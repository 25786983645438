import React from "react";

interface TitleProps {
  title: string;
  subtitle?: string;
}
const Title = ({ title, subtitle }: TitleProps) => {
  return (
    <div className={`flex flex-col text-white px-3`}>
      <text className={`text-xl font-semibold pb-2`}>{title}</text>
      <text className={`text-sm`}>{subtitle}</text>
    </div>
  );
};

export default Title;
