import create from "zustand";
import { persist } from "zustand/middleware";

import { UserConfiguration } from "../utils/fetchUserConfiguration";
import {
  createAccount,
  sendJob,
  sendSelfReportedSleepTimes,
  getStripeConfiguration,
  getCircadianCurve,
} from "../utils/webOnboarding2/api";
import { ApiError } from "../utils/webOnboarding2/constants";

const SESSION_STORAGE_NAME = "rise-web-onboarding";

export type State = {
  debtMinutes: number;
  email: string;
  energyDipEnd: number;
  energyDipStart: number;
  energyPotential: number;
  error: boolean;
  errorInfo: ApiError;
  freedayInBed: number | null;
  freedayWakeUp: number | null;
  isFetching: boolean;
  jobSelected: string;
  personId: string;
  reminderDate: string | null;
  signUpSuccess: boolean;
  sleepNeed: number;
  token: string;
  userConfig: UserConfiguration | null;
  weekdayInBed: number | null;
  weekdayWakeUp: number | null;
  code: string | null;

  setEmail: (email: string) => void;
  setError: (error: boolean) => void;
  setErrorInfo: (errorInfo: ApiError) => void;
  setFreedayInBed: (freedayInBed: number) => void;
  setFreedayWakeUp: (freedayWakeUp: number) => void;
  setIsFetching: (isFetching: boolean) => void;
  setJobSelected: (jobSelected: string) => void;
  setPersonId: (personId: string) => void;
  setReminderDate: (reminderDate: string) => void;
  setSignUpSuccess: (signUpSuccess: boolean) => void;
  setToken: (token: string) => void;
  setWeekdayInBed: (weekdayInBed: number) => void;
  setWeekdayWakeUp: (weekdayWakeUp: number) => void;
  setCode: (code: string) => void;

  createAccount: () => void;
  sendJob: () => void;
  sendSelfReportedSleepTimes: () => void;
  getCircadianCurve: () => void;
  getStripeConfiguration: () => void;
};

// TODO: try to break this store out in some fashion, it's only going to continue to grow: start the pattern now!
export const useStore = create<State>(
  persist(
    (set, get) => ({
      debtMinutes: 0,
      email: "",
      energyDipEnd: 0,
      energyDipStart: 0,
      energyPotential: 0,
      error: false,
      errorInfo: ApiError.None,
      freedayInBed: null,
      freedayWakeUp: null,
      isFetching: false,
      jobSelected: "",
      personId: "",
      reminderDate: null,
      signUpSuccess: false,
      sleepNeed: 0,
      token: "",
      userConfig: null,
      weekdayInBed: null,
      weekdayWakeUp: null,
      code: null,

      setEmail: (email) => set({ email }),
      setError: (error) => set({ error }),
      setErrorInfo: (errorInfo: ApiError) => set({ errorInfo }),
      setFreedayInBed: (freedayInBed) => set({ freedayInBed }),
      setFreedayWakeUp: (freedayWakeUp) => set({ freedayWakeUp }),
      setIsFetching: (isFetching) => set({ isFetching }),
      setJobSelected: (jobSelected) => set({ jobSelected }),
      setPersonId: (personId) => set({ personId }),
      setReminderDate: (reminderDate: string) => set({ reminderDate }),
      setSignUpSuccess: (signUpSuccess: boolean) => set({ signUpSuccess }),
      setToken: (token) => set({ token }),
      setWeekdayInBed: (weekdayInBed) => set({ weekdayInBed }),
      setWeekdayWakeUp: (weekdayWakeUp) => set({ weekdayWakeUp }),
      setCode: (code) => set({ code }),

      createAccount: async () => await createAccount(set, get),
      sendJob: async () => await sendJob(set, get),
      sendSelfReportedSleepTimes: async () =>
        await sendSelfReportedSleepTimes(set, get),
      getCircadianCurve: async () => await getCircadianCurve(set, get),
      getStripeConfiguration: async () => await getStripeConfiguration(set, get),
    }),
    {
      name: SESSION_STORAGE_NAME,
      getStorage: () => sessionStorage,
    }
  )
);
