import React from 'react'
import { useTranslation } from 'react-i18next'
import { SupportedCountryCode, countryCodeToCurrencySymbol } from '../../../utils/currency'
import { ChooseYourOwnPriceProduct, ChooseYourOwnPriceProductId } from '../chooseYourOwnPrices'
import './ChoosePriceSelector.css'

export type ChoosePriceSelectorOptions = Record<ChooseYourOwnPriceProductId, ChooseYourOwnPriceProduct>

export type ChoosePriceSelectorProps = {
  options: ChoosePriceSelectorOptions
  selectedId: string | null
  locale: SupportedCountryCode
  onChange: (productId: ChooseYourOwnPriceProductId) => void
}

function ChoosePriceSelector({ options, locale, onChange, selectedId }: ChoosePriceSelectorProps) {
  const { t } = useTranslation()
  const currentSymbol = countryCodeToCurrencySymbol(locale)
  const symbolPosition = locale === 'DE' || locale === 'FR' ? 'euro' : ''
  const handleSelect = (option: ChooseYourOwnPriceProduct) => {
    onChange(option.productCode)
  }

  return (
    <div id="choose-price-selector">
      <div
        className={`option ${symbolPosition} ${selectedId === '30d01_annual_70' ? 'selected' : ''}`}
        onClick={() => {
          handleSelect(options['30d01_annual_70'])
        }}
      >
        <div className="container">
          <div className="price-container">
            <span className="currency">{currentSymbol}</span>
            <span className="price">{options['30d01_annual_70'].introPrice}</span>
          </div>
          <div className="trial-text">
            {t('choose_your_price_checkout_screen.n_day_trial', { daysFree: options['30d01_annual_70'].daysFree })}
          </div>
        </div>
      </div>
      <div
        className={`option ${symbolPosition} ${selectedId === '30d05_annual_70' ? 'selected' : ''}`}
        onClick={() => {
          handleSelect(options['30d05_annual_70'])
        }}
      >
        <div className="container">
          <div className="price-container">
            <span className="currency">{currentSymbol}</span>
            <span className="price">{options['30d05_annual_70'].introPrice}</span>
          </div>
          <div className="trial-text">
            {t('choose_your_price_checkout_screen.n_day_trial', { daysFree: options['30d05_annual_70'].daysFree })}
          </div>
        </div>
        <div className="popular-badge">{t('common.POPULAR')}</div>
      </div>
      <div
        className={`option ${symbolPosition} ${selectedId === '30d10_annual_70' ? 'selected' : ''}`}
        onClick={() => {
          handleSelect(options['30d10_annual_70'])
        }}
      >
        <div className="container">
          <div className="price-container">
            <span className="currency">{currentSymbol}</span>
            <span className="price">{options['30d10_annual_70'].introPrice}</span>
          </div>
          <div className="trial-text">
            {t('choose_your_price_checkout_screen.n_day_trial', { daysFree: options['30d10_annual_70'].daysFree })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChoosePriceSelector
