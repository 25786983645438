import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import WebVsAppModal from './WebVsAppModal'

import mainPhoto from './images/backround_v2.png'
import stars from './images/stars_3x.png'
import iOSIcon from './images/RISE_iOS_icon.png'
import { DynamicCodeProps } from '../../utils/dynamicCodes/types'
import Analytics from '../../utils/Analytics'

// @ts-ignore
// import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';
//
// const ctaExperiment = 'Web vs App Store CTA';
// const freeVariant = 'REDEEM FREE WEEK';
// const discountVariant = 'REDEEM YOUR DISCOUNT';
//
// emitter.defineVariants(ctaExperiment, [freeVariant, discountVariant], [50, 50]);

const WebVsAppStorePrelude = (props: DynamicCodeProps) => {
  const [showModal, setShowModal] = useState(false)
  const code = props.match.params.name
  const history = useHistory()

  useEffect(() => {
    Analytics.track('Web Vs App Store - Prelude Viewed', { code })
  }, [code])

  return (
    <>
      <div
        className={`w-full h-screen mx-auto ${
          window.innerWidth < 640 ? '' : ''
        }`}
        style={{ opacity: `${showModal ? 0.2 : 1}` }}
      >
        <div
          className={`mx-auto sm:w-px-640 relative z-10 ${
            window.innerWidth > 640 ? '' : ''
          }`}
          style={{ maxWidth: '550px' }}
        >
          <MaybeStagingBadge />
          <div
            style={{ minHeight: window.innerHeight }}
            className={`h-screen ${
              window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
            } sm:space-y-0 sm:pt-410`}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <img src={mainPhoto} alt="" style={{ width: '100%' }} />
              <img
                src={iOSIcon}
                alt=""
                style={{
                  position: 'absolute',
                  bottom: '-28px',
                  width: '73px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  left: 0,
                  right: 0,
                }}
              />
            </div>
            <div
              style={{
                marginTop: '35px',
                marginLeft: '4%',
                marginRight: '4%',
                textAlign: 'center',
              }}
              className={'text-white'}
            >
              <img
                src={stars}
                alt=""
                className="inline-block"
                style={{ width: '90px' }}
              />
              <p style={{ fontWeight: 300, fontSize: '16px' }}>
                4.5 stars on 6.5k reviews
              </p>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '26px',
                  marginTop: '15px',
                  lineHeight: '32.84px',
                }}
              >
                Become a morning person
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: '14px',
                  marginTop: '7.5px',
                }}
              >
                Save 40% when you choose to redeem on Web.
              </p>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '1.1rem',
                }}
              >
                You can can sign up for RISE on either Web or the App Store.
                Redeeming on the web supports our team and allows us to provide
                better customer savings and support.
              </p>

              <button
                style={{
                  width: '95%',
                  letterSpacing: '0.1rem',
                  marginTop: '17px',
                }}
                className={
                  'border-2 bg-purple-1 border-purple-1 text-white rounded-md font-medium tracking-wide  max-w-full transition-opacity duration-500 ease-in-out h-16'
                }
                onClick={() => {
                  Analytics.track(
                    'Web Vs App Store Prelude - Rise Checkout Clicked',
                    { code }
                  )
                  history.push(`/try-rise/${code}`)
                }}
              >
                REDEEM 40% OFF ON WEB
              </button>
              <button
                style={{
                  width: '95%',
                  borderWidth: '3px',
                  letterSpacing: '0.1rem',
                }}
                className={
                  'mt-2 mb-10 border-purple-1 text-white rounded-md font-medium tracking-wide  max-w-full transition-opacity duration-500 ease-in-out h-16'
                }
                onClick={() => {
                  setShowModal(true)
                }}
              >
                GO TO APP STORE
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <WebVsAppModal code={code} hide={() => setShowModal(false)} />
      ) : null}
    </>
  )
}

export default WebVsAppStorePrelude
