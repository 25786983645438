interface ScreenConfig {
  url: string
  documentTitle: string
}

export const screenConfigs: Record<string, ScreenConfig> = {
  emailConfirmationFeatures: {
    url: '/email-confirmation',
    documentTitle: 'Rise',
  },
  emailConfirmationTrialConfirmation: {
    url: '/email-confirmation-trial',
    documentTitle: 'Rise',
  },

  emailConfirmationCheckout: {
    url: '/email-confirmation-checkout',
    documentTitle: 'Rise',
  },
  emailConfirmationCheckoutStudentPrices: {
    url: '/email-confirmation-student-checkout',
    documentTitle: 'Rise',
  },
  designTrialCheckout: {
    url: '/email-confirmation-design-trial-checkout',
    documentTitle: 'Rise',
  },
  designTrialStudentCheckout: {
    url: '/email-confirmation-design-trial-student-checkout',
    documentTitle: 'Rise',
  },
  chooseYourPriceCheckout: {
    url: '/email-confirmation-choose-your-price-checkout',
    documentTitle: 'Rise',
  },
  chooseYourPriceCheckoutTrialConfirmation: {
    url: '/email-confirmation-choose-your-price-trial',
    documentTitle: 'Rise',
  },
}
