import React from 'react'
import './FeatureCardSlides.css'

import logo from '../assets/logo@3x.png'
import slide0 from '../assets/hero-0@3x.png'
import slide1 from '../assets/hero-1@3x.png'
import beaker from '../assets/icon_beaker_24x24@3x.png'
import calendar from '../assets/icon_calendar_24x24@3x.png'
import logoCheckmark from '../assets/logoCheckmark@3x.png'
import { Trans, useTranslation } from 'react-i18next'

type Slide0Props = {
  emailAddress: string | null
}
export function Slide0({ emailAddress }: Slide0Props) {
  const { t } = useTranslation()
  return (
    <div className="slide flex flex-col justify-space-between items-center text-white">
      <div className="flex flex-1 flex-col items-center justify-center pb-32">
        <div className="icon pt-5 pb-1">
          <img src={logoCheckmark} alt="Rise" width={50} height={50} />
        </div>

        <h1 className="text-white  pb-3 text-3xl  leading-tight text-center">
          {t('email_confirmation.slide0.title')}
        </h1>
        <span className="text-center text-base pr-6 pl-6">
          <Trans
            components={{ highlight: <span className="text-yellow-2" /> }}
            i18nKey={'email_confirmation.slide0.body'}
            values={{
              emailAddress: emailAddress ?? t('common.your_email_address'),
            }}
          />
        </span>
      </div>

      <div className="absolute top-2 right-2 text-gray-2 text-sm">
        {t('common.n_of_x', { n: 1, x: 3 })}
      </div>
    </div>
  )
}

export function Slide1() {
  const { t } = useTranslation()
  return (
    <div className="slide flex flex-col justify-space-between items-center text-white">
      <img src={slide0} className="slideImage" alt="" />

      <div className="flex flex-col items-center z-10 ">
        <div className="icon pt-5 pb-1">
          <img src={logo} alt="Rise" width={50} height={50} />
        </div>

        <h1 className="text-white  pb-3 text-3xl  leading-tight text-center">
          {t('email_confirmation.slide1.title')}
        </h1>
        <span className="text-center text-base px-3">
          {t('email_confirmation.slide1.body1')}
        </span>
      </div>

      <div id="callout1" className="absolute bottom-5 z-10 px-3 ">
        <div className="flex flex-row items-start">
          <img src={beaker} className="icon" width={24} height={24} alt="" />
          <p className="text-sm pl-2 pt-0.5">
            {t('email_confirmation.slide1.body2')}
          </p>
        </div>
      </div>

      <div className="absolute top-2 right-2 text-gray-2 text-sm">
        {t('common.n_of_x', { n: 2, x: 3 })}
      </div>
    </div>
  )
}
export function Slide2() {
  const { t } = useTranslation()
  return (
    <div className="slide flex flex-col justify-space-between items-center text-white">
      <img src={slide1} className="slideImage" alt="" />

      <div className="flex flex-col items-center z-10 ">
        <div className="icon pt-5 pb-1">
          <img src={logo} alt="Rise" width={50} height={50} />
        </div>

        <h1 className="text-white  pb-3 text-3xl  leading-tight text-center">
          {t('email_confirmation.slide2.title')}
        </h1>
        <span className="text-center text-base px-3">
          {t('email_confirmation.slide2.body1')}
        </span>
      </div>

      <div id="callout1" className="absolute bottom-5 z-10 px-3">
        <div className="flex flex-row items-start">
          <img src={calendar} className="icon" width={24} height={24} alt="" />
          <p className="text-sm pl-2 pt-0.5">
            {t('email_confirmation.slide2.body2')}
          </p>
        </div>
      </div>

      <div className="absolute top-2 right-2 text-gray-2 text-sm">
        {t('common.n_of_x', { n: 3, x: 3 })}
      </div>
    </div>
  )
}
