import React, { useEffect, useState } from 'react';
import MaybeStagingBadge from '../../components/MaybeStagingBadge';
import { useTokenFromParams } from '../../hooks/useTokenFromParams';
import logo from '../../images/logo.svg';
import profile from '../../images/profile.svg';
import appStore from './appStore.png';
import playStore from './playStore.png';
import riseAppLockup from './riseAppLockup.png';
import Analytics from '../../utils/Analytics';
import { useMarketingSourceCodeFromParams } from '../../hooks/useMarketingSourceCodeFromParams';

const SignUpSuccessPage = () => {
  const email = useTokenFromParams();
  const code = useMarketingSourceCodeFromParams();
  const [daysFree, setDaysFree] = useState(7)

    useEffect(() => {
        //TODO: Likely move to a central place with all the config of codes
        if(['jobble'].includes(code.toLowerCase())){
            setDaysFree(60)
        }

        if(['perkspot', 'leon', 'gift', 'survey'].includes(code.toLowerCase())){
            setDaysFree(30)
        }
    }, [code]);

  return (
    <div className="w-full h-screen mx-auto" id="new-years-2021">
      <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
        <MaybeStagingBadge />
        <div className={'py-6 md:py-12'}>
          <img
            src={logo}
            alt="RISE"
            className="self-start"
            style={{ height: 16 }}
          />
        </div>
        <div
          className={`w-px-320 md:w-px-600 ${
            window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
          } sm:space-y-0 sm:pt-20`}
        >
          <div className="pt-8 flex flex-col">
            <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
              You're all set!
            </h1>
            <div className="flex justify-end my-2  pb-2">
              <hr className="w-full border-t border-white opacity-25" />
            </div>
            {email ? (
              <div
                className={`opacity-0 text-white text-lg font-medium flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
                  email && 'opacity-100'
                }`}
              >
                <img src={profile} alt="" className="inline mr-2 w-5" />
                {email ?? '...'}
              </div>
            ) : null}
            <p className="text-white text-sm">
              Now, just download the RISE app and use this email to log in and
              start your <span className={'whitespace-nowrap'}>{`${daysFree}`}-days</span> free.
            </p>
            <div className="flex justify-end my-8 pb-2 ">
              <hr className="w-full border-t border-white opacity-25" />
            </div>
          </div>
        </div>
        <div>
          <img
            src={riseAppLockup}
            alt="RISE: Sleep & Energy Tracker - Better Health, Energy & Focus"
            className="self-start mb-6"
            style={{ height: 83 }}
          />

          <div className="flex">
            <a
              href="https://apps.apple.com/us/app/rise-sleep-energy-tracker/id1453884781"
              onClick={() =>  Analytics.track('Store Redirect Clicked', {store: 'appStore'})}
              className="block mr-4"
            >
              <img
                src={appStore}
                alt="Download on the App Store"
                className="self-start"
                style={{ height: 50 }}
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.risesci.nyx"
              onClick={() =>  Analytics.track('Store Redirect Clicked', {store: 'playStore'})}
              className="block"
            >
              <img
                src={playStore}
                alt="Get it on Google Play"
                className="self-start"
                style={{ height: 50 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccessPage;
