import React, { useEffect } from "react";
import { useStore } from "../../state/webOnboarding2";
import { Redirect } from "react-router-dom";
import { screenConfigs } from "../../utils/webOnboarding2/constants";
import MaybeStagingBadge from "../../components/MaybeStagingBadge";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const entryPoint = screenConfigs.emailSignUp.url;

export const withRedirectAndTitle = <P extends object>(Component: React.ComponentType<P>, documentTitle: string): React.FC<P> => ({...props}: any) => {

    const {
        signUpSuccess
    } = useStore(state => ({
        signUpSuccess: state.signUpSuccess
    }))

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useDocumentTitle(documentTitle)

    const shouldRedirect = ((): boolean => {
        if (signUpSuccess || window.location.pathname === entryPoint) {
            return false;
        }
        return true;
    })();

    return (
        <div className="w-full h-screen mx-auto">
            {shouldRedirect ? <Redirect to={{
                pathname: entryPoint,
                search: window.location.search,
            }} /> : null }
            <MaybeStagingBadge/>
            <Component {...props as P} />
        </div>
    )
}
