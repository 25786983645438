import Environment from '../../utils/Environment'
import i18n from '../../i18n/i18n'
import { SupportedCountryCode, SupportedCurrency, countryCodeToCurrency, formatCurrency } from '../../utils/currency'

export type ChooseYourOwnPriceProduct = {
  code: string
  price: number
  daysFree: number
  introPrice: number
  sheetLabel: string // Text to show on the Apple/Google Pay sheets
  productCode: ChooseYourOwnPriceProductId
  title: string | JSX.Element
  subtitle: string
  pricingText: string | JSX.Element // Text to show on Paywall when selected
}

export type ChooseYourOwnPriceProductId = '30d01_annual_70' | '30d05_annual_70' | '30d10_annual_70'

export type LocalizedPriceData = Record<SupportedCountryCode, ChooseYourOwnPriceProduct>

// Returns `1,5,10,unknown` based on product id
export const analyticsChosenPrice = (productCode: ChooseYourOwnPriceProductId) => {
  return productCode === '30d01_annual_70'
    ? '1'
    : productCode === '30d05_annual_70'
    ? '5'
    : productCode === '30d10_annual_70'
    ? '10'
    : 'unknown'
}

//This is the format provided by Greg
type ChooseYourPriceRawOffer = Record<
  ChooseYourOwnPriceProductId,
  {
    daysFree: number
    productId: ChooseYourOwnPriceProductId
    localizedPrices: Record<SupportedCurrency, { priceId: string; price: number; introPrice: number }>
  }
>

/** Returns the pricing text for a given country and intro price */
const createLocalizedPricingText = (
  countryCode: SupportedCountryCode,
  introPrice: number,
  price: number,
  daysFree: number
): {
  sheetLabel: string
  subtitle: string
  pricingText: string
  title: string
} => {
  const currency = countryCodeToCurrency(countryCode)
  const introPriceString = formatCurrency({ value: introPrice, countryCode, currency })
  const annualPriceString = formatCurrency({ value: price, countryCode, currency })

  const sheetLabel = i18n.t('checkout_screen.sheet_label', {
    daysFree,
    planPrice: annualPriceString,
  })
  const subtitle = i18n.t('checkout_screen.days_free', { daysFree })

  const pricingText = i18n.t('checkout_screen.pricing_text_paid_trial_choose_your_price', {
    introPriceString,
    annualPriceString,
  })

  return {
    sheetLabel: sheetLabel,
    title: introPriceString,
    subtitle: subtitle,
    pricingText: pricingText,
  }
}

export const get_localizedPrices_chooseYourOwn = (): Record<ChooseYourOwnPriceProductId, LocalizedPriceData> => {
  // Closure scoped function to get pricing for a given productId and locale
  function _getPricing(
    productId: ChooseYourOwnPriceProductId,
    locale: SupportedCountryCode
  ): ChooseYourOwnPriceProduct {
    const offers = Environment.isProduction ? prod_chooseYourPriceOffers : test_chooseYourPriceOffers

    const currency = countryCodeToCurrency(locale)
    const offer = offers[productId]
    const localizedPrice = offer.localizedPrices[currency]

    //get localized pricing text
    const localizedText = createLocalizedPricingText(
      locale,
      localizedPrice.introPrice,
      localizedPrice.price,
      offer.daysFree
    )

    return {
      productCode: offer.productId,
      daysFree: offer.daysFree,
      code: localizedPrice.priceId,
      introPrice: localizedPrice.introPrice,
      price: localizedPrice.price,
      title: localizedText.title,
      sheetLabel: localizedText.sheetLabel,
      subtitle: localizedText.subtitle,
      pricingText: localizedText.pricingText,
    }
  }

  return {
    // $1 Intro for 30 Days then Annual 70 Plan
    '30d01_annual_70': {
      US: _getPricing('30d01_annual_70', 'US'),
      GB: _getPricing('30d01_annual_70', 'GB'),
      CA: _getPricing('30d01_annual_70', 'CA'),
      AU: _getPricing('30d01_annual_70', 'AU'),
      DE: _getPricing('30d01_annual_70', 'DE'),
    },
    // $5 Intro for 30 Days then Annual 70 Plan
    '30d05_annual_70': {
      US: _getPricing('30d05_annual_70', 'US'),
      GB: _getPricing('30d05_annual_70', 'GB'),
      CA: _getPricing('30d05_annual_70', 'CA'),
      AU: _getPricing('30d05_annual_70', 'AU'),
      DE: _getPricing('30d05_annual_70', 'DE'),
    },
    // $10 Intro for 30 Days then Annual 70 Plan
    '30d10_annual_70': {
      US: _getPricing('30d10_annual_70', 'US'),
      GB: _getPricing('30d10_annual_70', 'GB'),
      CA: _getPricing('30d10_annual_70', 'CA'),
      AU: _getPricing('30d10_annual_70', 'AU'),
      DE: _getPricing('30d10_annual_70', 'DE'),
    },
  }
}

// $1 Intro for 30 Days then Annual 70 Plan
const prod_chooseYourPriceOffers: ChooseYourPriceRawOffer = {
  '30d01_annual_70': {
    daysFree: 30,
    productId: '30d01_annual_70',
    localizedPrices: {
      USD: {
        priceId: 'price_1Pb29KH7jib4x1Gy6b1yZwoT',
        price: 69.99,
        introPrice: 1.0,
      },
      EUR: {
        priceId: 'price_1Pb29KH7jib4x1GyOCDUbUAa',
        price: 69.99,
        introPrice: 1.0,
      },
      CAD: {
        priceId: 'price_1Pb29LH7jib4x1Gyk0rEh1lU',
        price: 99.99,
        introPrice: 2.0,
      },
      AUD: {
        priceId: 'price_1Pb29LH7jib4x1GyvAUXxrK1',
        price: 119.99,
        introPrice: 2.0,
      },
      GBP: {
        priceId: 'price_1Pb29LH7jib4x1GybY49SoIk',
        price: 59.99,
        introPrice: 1.0,
      },
    },
  },
  // $5 Intro for 30 Days then Annual 70 Plan
  '30d05_annual_70': {
    daysFree: 30,
    productId: '30d05_annual_70',
    localizedPrices: {
      USD: {
        priceId: 'price_1Pb29tH7jib4x1GyILrRknIQ',
        price: 69.99,
        introPrice: 5.0,
      },
      EUR: {
        priceId: 'price_1Pb29uH7jib4x1GyyYSB1cbU',
        price: 69.99,
        introPrice: 5.0,
      },
      CAD: {
        priceId: 'price_1Pb29uH7jib4x1GyFDAe0uQt',
        price: 99.99,
        introPrice: 7.0,
      },
      AUD: {
        priceId: 'price_1Pb29uH7jib4x1GyUOayURWA',
        price: 119.99,
        introPrice: 8.0,
      },
      GBP: {
        priceId: 'price_1Pb29vH7jib4x1GyQrnhOngz',
        price: 59.99,
        introPrice: 4.0,
      },
    },
  },
  // $10 Intro for 30 Days then Annual 70 Plan
  '30d10_annual_70': {
    daysFree: 30,
    productId: '30d10_annual_70',
    localizedPrices: {
      USD: {
        priceId: 'price_1Pb2A9H7jib4x1GymaCQluEq',
        price: 69.99,
        introPrice: 10.0,
      },
      EUR: {
        priceId: 'price_1Pb2A9H7jib4x1GyLkYU6JIu',
        price: 69.99,
        introPrice: 9.0,
      },
      CAD: {
        priceId: 'price_1Pb2AAH7jib4x1GyfF1JPqet',
        price: 99.99,
        introPrice: 14.0,
      },
      AUD: {
        priceId: 'price_1Pb2ABH7jib4x1Gyy8dpIruZ',
        price: 119.99,
        introPrice: 15.0,
      },
      GBP: {
        priceId: 'price_1Pb2ABH7jib4x1GyAUmSTVZb',
        price: 59.99,
        introPrice: 8.0,
      },
    },
  },
}

//## TEST ##

// $1 Intro for 30 Days then Annual 70 Plan
const test_chooseYourPriceOffers: ChooseYourPriceRawOffer = {
  '30d01_annual_70': {
    daysFree: 30,
    productId: '30d01_annual_70',
    localizedPrices: {
      USD: {
        priceId: 'price_1PalQHH7jib4x1GyHjfMepwE',
        price: 69.99,
        introPrice: 1.0,
      },
      EUR: {
        priceId: 'price_1PalQIH7jib4x1GyNvdrk3P8',
        price: 69.99,
        introPrice: 1.0,
      },
      CAD: {
        priceId: 'price_1PalQIH7jib4x1GyUeRaYG1o',
        price: 99.99,
        introPrice: 2.0,
      },
      AUD: {
        priceId: 'price_1PalQJH7jib4x1GywEKW3BuD',
        price: 119.99,
        introPrice: 2.0,
      },
      GBP: {
        priceId: 'price_1PalQJH7jib4x1GydxVHSRex',
        price: 59.99,
        introPrice: 1.0,
      },
    },
  },
  // $5 Intro for 30 Days then Annual 70 Plan
  '30d05_annual_70': {
    daysFree: 30,
    productId: '30d05_annual_70',
    localizedPrices: {
      USD: {
        priceId: 'price_1PalQZH7jib4x1GyMZDjtMlb',
        price: 69.99,
        introPrice: 5.0,
      },
      EUR: {
        priceId: 'price_1PalQaH7jib4x1Gy47w1OE72',
        price: 69.99,
        introPrice: 5.0,
      },
      CAD: {
        priceId: 'price_1PalQaH7jib4x1GySOZcAI20',
        price: 99.99,
        introPrice: 7.0,
      },
      AUD: {
        priceId: 'price_1PalQbH7jib4x1GyWyOb23Lr',
        price: 119.99,
        introPrice: 8.0,
      },
      GBP: {
        priceId: 'price_1PalQcH7jib4x1Gy31GBlovj',
        price: 59.99,
        introPrice: 4.0,
      },
    },
  },
  // $10 Intro for 30 Days then Annual 70 Plan
  '30d10_annual_70': {
    daysFree: 30,
    productId: '30d10_annual_70',
    localizedPrices: {
      USD: {
        priceId: 'price_1PalQqH7jib4x1GyD6ARy63j',
        price: 69.99,
        introPrice: 10.0,
      },
      EUR: {
        priceId: 'price_1PalQqH7jib4x1GyWRs9PcQGu',
        price: 69.99,
        introPrice: 9.0,
      },
      CAD: {
        priceId: 'price_1PalQrH7jib4x1GyOq7xnK8S',
        price: 99.99,
        introPrice: 14.0,
      },
      AUD: {
        priceId: 'price_1PalQrH7jib4x1GyAaKOqzkX',
        price: 119.99,
        introPrice: 15.0,
      },
      GBP: {
        priceId: 'price_1PalQsH7jib4x1Gys0qHuZKn',
        price: 59.99,
        introPrice: 8.0,
      },
    },
  },
}

// / based on May 2024 Burn/Trials
export const localizedCompensationPrice = {
  US: formatCurrency({
    value: 14.69,
    countryCode: 'US',
    currency: 'USD',
    minimumFractionDigits: 2,
  }),
  CA: formatCurrency({
    value: 20.05,
    countryCode: 'CA',
    currency: 'CAD',
    minimumFractionDigits: 2,
  }),
  AU: formatCurrency({
    value: 21.79,
    countryCode: 'AU',
    currency: 'AUD',
    minimumFractionDigits: 2,
  }),
  GB: formatCurrency({
    value: 11.37,
    countryCode: 'GB',
    currency: 'GBP',
    minimumFractionDigits: 2,
  }),
  DE: formatCurrency({
    value: 13.51,
    countryCode: 'DE',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }),
}
