import { addDays } from 'date-fns'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ActionButton from '../../../../screens/WebOnboarding2/components/ActionButton'
import Analytics from '../../../../utils/Analytics'
import Environment from '../../../../utils/Environment'
import { useQueryToken } from '../../../../utils/dynamicCodes/hooks'
import timeline from '../../assets/Timeline3pt@3x.png'
import bellIcon from '../../assets/bellIcon.svg'
import radialBg from '../../assets/dark-radial-bg.png'
import logo from '../../assets/logo@3x.png'

function ChooseYourPriceTrialReminderScreen() {
  const { t, i18n } = useTranslation()

  const location = useLocation()
  const priceString = new URLSearchParams(location.search).get('price')

  const trialLengthDays = 30
  const trialEndDate = addDays(new Date(), trialLengthDays).toLocaleDateString(i18n.language, {
    month: 'long',
    day: 'numeric',
  })
  const reminderDate = addDays(new Date(), trialLengthDays - 1).toLocaleDateString(i18n.language, {
    month: 'long',
    day: 'numeric',
  })
  const emailAddress = useQueryToken()

  const handleContinue = () => {
    Analytics.track('Email Confirmation Finish Setup Clicked', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
    })
    window.location.href = 'nyx://'
  }

  useEffect(() => {
    Analytics.track('Email Confirmation Finish Setup Viewed', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
    })
  }, [emailAddress])

  return (
    <>
      <Helmet>
        {/* Prevents zooming into the text input when entering a credit card number */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className={`w-full safe-full-screen mx-auto`}>
        <div
          className="flex flex-col relative safe-full-screen"
          style={{
            backgroundImage: `url(${radialBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="flex flex-col flex-1 px-5 items-center justify-top pt-5">
            <img src={logo} style={{ width: 60 }} alt="Rise" />
            <div className="text-white px-10 pt-2 pb-12 text-3xl leading-tight text-center">
              {t('choose_your_price_trial_reminder_screen.title')}
            </div>
            <div className="flex flex-1 w-screen px-2  flex-col justify-between text-white">
              {/* Timeline */}
              <div className="flex flex-column relative mr-10 ml-2 text-sm  leading-tight">
                <img src={timeline} alt="" width={20} className="timeline-image" style={{ marginTop: '5px' }} />

                <div className="absolute left-8" style={{ top: '5px' }}>
                  <h2 className="text-xl font-bold">{t('choose_your_price_trial_reminder_screen.step_1_title')}</h2>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey="choose_your_price_trial_reminder_screen.step_1_subtitle"
                  />
                </div>

                <div className="absolute left-8" style={{ top: '75px' }}>
                  <h2 className="text-xl font-bold">
                    {reminderDate}
                    <img src={bellIcon} alt="bell" className="ml-2 inline" />
                  </h2>
                  <Trans
                    components={{ highlight: <strong /> }}
                    i18nKey="choose_your_price_trial_reminder_screen.step_2_subtitle"
                    values={{ emailAddress }}
                  />
                </div>

                <div className="absolute left-8 " style={{ top: '190px' }}>
                  <h2 className="text-xl font-bold">{trialEndDate}</h2>
                  {priceString
                    ? t('choose_your_price_trial_reminder_screen.step_3_subtitle', { annualPrice: priceString })
                    : t('confirmation_screen.step_4_subtitle')}
                </div>
              </div>

              {/* CTA */}
              <div className="p-2">
                <ActionButton title={t('common.FINISH_SETUP')} onClick={handleContinue} />

                <div className="my-2 text-center text-sm text-purple-2 font-bold">
                  <a href="mailto:support@risescience.com">{t('common.Contact_us')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseYourPriceTrialReminderScreen
