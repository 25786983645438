import React from "react";
import logo from "../../../images/logo.svg";
import {UiState} from "./EmailSignUp";
import ActionButton from "../components/ActionButton";
import Title from "../components/Title";
import AlertMessage from "../components/AlertMessage";
import AccountSuccess from "../components/AccountSuccess";
import NeedHelpLink from "../components/NeedHelpLink";
import DisabledActionButton from "../components/DisabledActionButton";
import {ApiError} from "../../../utils/webOnboarding2/constants";

// TODO: 'error' is no longer used, could be removed, errorState is reliant on errorInfo
export function EmailSignUpView(props: {
  email: string;
  error: boolean;
  errorInfo: ApiError;
  onChange: (e: any) => void;
  uiState: UiState;
  onCreateProfileClick: () => void;
  onAccountCreateSuccess: () => void;
}) {
  const enableButton = props.uiState === "valid_email";

  const showNetworkError = props.errorInfo === ApiError.NetworkError;
  const showEmailTakenError = props.errorInfo === ApiError.EmailTaken;
  const errorExists = props.errorInfo !== ApiError.None;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="mx-auto w-px-375 sm:w-px-640  relative z-10">
        <div className={"pt-6 md:py-8"}>
          <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
        </div>
        <div
          className={`w-px-375 md:w-px-600 ${
            window.innerHeight < 800 ? "space-y-20" : "space-y-40"
          } sm:space-y-0 sm:pt-20`}
        >
          <div className="pt-8 flex flex-col">
            <text className={`text-3xl text-white pb-5 px-3`}>
              7-Day Free Trial
            </text>
            {props.uiState !== "signUpSuccess" && (
              <Title
                title={"Sign up for RISE"}
                subtitle={`You’ll use this email address to log in to the app after you download it.`}
              />
            )}
            {showNetworkError && (
              // show if there is an API error (not taken email or invalid email)
              // do not show the red error messages
              // do not show Need Help link
              <AlertMessage
                title={"Sorry, there was a problem."}
                subtitle={"Please try entering your email again."}
              />
            )}

            {props.uiState !== "signUpSuccess" && (
              <div className="text-white pt-4 pb-6 px-3">
                <label className="block text-gray-700 text-sm mb-2 ">
                  Email
                </label>
                <div>
                  <input
                    type="email"
                    name="name"
                    value={props.email}
                    className={`w-full md:w-full form-input bg-panelGray-1 border py-4 rounded-none relative ${
                      errorExists ? "border-red-1" : "border-gray-4"
                    }`}
                    onChange={props.onChange}
                  />
                  {/*{showErrorState && (*/}
                  {/*  // show if user enters invalid email or taken email*/}
                  {/*  // onClick should set the input value to ''*/}
                  {/*  // there is not yet a function set up to clear the input*/}
                  {/*  <ClearInputButton*/}
                  {/*    onClick={() => console.log("clear email input")}*/}
                  {/*  />*/}
                  {/*)}*/}
                </div>


                {showEmailTakenError && (
                  // show error if email is taken AFTER account creation attempt
                  <div className={`pt-2`}>
                    <text className={`text-sm text-red-1 font-medium`}>
                      Email is already taken.
                    </text>
                  </div>
                )}

                {/*{showErrorState && (*/}
                {/*  // show error if email is invalid AFTER account creation attempt*/}
                {/*  // currently this is showing as the user is typing*/}
                {/*  // may need to add setTimeout or wait until they are done typing*/}
                {/*  <div className={`pt-2`}>*/}
                {/*    <text className={`text-sm text-red-1 font-medium`}>*/}
                {/*      Invalid email. Please try again.*/}
                {/*    </text>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            )}

            {props.uiState === "signUpSuccess" && (
              <AccountSuccess
                email={props.email}
                onClick={props.onAccountCreateSuccess}
              />
            )}

            {enableButton ? (
              <div className={"w-full px-3"}>
                <ActionButton
                  title={"CREATE AN ACCOUNT"}
                  onClick={props.onCreateProfileClick}
                />
              </div>
            ) : (
              <div className={"w-full px-3"}>
                {props.uiState !== "signUpSuccess" && (
                  <DisabledActionButton
                    title={"CREATE AN ACCOUNT"}
                    onClick={() => {}}
                  />
                )}
                {/* NOTE: Need Help link is showing in 2 out of 3 error situations
                  Currently, it is set up to show whenever there is any type of error
                   */}
                {errorExists && (
                  <div className={"pt-2"}>
                    <NeedHelpLink />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
