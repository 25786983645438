import React from 'react'

import {
  BodyCopy,
  BrandHeadline,
  Content,
  DoubleColumnSlide,
  LargeHeadline,
  LawSidebar,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const LawTwoDetails: React.FC = () => {
  useSlideTrackEvt('LawTwoDetails')

  return (
    <DoubleColumnSlide>
      <div>
        <Content>
          <BrandHeadline>the Sleep science</BrandHeadline>
          <LargeHeadline>
            It&apos;s not lunch. Your midday energy dip is normal.
          </LargeHeadline>
          <BodyCopy>
            Our{' '}
            <strong>
              <em>Circadian Rhythm</em>
            </strong>{' '}
            is the internal “body clock” that determines your energy levels
            throughout the day. While we all feel a loss of energy in the
            afternoon, the timing and severity are based on several factors
            including sleep, biology, and level of Sleep Debt.
          </BodyCopy>
        </Content>
      </div>
      <div>
        <LawSidebar num={2} />
      </div>
    </DoubleColumnSlide>
  )
}
