import React from 'react';
import { animated, useSpring } from 'react-spring';

export interface EnergyScheduleAnimationProps {
  step: number;
  onStepComplete?: (step: number) => void;
}

export const EnergyScheduleAnimation = ({
  step,
  onStepComplete,
}: EnergyScheduleAnimationProps) => {
  const { s } = useSpring({
    s: step,
    config: { duration: 1000 },
    onRest: () => {
      onStepComplete && onStepComplete(step);
    },
  });

  return (
    <div style={{ marginLeft: -4,  marginTop:-4, position: 'relative' }}>
      <animated.div
        style={{
          transform: s.to({
            range: [0, 1, 2, 3, 4, 5, 6],
            output: [
              'translateY(0%)',
              'translateY(-13%)',
              'translateY(-40%)',
              'translateY(-40%)',
              'translateY(-50%)',
              'translateY(-68%)',
              'translateY(-68%)',
            ],
          }),
        }}
      >
        <EnergyOkay />
      </animated.div>

    </div>
  );
};

const EnergyOkay = () => {
  return (
    <svg
      width="282"
      height="3071"
      viewBox="0 0 282 3071"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1.5L1.5 1.5V3V3067.25V3068.75H3H40.8213H42.3213V3067.25V2563.01C42.3213 2437.59 99.5589 2355.97 158.033 2272.58L160.962 2268.4C220.427 2183.57 280.146 2096.18 280.146 1958.75C280.146 1821.59 255.955 1753.92 231.745 1698.34C229.131 1692.33 226.519 1686.48 223.939 1680.69C202.677 1633.03 183.594 1590.24 183.594 1511.47C183.594 1454.51 202.221 1419.48 223.223 1379.99C226.02 1374.73 228.859 1369.39 231.701 1363.92C255.921 1317.23 280.146 1260.66 280.146 1156.18C280.146 1042.36 227.538 981.936 172.015 918.162C168.307 913.904 164.587 909.631 160.869 905.327C131.156 870.928 101.543 834.496 79.3384 787.349C57.143 740.22 42.3213 682.315 42.3213 604.974V182.1V3.00004V1.50005L40.8213 1.50004L3 1.5Z"
        stroke="#16161A"
        stroke-width="3"
      />
      <path
        d="M3.00317 1.5L1.50317 1.5V3V3067.25V3068.75H3.00317H40.8244H42.3244V3067.25V2543.64C42.3244 2417 84.7067 2337.42 127.575 2256.93L128.589 2255.03C171.827 2173.83 215.205 2091.35 215.205 1958.75C215.205 1830.89 192.608 1761.72 170.016 1702.03C168.048 1696.83 166.081 1691.7 164.13 1686.62C143.716 1633.42 125.022 1584.71 125.022 1502.9C125.022 1441.97 143.093 1405.99 163.145 1366.06C165.399 1361.57 167.678 1357.03 169.959 1352.41C192.579 1306.51 215.205 1252.01 215.205 1149.43C215.205 1056.52 179.66 997.88 140.941 934.008C136.832 927.23 132.688 920.393 128.546 913.45C85.4025 841.122 42.311 756.934 42.311 607.031C42.311 451.94 42.3144 345.707 42.3177 278.247C42.3194 244.517 42.3211 220.48 42.3223 204.876L42.3239 187.637L42.3243 183.465L42.3244 182.439L42.3244 182.185L42.3244 182.121L42.3244 182.105V182.102C42.3244 182.101 42.3244 182.1 40.8244 182.1L42.3244 182.1V3.00004V1.50005L40.8244 1.50004L3.00317 1.5Z"
        fill="#16161A"
        stroke="url(#paint0_linear)"
        stroke-width="3"
      />
      <path
        d="M3 1.5L1.5 1.5V3V3067.25V3068.75H3H40.8213H42.3213V3067.25V2543.64C42.3213 2417.04 90.2888 2337.48 138.831 2256.97L139.971 2255.08C188.919 2173.89 238.051 2091.37 238.051 1958.72C238.051 1830.86 215.986 1765.9 193.927 1708.31C192.495 1704.57 191.064 1700.87 189.639 1697.18C169.161 1644.16 150.001 1594.56 150.001 1502.87C150.001 1461.26 155.484 1432.13 163.676 1408.43C170.773 1387.91 179.909 1371.42 189.39 1354.32C190.867 1351.65 192.353 1348.97 193.841 1346.26C204.87 1326.14 215.94 1304.33 224.24 1273.82C232.537 1243.33 238.051 1204.19 238.051 1149.4C238.051 1045.85 194.342 980.491 148.386 911.774C145.574 907.569 142.754 903.352 139.935 899.113C91.0588 825.618 42.3213 745.271 42.3213 608.06V182.1V3.00004V1.50005L40.8213 1.50004L3 1.5Z"
        stroke="#16161A"
        stroke-width="3"
      />
      <path
        d="M3.00317 1.5L1.50317 1.5V3V3067.25V3068.75H3.00317H40.8244H42.3244V3067.25V2543.64C42.3244 2416.92 74.6406 2337.28 107.298 2256.81L108.079 2254.88C141.028 2173.68 174.053 2091.26 174.053 1958.75C174.053 1830.92 155.126 1765.98 136.2 1708.41C134.972 1704.67 133.744 1700.96 132.521 1697.27C114.948 1644.26 98.4976 1594.62 98.4976 1502.9C98.4976 1458.03 103.208 1427.92 110.24 1404.42C116.427 1383.74 124.418 1368.14 132.693 1351.99C133.829 1349.77 134.97 1347.54 136.113 1345.29C145.582 1326.62 155.085 1306.34 162.207 1276.35C169.325 1246.38 174.053 1206.78 174.053 1149.43C174.053 1045.44 146.954 992.361 117.407 934.482C114.299 928.394 111.164 922.253 108.03 915.994C75.1882 850.393 42.3244 771.516 42.3244 603.946V182.1V3.00004V1.50005L40.8244 1.50004L3.00317 1.5Z"
        stroke="black"
        stroke-width="3"
      />
      <path
        d="M3 1.5L1.5 1.5V3V3067.25V3068.75H3H40.8213H42.3213V3067.25V2543.64C42.3213 2417 84.7035 2337.42 127.572 2256.93L128.586 2255.03C171.824 2173.83 215.202 2091.35 215.202 1958.75C215.202 1830.89 192.482 1761.72 169.768 1702.03C167.789 1696.83 165.812 1691.7 163.85 1686.62C143.325 1633.42 124.53 1584.71 124.53 1502.9C124.53 1441.97 142.698 1405.99 162.859 1366.06C165.125 1361.57 167.417 1357.04 169.71 1352.41C192.453 1306.51 215.202 1252.01 215.202 1149.43C215.202 1056.52 179.657 997.88 140.938 934.008C136.829 927.23 132.685 920.393 128.543 913.45C85.3993 841.122 42.3079 756.934 42.3079 607.031C42.3079 451.94 42.3112 345.707 42.3146 278.247C42.3162 244.517 42.3179 220.48 42.3192 204.876L42.3207 187.637L42.3211 183.465L42.3212 182.439L42.3213 182.185L42.3213 182.121L42.3213 182.105V182.102C42.3213 182.101 42.3213 182.1 40.8213 182.1L42.3213 182.1V3.00004V1.50005L40.8213 1.50004L3 1.5Z"
        stroke="url(#paint1_linear)"
        stroke-width="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="57.2356"
          y1="119.182"
          x2="57.2356"
          y2="3045.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.166667" stop-color="#721CFF" />
          <stop offset="0.34375" stop-color="#FD8206" />
          <stop offset="0.46875" stop-color="#721CFF" />
          <stop offset="0.619792" stop-color="#FD8206" />
          <stop offset="0.776042" stop-color="#721CFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="57.2324"
          y1="119.182"
          x2="57.2324"
          y2="3045.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.166667" stop-color="#721CFF" />
          <stop offset="0.34375" stop-color="#FD8206" />
          <stop offset="0.46875" stop-color="#721CFF" />
          <stop offset="0.619792" stop-color="#FD8206" />
          <stop offset="0.776042" stop-color="#721CFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
