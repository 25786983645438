import packageJson from '../../package.json'
import Environment from './Environment'
import * as Sentry from '@sentry/react'

const metaPayload = () => {
  return {
    version: packageJson.version,
    environment: Environment.getVar('REACT_APP_ENV') ?? 'test',
    host: window.location.host,
    url: window.location.href,
    urlParams: window.location.search,
  }
}

function track(eventName: string, payload: object = {}) {
  const completePayload = { ...metaPayload(), ...payload }
  window.analytics.track(eventName, completePayload)

  if (Environment.inTest) {
    logAnalytics(eventName, completePayload)
  }
}

const logAnalytics = (eventName: string, completePayload?: object) => {
  console.log(`Sent to Amplitude: "${eventName}". Payload: ${JSON.stringify(completePayload, null, '\t')}`)
}

type IdentifyPayload = Record<string, any>
type AnonymousIdentify = (payload: IdentifyPayload) => void
type IdentifiedIdentify = (id: string | number, payload?: IdentifyPayload) => void
const identify: IdentifiedIdentify & AnonymousIdentify = (
  idOrPayload: string | number | IdentifyPayload,
  payload?: IdentifyPayload
) => {
  if (typeof idOrPayload === 'string' || typeof idOrPayload === 'number') {
    //id if known id
    window.analytics.identify(idOrPayload, { ...metaPayload(), ...payload })
  } else {
    //payload if anonymous id
    window.analytics.identify({ ...metaPayload(), ...idOrPayload })
  }
}

const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const analyticsIsReady = () => !Array.isArray(window.analytics)

/**
 * In some cases, we need to ensure that Segment is initialized before sending events
 * (redirecting back to the Checkout Success Page from Stripe Checkout is one example)
 * By default: waits 200 ms and retries 5 times.
 */
const onReady = async (analytics: () => void, retries: number = 5, waitInMs: number = 200): Promise<void> => {
  for (let i = 0; i < retries; i++) {
    if (analyticsIsReady()) {
      analytics()
      return
    }
    await waitFor(waitInMs)
  }
  Sentry.captureException(new Error("Analytics didn't initialize within window"))
}

const Analytics = {
  track,
  identify,
  onReady,
}
export default Analytics
