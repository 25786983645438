import React from 'react'

import {
  BodyCopy,
  BrandHeadline,
  Content,
  DoubleColumnSlide,
  LargeHeadline,
  LawSidebar,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const LawOneTired: React.FC = () => {
  useSlideTrackEvt('LawOneTired')

  return (
    <DoubleColumnSlide>
      <div>
        <Content>
          <BrandHeadline>the Sleep science</BrandHeadline>
          <LargeHeadline>The reason you wake up tired.</LargeHeadline>
          <BodyCopy>
            We all need a minimum amount of sleep each night. For every night
            that we sleep less than our need, we accumulate{' '}
            <strong>
              <em>Sleep Debt</em>
            </strong>
            . It is the #1 predictor of how you will feel and perform during the
            day. Many of us try to catch over the weekends, but research has
            shown we can&apos;t recover from 5 days of Sleep Debt in only 1-2
            nights.
          </BodyCopy>
        </Content>
      </div>
      <div>
        <LawSidebar num={1} />
      </div>
    </DoubleColumnSlide>
  )
}
