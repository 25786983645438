import React, { useEffect, useState } from 'react'
import logo from '../images/logo.svg'

function LoadingScreen() {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        color: 'white',
        backgroundColor: 'black',
        flexDirection: 'column',
      }}
    >
      {visible ? <img src={logo} alt="RISE" style={{ width: 66 }} /> : null}
    </div>
  )
}

export default LoadingScreen
