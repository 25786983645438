import React from 'react'

interface ActionButtonProps {
  title: string
  onClick: () => void
  icon?: string // Optional icon img src
  disabled?: boolean
}

const ActionButton = ({ title, onClick, icon, disabled }: ActionButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`bg-purple-1 rounded-md text-md tracking-widest text-white p-5 w-full flex items-center justify-center ${
        disabled ? 'bg-gray-1 opacity-50 cursor-not-allowed' : ''
      }`}
      disabled={disabled}
    >
      {icon && <img src={icon} alt="Icon" className="w-4 h-4 mr-3" />}
      {title}
    </button>
  )
}

export default ActionButton
