import * as Sentry from '@sentry/react';

import React, {useEffect, useState} from 'react';

import Analytics from '../../utils/Analytics';
import ButtonError from '../../components/ButtonError';
import ButtonProcessing from '../../components/ButtonProcessing';
import ButtonRedeem from '../../components/ButtonRedeem';
import { ErrorMessage } from '../../utils/ErrorMessage';
import MaybeStagingBadge from '../../components/MaybeStagingBadge';
import logo from '../../images/logo.svg';
import profile from '../../images/profile.svg';
import { redirectToStripe } from '../../utils/redirectToStripe';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {abbreviateFixedDiscount, useOffer, useQueryToken, useUserConfiguration} from "../../utils/dynamicCodes/hooks";
import {DynamicCodeProps} from "../../utils/dynamicCodes/types";
import LoadingSpinner from "../../components/LoadingSpinner";
import {Offer} from "../../utils/getDiscountOffer";
import sleep_sensing from "./sleep_sensing.svg";
import squiggly from "./squiggly.svg";
import bell from "./bell.svg"
import check from "./check.svg"
import agenda from "./agenda.svg"
import logos from "./recommended-logos.png"


const daysFreeMessage = (offer: Offer | null) => {
    if (offer && offer.daysFree) {
        if (offer.daysFree === 7) {
            return `Get 1 week free!`
        }
        return `Get ${offer.daysFree} days free!`
    }
    return `Get 7 days free!`
}


type UiState = 'loading' | 'ready' | 'processing' | 'error';
const StripeTestFromAppOffer = ({match}: DynamicCodeProps) => {
    const code = match.params.name;

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ErrorMessage | null>(null);
    const token = useQueryToken();
    const offer = useOffer(code);
    const [userConfig, configError] = useUserConfiguration(code, token);

    useDocumentTitle('Rise Thanks You');

    const uiState: UiState = ((): UiState => {
        if (error) return 'error';
        if (userConfig && !processing) return 'ready';
        if (!userConfig) return 'loading';
        if (processing) return 'processing';
        return 'ready';
    })();

    useEffect(() => {
        if (uiState === 'ready') {
            Analytics.track('Redeem offer shown', {
                stripe_price: userConfig?.stripe_price,
                code
            });
            Analytics.track(`${code} Page Viewed`);
        }
    }, [uiState, userConfig?.stripe_price, code]);

    useEffect(() => {
        if (configError !== null) {
            setError(ErrorMessage.FetchingConfiguration)
        }
    }, [configError])

    useEffect(() => {
        if (error) {
            Sentry.captureException(new Error(error));
        }
    }, [error]);

    async function redirectToStripeCheckout() {
        if (!userConfig) return;
        setProcessing(true);
        try {
            Analytics.track('Redeem offer clicked', {
                stripe_price: userConfig.stripe_price,
            });
            await redirectToStripe(userConfig);
        } catch (e) {
            setProcessing(false);
            setError(e);
        }
    }

    return (
        <div className="w-full h-screen mx-auto" id="black-friday-2021">
            <div>
                <div className="absolute w-full h-full bg-opacity-50 bgGradient z-0" />
                    <div className={`absolute w-full h-full bg-opacity-50 bgHeroSun z-0 `} />
                <div className="absolute w-full h-full bg-opacity-50 bgFade z-0" />
            </div>

            <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
                <MaybeStagingBadge />
                <div
                    className={`w-px-320 ${
                        window.innerHeight < 800 ? 'space-y-5' : 'space-y-20'
                    } sm:space-y-0 sm:pt-48`}
                >
                    <div
                        className={`opacity-0 border-b text-shadow border-white border-opacity-25 text-white flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
                            token && 'opacity-100'
                        }`}
                        style={{ textShadow: '1px 1px 5px  #000' }}
                    >
                        <img src={profile} alt="" className="inline mr-2 w-5" />
                        {token ?? '...'}
                    </div>

                    <div className="pt-5 flex flex-col">
                        <div style={{ height: 20 }}>
                            <img src={logo} alt="RISE" className="self-start" />
                        </div>
                        <div style={{visibility: uiState === 'loading' ? 'hidden' : 'visible', position: 'relative', marginTop: 0}}>
                            <LoadingSpinner uiState={uiState} />
                            {offer?.fixedDiscount ?
                                <>
                                    <h1 className="text-white pt-2 pb-3 text-3xl font-bold leading-tight">
                                        Get a {abbreviateFixedDiscount(offer)} discount!
                                    </h1>
                                    <h1 className="text-white pb-3 text-3m font-bold leading-tight">
                                        Try free for {offer ? offer.daysFree : 7} days
                                    </h1>
                                </>
                                :
                                <>
                                    {offer && offer.discountPercentage !== '0%' ?
                                        <h1 className="text-white pt-4 pb-3 text-4xl font-bold leading-tight" style={{visibility: offer?.discountPercentage !== '0%' ? 'visible' : 'hidden'}}>
                                            {`${offer.discountPercentage} off`}
                                        </h1>
                                        :
                                        null
                                    }
                                    <h1 className="text-white pb-3 text-3xl font-bold leading-tight pt-3">
                                        {daysFreeMessage(offer)}
                                    </h1>
                                </>
                            }

                            <div className="pb-4" style={{textAlign: "center"}}>
                                <img src={logos} alt=""  className="inline pr-4" style={{ height: 50 }} />
                            </div>

                            <ul className="list-none">
                                <li key={1} className="text-white flex font-medium items-start pb-3">
                                    <img src={sleep_sensing} alt="" className="inline" style={{ height: 20, marginRight: 10 }} />
                                    Automatically track your sleep debt
                                </li>
                                <li key={2} className="text-white flex font-medium items-start pb-3">
                                    <img src={squiggly} alt="" className="inline" style={{ height: 20, marginRight: 10 }} />
                                    Plan when to workout or take on challenging ideas with daily energy predictions
                                </li>
                                <li key={3} className="text-white flex font-medium items-start pb-3">
                                    <img src={bell} alt="" className="inline" style={{ height: 20, marginRight: 10 }} />
                                    Get reminders to build better habits and adjust your sleep schedule
                                </li>
                                <li key={4} className="text-white flex font-medium items-start pb-3">
                                    <img src={agenda} alt="" className="inline" style={{ height: 20, marginRight: 10 }} />
                                    Unlimited 1-1 Sleep Coaching
                                </li>
                            </ul>


                            <div className="text-white pb-3 mt-3">
                                {offer && offer?.originalPrice !== offer?.price ?
                                    <p>
                                                <span className="text-2xl font-medium line-through text-gray-3">
                                                  {offer.originalPrice}
                                                </span>{' '}
                                        <span className="text-2xl font-medium text-white">
                                                    {offer.price}
                                                </span>
                                        <span> per {offer?.billingInterval ? offer.billingInterval : 'year'}</span>
                                    </p>
                                    :
                                    <>
                                        <p>
                                                <span className="text-4xl font-medium text-white">
                                                  $0
                                                </span>{' '}
                                                <span style={{fontSize: '0.8em'}}> DUE TODAY, THEN {offer ? offer.price : '$59.99'} ($4.99/mo)</span>
                                        </p>
                                        <span style={{fontSize: '0.8em'}}>BILLED YEARLY AFTER TRIAL • CANCEL ANYTIME</span>
                                    </>
                                }
                            </div>
                            <div className="mb-3 pt-3 pb-3 text-white border-solid border-2 border-gray-4 rounded-md" style={{fontSize: '0.85em', textAlign:"center"}}>
                                <img src={check} alt="" className="inline" style={{ height: 20, marginRight: 10 }} />
                                Get reminded before your free trial ends
                            </div>
                        </div>
                        <div className="mb-10">
                            {uiState === 'error' && (
                                <ButtonError
                                    testId="errorButton"
                                    title={error ? error.toUpperCase() : 'Error processing request'}
                                />
                            )}
                            {uiState === 'loading' && (
                                <ButtonProcessing
                                    testId="retrievingButton"
                                    title="RETRIEVING YOUR OFFER..."
                                />
                            )}
                            {uiState === 'ready' && (
                                <ButtonRedeem
                                    testId="redeemButton"
                                    title="TRY FOR FREE"
                                    onClick={redirectToStripeCheckout}
                                />
                            )}
                            {uiState === 'processing' && (
                                <ButtonProcessing
                                    testId="processingButton"
                                    title="PROCESSING..."
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StripeTestFromAppOffer;
