/** Returns a SleepDebtState as a string for the given sleep debt minutes*/
/** Follows the same rules as in Nyx */
export function getSleepDebtState(sleepDebtMinutes: number): SleepDebtState {
  const options: { state: SleepDebtState; from: number; to: number }[] = [
    {
      state: 'great',

      from: -Infinity,
      to: 0 * 60,
    },
    {
      state: 'good',

      from: 0 * 60,
      to: 5 * 60,
    },
    {
      state: 'okay',

      from: 5 * 60,
      to: 12 * 60,
    },
    {
      state: 'poor',

      from: 12 * 60,
      to: Infinity,
    },
  ];
  const { state } =
    options.find((option) => {
      return sleepDebtMinutes > option.from && sleepDebtMinutes <= option.to;
    }) ?? {};

  return state ?? 'okay';
}

export type SleepDebtState = 'okay' | 'poor' | 'good' | 'great';