import React from 'react'

import './Slides.css'

export const SingleColumnSlide: React.FC = ({ children }) => (
  <div className="ob3__slide">{children}</div>
)

export const DoubleColumnSlide: React.FC = ({ children }) => (
  <div className="ob3__slide--double-column">{children}</div>
)
