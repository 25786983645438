import React, { useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { delay } from '../../../../../utils/webOnboarding2/animationUtils';
import { minutesToDecimal } from '../../../../../utils/webOnboarding2/formatting';
import {
  getSleepDebtState,
  SleepDebtState,
} from '../../../../../utils/webOnboarding2/sleepDebt';
import {
  EllipseGood,
  EllipseGreat,
  EllipseOkay,
  EllipsePoor,
  EnergyLevels,
} from './ChartGraphics';

const globalConfig = {
  size: 350,
  orbStroke: 1,
  purple: '#721CFF',
  panelGray1: '#16161A',
  minOrbRadius: 75,
};

const center = globalConfig.size / 2;

const sdConfig: Record<
  SleepDebtState,
  { orbRadius: number; EnergyArc: () => JSX.Element }
> = {
  okay: {
    orbRadius: 74,
    EnergyArc: EllipseOkay,
  },
  poor: {
    orbRadius: 273 / 2,
    EnergyArc: EllipsePoor,
  },
  good: {
    orbRadius: 48 / 2,
    EnergyArc: EllipseGood,
  },
  great: {
    orbRadius: 0,
    EnergyArc: EllipseGreat,
  },
};

export interface SleepDebtAnimationProps {
  sleepDebtMinutes: number;
  onRest?: () => void;
}

export const SleepDebtAnimation = React.memo(
  ({ sleepDebtMinutes, onRest }: SleepDebtAnimationProps) => {
    const sleepDebtState: SleepDebtState = getSleepDebtState(sleepDebtMinutes);
    const [finished, setFinished] = useState(false);
    const cfg = sdConfig[sleepDebtState];

    const orbFinalSpot =
      globalConfig.size - cfg.orbRadius + globalConfig.orbStroke / 2;
    const textWidth = Math.max(globalConfig.minOrbRadius, cfg.orbRadius) * 2;
    const finalTextSpot = ((): number => {
      if (sleepDebtState === 'poor' || sleepDebtState === 'okay') {
        return orbFinalSpot - textWidth / 2;
      }
      if (sleepDebtState === 'good') {
        return center - 30;
      }
      return center - 0;
    })();

    const animate = useSpring({
      cancel: finished,
      config: {
        ...config.molasses,
      },
      to: async (next) => {
        await next({
          r: cfg.orbRadius,
          fill: globalConfig.purple,
          stroke: 'transparent',
        });
        await delay(400);
        await next({
          textX: finalTextSpot,
          fill: globalConfig.panelGray1,
          cx: orbFinalSpot,
          energyArcOpacity: 1,
          energyArcCx: 'translate(0%,0)',
          energyLevelOpacity: 1,
        });
      },
      from: {
        textX: center - textWidth / 2,
        cx: center,
        r: center,
        stroke: globalConfig.purple,
        fill: globalConfig.panelGray1,
        energyArcOpacity: 0,
        energyArcCx: 'translate(-20%,0)',
        energyLevelOpacity: 0,
      },
      onRest: () => {
        setFinished(true);
        onRest && onRest();
      },
    });
    return (
      <svg viewBox={`0 0 ${globalConfig.size} ${globalConfig.size}`}>
        <animated.g
          style={{
            transform: animate.energyArcCx,
            opacity: animate.energyArcOpacity,
          }}
        >
          <cfg.EnergyArc />
        </animated.g>
        <animated.g
          style={{
            transform: 'translateX(20px)',
            opacity: animate.energyLevelOpacity,
          }}
        >
          <EnergyLevels />
        </animated.g>
        <animated.circle
          cy={center}
          strokeWidth={globalConfig.orbStroke}
          r={animate.r}
          stroke={animate.stroke}
          fill={animate.fill}
          cx={animate.cx}
        />
        <animated.svg width={textWidth} fill={'white'} x={animate.textX}>
          <text y={center}>
            <tspan x="50%" text-anchor="middle" style={{ fontSize: 34 }}>
              {minutesToDecimal(sleepDebtMinutes)}
              <tspan
                dx={3}
                dy="-7"
                text-anchor="middle"
                style={{ fontSize: 14 }}
              >
                {minutesToDecimal(sleepDebtMinutes) === 1 ? 'hr' : 'hrs'}
              </tspan>
            </tspan>
            <tspan
              x="50%"
              dy="28"
              text-anchor="middle"
              style={{ fontSize: 18 }}
            >
              Sleep Debt
            </tspan>
          </text>
        </animated.svg>
      </svg>
    );
  }
);

export const SleepDebtLoadingAnimation = () => {
  const textWidth = globalConfig.minOrbRadius * 2;

  const [flip, set] = useState(false);
  const animate = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0.3 },
    reset: true,
    reverse: flip,
    delay: 200,
    config: {
      duration: 800,
    },
    onRest: () => set(!flip),
  });
  return (
    <svg viewBox={`0 0 ${globalConfig.size} ${globalConfig.size}`}>
      <animated.circle
        cy={center}
        cx={center}
        r={center}
        stroke={globalConfig.purple}
        strokeWidth={globalConfig.orbStroke}
      />
      <svg width={textWidth} fill={'white'} x={center - textWidth / 2}>
        <animated.text y={center} style={{ opacity: animate.opacity }}>
          <tspan x="50%" text-anchor="middle" style={{ fontSize: 18 }}>
            Calculating...
          </tspan>
        </animated.text>
      </svg>
    </svg>
  );
};
