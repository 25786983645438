import { Offer } from '../getDiscountOffer'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Environment from '../Environment'
import { UserConfiguration } from '../fetchUserConfiguration'
import { useLocation } from 'react-router-dom'
import Analytics from '../Analytics'
import * as Sentry from '@sentry/react'
import { useSourceFromParams } from '../../hooks/useTokenFromParams'
import { UserAlreadySubscribedMessage } from './redirects'

export const BASE_URL = 'https://api.risesci.com'
// figure out better way to switch for testing
//export const BASE_URL = 'http://localhost:8000'

export function useOffer(code: string): Offer | null {
  const [offer, setOffer] = useState(null)
  const shouldClearCache = useClearCacheFromParams()

  const getNewOffer = async (code: string) => {
    const data = {
      code,
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      clear_cache: shouldClearCache,
    }

    const response = await axios.post(`${BASE_URL}/api/stripe-offer`, data)

    if (response.status !== 200) {
      Analytics.track('Could not fetch offer', {
        code,
        env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      })
      throw new Error(`Status: ${response.status} ${response.statusText}`)
    }
    return response.data
  }

  useEffect(() => {
    getNewOffer(code).then((result) => setOffer(result))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return offer
}

export function useValueParams(value: string): string | null {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  return params.get(value)
}

function useNewUserFromParams(): boolean {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  return params.get('new_user') === '1'
}

export function useInviteIdFromParams(): string | null {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  return params.get('invite_id')
}

export function useClearCacheFromParams(): boolean {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  return Boolean(params.get('clear_cache'))
}

interface RequestParams {
  token: string
  env: string
  success_url: string
  cancel_url: string
  coupon?: string
}

export function useUserConfiguration(
  code: string,
  token: string | null
): [UserConfiguration | null, Error | null] {
  const [userConfiguration, setUserConfiguration] = useState(null)
  const [error, setError] = useState(null)
  const isNewUser = useNewUserFromParams()
  const paramSource = useSourceFromParams()

  useEffect(() => {
    const getRedirectUrls = () => {
      const redirectSuccess = {
        newUser: `${document.location.origin}/offer/success?token=${
          token ? encodeURIComponent(token) : token
        }&code=${code}&report=1`,
        mobile: 'https://rise-sleep.app.link/KlNmSZAstbb',
        desktop: `${document.location.origin}/thankyou?user_id=${token}`,
        app: `${document.location.origin}/payment-complete?user_id=${token}`,
      }
      let source: keyof typeof redirectSuccess
      if (paramSource === 'app') {
        source = 'app'
      } else {
        source = 'newUser' // 2022-06-14 Show the updated dynamic success page for all users
      }
      return {
        success: redirectSuccess[source],
        cancel: document?.location?.href,
      }
    }

    const getUserConfiguration = async (code: string, token: string) => {
      const redirectUrls = getRedirectUrls()

      const params: RequestParams = {
        token,
        coupon: code,
        success_url: redirectUrls.success,
        cancel_url: redirectUrls.cancel,
        env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      }

      try {
        const response = await axios.get(
          `${BASE_URL}/api/stripe-checkout-configuration`,
          { params }
        )
        if (response.status !== 200) {
          throw new Error(`Status: ${response.status} ${response.statusText}`)
        } else {
          return [response.data, null]
        }
      } catch (e) {
        if (e.response?.data === UserAlreadySubscribedMessage) {
          return [null, new Error('User already subscribed')]
        }
        Sentry.captureException(new Error(e))
        Analytics.track('Unable to find dynamic offer', {
          code,
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          email: token,
        })
        return [null, e]
      }
    }

    if (token) {
      getUserConfiguration(code, token).then(([config, error]) => {
        if (config !== null) {
          // Update Sentry Identity
          Sentry.setUser({ email: token, ...config })
          // Setup Analytics Identity
          Analytics.identify(config.person_id, config)
          Analytics.track('Retrieved user config', config)
        }
        setUserConfiguration(config)
        setError(error)
      })
    }
  }, [code, token, isNewUser, paramSource])

  return [userConfiguration, error]
}

export function useQueryToken() {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  if (params.get('token')) {
    const token = decodeURIComponent(params.get('token') as string)
    return token.replace(' ', '+')
  }
  return params.get('user_id')
}

export function useEmail() {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  if (params.get('email')) {
    const token = decodeURIComponent(params.get('email') as string)
    return token.replace(' ', '+')
  }
  return ''
}

export function useCode() {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  if (params.get('code')) {
    return decodeURIComponent(params.get('code') as string)
  }
  return ''
}

export function abbreviateFixedDiscount(offer: Offer): string {
  if (offer.fixedDiscount) {
    const lastTwoDigits = offer.fixedDiscount.slice(-2)
    if (lastTwoDigits === '00') {
      return offer.fixedDiscount.slice(0, -3)
    }
    return offer.fixedDiscount
  }
  return ''
}
