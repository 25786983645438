import { Elements } from '@stripe/react-stripe-js'
import { PaymentRequest, Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import PaymentIntentScreen from '../../../../screens/StripeCustomCheckout/PaymentIntentScreen'
import Analytics from '../../../../utils/Analytics'
import Environment from '../../../../utils/Environment'
import radialBg from '../../assets/dark-radial-bg.png'
import icon_bell from '../../assets/icon_bell_ring@3x.png'
import icon_guarantee from '../../assets/icon_guarantee@3x.png'
import icon_phone from '../../assets/icon_phone@3x.png'
import logo from '../../assets/logo@3x.png'
import GradientTrialBox from '../../components/GradientTrialBox'
import ListItem from '../../components/ListItem'
import {
  createCheckoutDetails,
  createPaymentRequest,
  createStripeInstance,
} from '../../setupStripeForEmailConfirmationPaywall'
import { SupportedCountryCode, countryCodeToCurrency, formatCurrency } from '../../../../utils/currency'
import { reportRepeatCheckout } from '../../../../utils/dynamicCodes/redirects'
import { ChooseYourOwnPriceProduct, analyticsChosenPrice } from '../../chooseYourOwnPrices'

export const stripeStyle = {
  colorPrimary: '#0570de',
  colorBackground: '#30313d',
  colorText: 'white',
  colorDanger: '#df1b41',
  borderRadius: '4px',
  colorPrimaryText: 'white',
  fontSizeSm: '1rem',
}

type ChooseYourPriceSelectorViewProps = {
  locale: SupportedCountryCode
  emailAddress: string
  product: ChooseYourOwnPriceProduct
}
const ChooseYourPriceCheckoutView = ({ product, locale, emailAddress }: ChooseYourPriceSelectorViewProps) => {
  const { t } = useTranslation()
  useDocumentTitle('Rise')
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null)
  const history = useHistory()
  const [options, setOptions] = useState<StripeElementsOptions>({})
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined)
  const [paymentRequestLoading, setPaymentRequestLoading] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [duplicateCustomer, setDuplicateCustomer] = useState<boolean>(false)
  const [chargeDate, setChargeDate] = useState<string>('')

  useEffect(() => {
    Analytics.track('Email Confirmation - Choose Price Checkout Viewed', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
      locale,
      chosen_price: analyticsChosenPrice(product.productCode),
    })
  }, [emailAddress, locale, product.productCode])

  // Setup Client Secret and Stripe Instance
  useEffect(() => {
    const setupStripe = async () => {
      if (emailAddress && !stripeInstance) {
        const checkoutDetails = await createCheckoutDetails(emailAddress)
        if ('error' in checkoutDetails) {
          if (checkoutDetails.duplicateCustomer) {
            setDuplicateCustomer(true)
          } else {
            Analytics.track('Unable to set up Stripe Custom Checkout Page', {
              env: Environment.getVar('REACT_APP_ENV') ?? 'test',
              email: emailAddress,
            })
          }
        } else {
          const stripe = await createStripeInstance()
          if (stripe) {
            setStripeInstance(stripe)
          }
          setClientSecret(checkoutDetails.client_secret)
          setChargeDate(checkoutDetails.charge_date)
          setOptions(checkoutDetails.options)
        }
      }
    }
    if (emailAddress) {
      setupStripe()
    }
  }, [stripeInstance, emailAddress])

  // Fire analytics for user who has a duplicate customer
  useEffect(() => {
    if (duplicateCustomer) {
      reportRepeatCheckout(product.code, emailAddress)
    }
  }, [duplicateCustomer, product.code, emailAddress])

  // Create Payment Request - this will be recreated when the user changes the trial option
  useEffect(() => {
    const introPriceInSubUnits = Math.round(product.introPrice * 100)
    if (stripeInstance) {
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: product.sheetLabel,
            amount: introPriceInSubUnits,
          },
        })
      } else {
        createPaymentRequest(stripeInstance, product.code, introPriceInSubUnits, product.sheetLabel, locale).then(
          (paymentRequest) => {
            setPaymentRequest(paymentRequest)
            setPaymentRequestLoading(false)
          }
        )
      }
    } else {
      console.log('Unable to setup payment request, stripeInstance is null')
    }
  }, [locale, paymentRequest, product.code, product.introPrice, product.sheetLabel, stripeInstance])

  const handlePurchaseSuccess = ({ existingAccount }: { existingAccount: boolean }) => {
    Analytics.track('Email Confirmation Purchase Success', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
      product,
    })
    history.push(
      `/email-confirmation-choose-your-price-trial?token=${emailAddress}&days=${
        product.daysFree
      }&price=${formatCurrency({
        value: product.price,
        countryCode: locale,
        currency: countryCodeToCurrency(locale),
        minimumFractionDigits: 2,
      })}`
    )
  }
  const refToScroll = useRef<HTMLDivElement>(null)

  const onCreditCardChoice = () => {
    refToScroll.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        {/* Prevents zooming into the text input when entering a credit card number */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className={`w-full h-screen mx-auto`}>
        <div
          className="flex flex-col relative h-full"
          style={{
            backgroundImage: `url(${radialBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="flex flex-col flex-1 px-5 items-center justify-top pt-5">
            <img src={logo} style={{ width: 60 }} alt="Rise" />
            <div className="text-white pt-2 pb-5 text-3xl leading-tight text-center">
              {t('choose_your_price_checkout_screen.title')}
            </div>
            <GradientTrialBox locale={locale} price={product.price} introPrice={product.introPrice} />
            <div className="pt-4">
              <ListItem
                Icon={<img src={icon_bell} alt="bell" />}
                text={t('choose_your_price_checkout_screen.item_1')}
              />
              <ListItem
                Icon={<img src={icon_phone} alt="phone" />}
                text={t('choose_your_price_checkout_screen.item_2')}
              />
              <ListItem
                Icon={<img src={icon_guarantee} alt="guarantee" />}
                text={t('choose_your_price_checkout_screen.item_3')}
              />

              <div className="pt-2">
                {duplicateCustomer ? (
                  <div className={'p-5 flex align-middle justify-center  text-white text-lg'}>
                    {t('checkout_screen.your_subscription_is_active')}
                  </div>
                ) : Object.keys(options).length && stripeInstance ? (
                  <div>
                    <Elements stripe={stripeInstance} options={options}>
                      <PaymentIntentScreen
                        code={product.code}
                        paymentRequest={paymentRequest}
                        clientSecret={clientSecret}
                        daysFree={product.daysFree}
                        chargeDate={chargeDate}
                        dynamicCheckout={true}
                        onPurchaseSuccess={handlePurchaseSuccess}
                        paymentRequestLoading={paymentRequestLoading}
                        onCreditCardChoice={onCreditCardChoice}
                        ctaText={t('common.CONTINUE')}
                      />
                    </Elements>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseYourPriceCheckoutView
