import React, { ChangeEvent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { SelfReportedFreedayView } from "./SelfReportedFreeDayView";
import { useStore } from "../../../state/webOnboarding2";
import { inBedOptions, wakeUpOptions, screenConfigs } from "../../../utils/webOnboarding2/constants";
import { withRedirectAndTitle } from "../withRedirectAndTitle";


// TODO: is freeday the best word? freeDay? weekEnd? weekend?
function SelfReportedFreeday(): ReactElement {
    const history = useHistory();

    const {
        error,
        setError,
        freedayWakeUp,
        setFreedayWakeUp,
        freedayInBed,
        setFreedayInBed,
    } = useStore(state => ({
        error: state.error,
        setError: state.setError,
        freedayWakeUp: state.freedayWakeUp,
        setFreedayWakeUp: state.setFreedayWakeUp,
        freedayInBed: state.freedayInBed,
        setFreedayInBed: state.setFreedayInBed,
    }))

    function progressToConfirmationIfTimesSelected() {
        setError(false);
        if (nothingSelected()) {
            setError(true)
        } else {
            history.push(screenConfigs.selfReportedConfirmation.url)
        }
    }

    function nothingSelected() {
        const wakeUp = useStore.getState().freedayWakeUp
        const inBed = useStore.getState().freedayInBed
        return wakeUp === null || inBed === null
    }

    const setSleepTimeFromRadio = (e: ChangeEvent<HTMLInputElement>, setTime: (sleepTime: number) => void) => {
        const sleepTime = parseInt(e.target.value)
        setTime(sleepTime)
    }

    return (
        <SelfReportedFreedayView
            inBedOptions={Array.from(inBedOptions)}
            wakeUpOptions={Array.from(wakeUpOptions)}
            onChangeWakeUp={(e) => setSleepTimeFromRadio(e, setFreedayWakeUp)}
            onChangeInBed={(e) => setSleepTimeFromRadio(e, setFreedayInBed)}
            error={error}
            wakeUpSelected={freedayWakeUp}
            inBedSelected={freedayInBed}
            onClick={()=> progressToConfirmationIfTimesSelected()}
        />
    );
}

export default withRedirectAndTitle(SelfReportedFreeday, screenConfigs.selfReportedFreeday.documentTitle);
