import React from 'react'
import { RiseRadioButton } from './RiseRadioButton'
import './SubscriptionSelectionCell.css'

export interface CellProps {
  onPress: () => void
  subtitle?: string | JSX.Element
  selected: boolean
  title?: string | React.ReactNode | React.ReactNode[]
}

function SubscriptionSelectionCell({
  onPress,
  selected,
  subtitle,
  title,
  ...rest
}: CellProps) {
  return (
    <div className={'cell--container'}>
      <button onClick={onPress} className={'cell--bodyContainer'}>
        <div>
          {title ? <div className={'cell--title'}>{title}</div> : null}
          {subtitle ? <div className={'cell--subtitle'}>{subtitle}</div> : null}
        </div>
        <RiseRadioButton checked={selected} onChange={onPress} />
      </button>
    </div>
  )
}

export default React.memo(SubscriptionSelectionCell)
