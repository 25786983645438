import ReactPixel from 'react-facebook-pixel';

// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };

// See comment in sendMarketingEvent as to why we need to drop to window.fbq
declare global {
    interface Window { fbq: any; }
}

export interface ConversionAPIUserData {
    em?: string;
    external_id?: string | undefined;
    client_ip_address?: string;
    fbc?: string | undefined;
    fbp: string | undefined;
}

export interface ConversionAPIEvent {
  event_name: string;
  event_id: string;
  event_time: number;
  user_data: ConversionAPIUserData;
  action_source: string;
  event_source_url?: string;
}

const FB_STANDARD_EVENTS = {
    COMPLETE_REGISTRATION: 'CompleteRegistration',
    INITIATE_CHECKOUT: 'InitiateCheckout',
    START_TRIAL: 'StartTrial'
}

ReactPixel.init('689199994939883'); // TODO: figure out if we need to pass advancedMatching and/or options

function sendMarketingEvent(eventName: string, data: {}, eventData: {}) {
  // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  //ReactPixel.track(eventName, data);

  // Ideally here we could call ReactPixel.track, which is a wrapper on FB's llightweight library. The problem is that we need to pass an eventID as 4th parameter called eventDate. This is needed to deduplicate between pixel and Conversion API events. ReactPixel doesn't yet support passing that 4th param, and they have an open issue for it. Sooo. we just do it the dirty way by dropping straight into window.fbq

  // TEMPORARY DISABLED (2022-05-11) as Leon is working with Firstparty and these events create more noise to troubleshoot
  //window.fbq('track', eventName, data, eventData); // eslint-disable-line no-undef
  //console.log('Sent Marketing Event')
}

function sendConversionAPIEvent(eventName: string, eventID: string, userData: ConversionAPIUserData){
    // const currentUnixTimestamp = Math.floor(Date.now() / 1000)
    // const payload: ConversionAPIEvent = {
    //     event_name: eventName,
    //     event_time: currentUnixTimestamp,
    //     user_data: userData,
    //     event_id: eventID,
    //     action_source: 'website',
    //     //event_source_url?: string;
    // }
    // TEMPORARY DISABLED (2022-05-11) as Leon is working with Firstparty and these events create more noise to troubleshoot
    // console.log("Will call api/marketing-events with", payload);
    // axios.post('https://api.risesci.com/api/marketing-events', payload)
    //   .then((res) => {
    //     if (res.status !== 200) {
    //       throw new Error(`Status: ${res.status} ${res.statusText}`);
    //     }
    //     return res.data;
    //   })
    //   .then((d) => {
    //       console.log(d)
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   });
}



const MarketingEvents = {
  sendMarketingEvent,
  FB_STANDARD_EVENTS,
  sendConversionAPIEvent
};
export default MarketingEvents;
