import React from "react";

const NeedHelpLink = () => {
  return (
    <div className={`flex flex-row`}>
      <text className={`text-sm text-white pr-2`}>Need help?</text>
      <text className={`text-sm text-white pr-2`}> Email:</text>
      <a
        className={`text-sm text-purple-2 font-bold`}
        href="mailto:support@risescience.com"
      >
        support@risescience.com
      </a>
    </div>
  );
};

export default NeedHelpLink;
