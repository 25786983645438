import React, { ReactElement } from 'react';
import {Oval} from "react-loader-spinner";

interface Props {
    uiState: string;
}

function LoadingSpinner({uiState}: Props): ReactElement  {
    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            visibility: uiState === 'loading' ? 'visible' : 'hidden'
        }}>
            <Oval
                ariaLabel="loading-indicator"
                height={120}
                width={120}
                strokeWidth={3}
                color="rgba(114, 28, 255, var(--tw-bg-opacity))"
                secondaryColor="white" />
        </div>
    );
}

export default LoadingSpinner;
