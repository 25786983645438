import React, {ReactElement, useEffect, useState} from 'react';
import Analytics from '../../utils/Analytics';
import MaybeStagingBadge from "../../components/MaybeStagingBadge";
import logo from '../../images/logo.svg';
import profile from "../../images/profile.svg";
import riseAppLockup from "../SignUpSuccess/riseAppLockup.png";
import appStore from "../SignUpSuccess/appStore.png";
import playStore from "../SignUpSuccess/playStore.png";
import {BASE_URL, useCode, useEmail, useQueryToken} from "../../utils/dynamicCodes/hooks";
import Environment from "../../utils/Environment";
import {loadStripe} from "@stripe/stripe-js";
import axios from "axios";


// this is not being used and should be deleted
function StripeCustomSuccess(): ReactElement {
    const token = useQueryToken();
    const email = useEmail();
    const code = useCode();
    const [stripeError, setStripeError] = useState<string>('');

    const createSubscriptionFromSetupIntent = async () => {
        // Once Stripe redirects after checking the Setup Intent, we attempt to use it to setup a Stripe
        // Customer and Subscription in Hypnos
        const key = Environment.getVar('REACT_APP_STRIPE_KEY');
        if (!key) {
            throw new Error('Stripe key must be defined');
        }
        // TODO: set back to production!!
        const stripe = await loadStripe(key);

        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
        );

        if (stripe && clientSecret) {
            const {setupIntent} = await stripe.retrieveSetupIntent(clientSecret);

            if (setupIntent) {
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                if (['succeeded', 'processing'].includes(setupIntent.status)) {
                    // TODO: we may want to await this for analytics
                    axios.post(`${BASE_URL}/api/stripe-complete-checkout`, {
                        token,
                        setup_intent_id: setupIntent.id,
                        email,
                        code,
                        env: 'test',
                    })
                }
                if (setupIntent.status === 'requires_payment_method') {
                    // This isn't a great state to be in - if this occurs often, we may need to redirect the user back
                    // to our payment screen
                    // TODO: analytics here
                    setStripeError('Failed to process payment details. Please try another payment method.')
                }
            }
        }
    }

    useEffect(() => {
        createSubscriptionFromSetupIntent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="w-full h-screen mx-auto" id="new-years-2021">
            <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
                <MaybeStagingBadge />
                <div className={'py-6 md:py-12'}>
                    <img
                        src={logo}
                        alt="RISE"
                        className="self-start"
                        style={{ height: 16 }}
                    />
                </div>
                <div
                    className={`w-px-320 md:w-px-600 ${
                        window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
                    } sm:space-y-0 sm:pt-20`}
                >
                    <div className="pt-8 flex flex-col">
                        {stripeError ? <text id='message' className="text-white">{stripeError}</text> : null}
                        <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                            You're all set!
                        </h1>
                        <div className="flex justify-end my-2  pb-2">
                            <hr className="w-full border-t border-white opacity-25" />
                        </div>
                        {token ? (
                            <div
                                className={`opacity-0 text-white text-lg font-medium flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
                                    token && 'opacity-100'
                                }`}
                            >
                                <img src={profile} alt="" className="inline mr-2 w-5" />
                                {token ?? '...'}
                            </div>
                        ) : null}
                        <p className="text-white text-sm">
                            Now, just download the RISE app and use this email or phone number to log in and
                            start your <span className={'whitespace-nowrap'}>7</span>-days free.
                        </p>
                        <div className="flex justify-end my-8 pb-2 ">
                            <hr className="w-full border-t border-white opacity-25" />
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        src={riseAppLockup}
                        alt="RISE: Sleep & Energy Tracker - Better Health, Energy & Focus"
                        className="self-start mb-6"
                        style={{ height: 83 }}
                    />

                    <div className="flex">
                        <a
                            href="https://apps.apple.com/us/app/rise-sleep-energy-tracker/id1453884781"
                            onClick={() =>  Analytics.track('Store Redirect Clicked', {store: 'appStore'})}
                            className="block mr-4"
                        >
                            <img
                                src={appStore}
                                alt="Download on the App Store"
                                className="self-start"
                                style={{ height: 50 }}
                            />
                        </a>

                        <a
                            href="https://play.google.com/store/apps/details?id=com.risesci.nyx"
                            onClick={() =>  Analytics.track('Store Redirect Clicked', {store: 'playStore'})}
                            className="block"
                        >
                            <img
                                src={playStore}
                                alt="Get it on Google Play"
                                className="self-start"
                                style={{ height: 50 }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StripeCustomSuccess;
