import React, { useState } from "react";
import logo from "../../../images/logo.svg";
import ActionButton from "../components/ActionButton";
import InfoModal from "../components/InfoModal";
import Title from "../components/Title";
import SleepNeedChart from "./SleepNeedChart";


export function SleepNeedView(props: {
  sleepNeed: string;
  onClick: () => void;
  sleepNeedDecimal: number;
}) {
  // NOTE: may need to move these functions to the container
  const [calculationModalOpen, setCalculationModalOpen] = useState(false);
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const [sleepNeedAccepted, setSleepNeedAccepted] = useState(false);

  return (
    <div className="mx-auto w-px-375 sm:w-px-640 relative z-10 h-full pb-16">
      {calculationModalOpen && (
        <InfoModal
          modalType={'calculation'}
          handleClose={() => setCalculationModalOpen(false)}
        />
      )}
      {learnMoreModalOpen && (
        <InfoModal
          modalType={'learnMore'}
          handleClose={() => setLearnMoreModalOpen(false)}
        />
      )}
      <div className={'pt-6 md:py-8'}>
        <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
      </div>
      <div
        className={`w-px-375 md:w-px-600 h-full ${
          window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
        } sm:space-y-0 sm:pt-20`}
      >
        <div className="pt-8 flex flex-col h-full justify-between ">
          <div>
            <Title title={`Your sleep need`} />
            <div className={`px-3 py-8 text-center`} style={{minHeight:120}}>
              {!sleepNeedAccepted &&
                <text className={`text-white text-lg text-center`}>
                  Based on your sleep patterns, we've learned your <span className="font-semibold">sleep need</span>
                </text>
              }
            </div>
            <div className="pb-8 flex flex-col px-3">
              <text className={`text-white text-xl font-bold text-center`}>
                {props.sleepNeed}
              </text>
            </div>
            <div
              className={'px-8'}
            >
             <SleepNeedChart width={"100%"} sleepNeed={props.sleepNeedDecimal} />
            </div>
            <div className={`flex flex-col pt-2 text-center px-3`}>
              <text className={`text-white text-sm`}>
                Adults typically need between 7 and 9 hours of sleep each night.
              </text>

              <text
                onClick={() => setCalculationModalOpen(true)}
                className={`py-2 text-purple-2 text-sm font-bold`}
              >
                How do you calculate it?
              </text>
            </div>
          </div>
          {sleepNeedAccepted && (
            <div className={`px-10 py-3 text-center`}>
              <text className={`text-white text-lg text-center`}>
                When you sleep less than your sleep need, you build up
                <br />
                <b>sleep debt.</b>
              </text>
            </div>
          )}
          <div>
            {!sleepNeedAccepted ? (
              <div>
                <div className={'w-full pt-10 pb-6 px-3'}>
                  <ActionButton
                    title="NEXT"
                    onClick={() => setSleepNeedAccepted(true)}
                  />
                </div>
                <div className={'flex flex-col px-4 pt-2 text-center self-end'}>
                  <text className={`text-white text-sm font-medium`}>
                    Feel inaccurate?
                  </text>
                  <text className={`text-gray-4 text-sm`}>
                    Your insights will become more accurate as you use RISE.
                  </text>
                  <text
                    onClick={() => setLearnMoreModalOpen(true)}
                    className={`pt-2 text-purple-2 text-sm font-semibold`}
                  >
                    Learn more
                  </text>
                </div>
              </div>
            ) : (
              <div className={`w-full px-3 pb-12`}>
                <ActionButton
                  title={`WHAT IS MY SLEEP DEBT`}
                  onClick={props.onClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
