import * as Sentry from '@sentry/react'
import { PaymentRequest, Stripe, loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import Analytics from '../../utils/Analytics'
import Environment from '../../utils/Environment'
import { BASE_URL } from '../../utils/dynamicCodes/hooks'
import { UserAlreadySubscribedMessage } from '../../utils/dynamicCodes/redirects'

import { stripeStyle } from './screens/EmailConfirmationCheckoutScreen'

import { SupportedCountryCode } from '../../utils/currency'

type CreateClientSecretResult = {
  client_secret: string
  person_id: string
  charge_date: string
  options: Record<string, any>
}
type CreateClientSecretErrorResult = {
  duplicateCustomer: boolean
  error: boolean
}
export const createCheckoutDetails = async (
  token: string
): Promise<CreateClientSecretResult | CreateClientSecretErrorResult> => {
  try {
    const response = await axios.post(`${BASE_URL}/api/stripe-setup-checkout`, {
      token,
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      winback: false,
    })
    const { client_secret, person_id, charge_date } = response.data
    return {
      client_secret,
      person_id,
      charge_date,
      options: {
        clientSecret: client_secret,
        appearance: {
          variables: {
            ...stripeStyle,
          },
        },
      },
    }
  } catch (error: any) {
    if (error.response?.data === UserAlreadySubscribedMessage) {
      return { duplicateCustomer: true, error: false }
    } else {
      Sentry.captureException(new Error(error))

      return { duplicateCustomer: false, error: true }
    }
  }
}

export const createStripeInstance = async (): Promise<Stripe | null | undefined> => {
  try {
    const key = Environment.getVar('REACT_APP_STRIPE_KEY')
    if (!key) {
      throw new Error('Stripe key must be defined')
    }
    return await loadStripe(key)
  } catch (error: any) {
    Sentry.captureException(new Error(error))
  }
}

export const createPaymentRequest = async (
  stripeInstance: Stripe,
  code: string,
  amount: number, //What will appear in the sheet, in USD cents
  label: string,
  locale: SupportedCountryCode
): Promise<PaymentRequest | undefined> => {
  const localeCurrencyMapping = {
    US: 'usd',
    GB: 'gbp',
    CA: 'cad',
    AU: 'aud',
    DE: 'eur',
  }
  const paymentRequest = stripeInstance.paymentRequest({
    country: 'US', // country code of merchant
    currency: localeCurrencyMapping[locale],
    total: { label, amount },
    requestPayerEmail: true,
    requestPayerName: true,
  })
  const canUseAppleOrGooglePay = await paymentRequest.canMakePayment()
  if (canUseAppleOrGooglePay) {
    const { applePay, googlePay } = canUseAppleOrGooglePay
    if (applePay) {
      Analytics.track('Apple Pay Shown', { code })
    }
    if (googlePay) {
      Analytics.track('Google Pay Shown', { code })
    }
    return paymentRequest
  } else {
    Analytics.track('Credit Card Shown', { code })
  }
}
