import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Analytics from '../../utils/Analytics'
import { Footer } from './Footer'
import { Nav } from './Nav'

import './Layout.css'

type LayoutProps = {
  /** Determines if the user should start at the beginning or end of the slides  */
  isComplete: boolean
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children, isComplete } = props
  const maxSlides = React.Children.count(children) - 1

  const history = useHistory()
  const { pathname, search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const [currentSlide, setCurrentSlide] = useState(isComplete ? maxSlides : 0)

  // Scroll the user back to the top as they paginate through slides
  useEffect(() => window.scrollTo(0, 0), [currentSlide])

  useEffect(() => {
    // If we're on the last slide add a query param. This will allow us to place the
    // user on the final slide when they navigate back using the browsers back button
    if (currentSlide === maxSlides && !searchParams.has('complete')) {
      searchParams.append('complete', '1')
      history.push({ pathname, search: searchParams.toString() })
    }
  })

  const navigateToPaywall = () => {
    Analytics.track('Intro To Rise - Rise Checkout Clicked', {})
    history.push(`/offer/intro-to-rise`, { from: 'onboarding' })
  }

  const removeCompleteSearchParam = () => {
    if (searchParams.has('complete')) {
      searchParams.delete('complete')
      history.push({ pathname, search: searchParams.toString() })
    }
  }

  const moveBackward = () => {
    setCurrentSlide((v) => Math.max(v - 1, 0))
    removeCompleteSearchParam()
  }

  const moveForward = () => {
    if (currentSlide === maxSlides) {
      navigateToPaywall()
    } else {
      setCurrentSlide((v) => Math.min(v + 1, maxSlides))
      removeCompleteSearchParam()
    }
  }

  return (
    <div>
      <Nav
        fixed
        onSignUp={navigateToPaywall}
        progressBar={{
          maxValue: maxSlides,
          value: currentSlide,
        }}
      />
      <main className="ob3__layout__main">
        {React.Children.toArray(children)[currentSlide]}
      </main>
      <Footer
        canMoveBackward={currentSlide > 0}
        onMoveBackClick={moveBackward}
        onMoveForwardClick={moveForward}
      />
    </div>
  )
}
