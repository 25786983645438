import React from 'react'

import './ProgressBar.css'

export type ProgressBarProps = {
  /** The number of slides being presented */
  maxValue: number
  /** Index representing the currently presented slide */
  value: number
}

/**
 * Presents a progress bar that will track a users progress through a series
 * of items
 */
export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { maxValue, value } = props
  const position = ((value + 1) / (maxValue + 1)) * 100

  return (
    <div className="progress-bar">
      <div className="progress-bar__fill" style={{ width: `${position}%` }} />
    </div>
  )
}
