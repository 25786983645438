import React from 'react'

import phone from '../assets/phones/track-sleep-debt-phone.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const TrackSleepDebt: React.FC = () => {
  useSlideTrackEvt('TrackSleepDebt')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline secondary>Using RISE</BrandHeadline>
        <LargeHeadline>
          Track your Sleep Debt to feel more energized.
        </LargeHeadline>
        <BodyCopy>
          RISE delivers results by helping you build sustainable habits to
          manage your Sleep Debt. 4 of 5 users feel the benefits of lower Sleep
          Debt within 5 days.
        </BodyCopy>
      </div>
      <div>
        <GradientPhone>
          <img alt="Sleep debt on RISE app" src={phone} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
