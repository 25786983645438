import { DynamicCodeProps } from '../../utils/dynamicCodes/types'
import { loadStripe } from '@stripe/stripe-js'
import {
  BASE_URL,
  useOffer,
  useQueryToken,
} from '../../utils/dynamicCodes/hooks'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PaymentRequest } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentIntentScreen from './PaymentIntentScreen'
import CheckoutInformation from './CheckoutInformation'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import Environment from '../../utils/Environment'
import Analytics from '../../utils/Analytics'
import * as Sentry from '@sentry/react'
import {
  UserAlreadySubscribedMessage,
  bypassCheckoutForDuplicateCustomer,
} from '../../utils/dynamicCodes/redirects'
import stripeLogo from '../../images/stripe.png'

const key = Environment.getVar('REACT_APP_STRIPE_KEY')
const stripePromise = loadStripe(key)

export const stripeStyle = {
  colorPrimary: '#0570de',
  colorBackground: '#30313d',
  colorText: 'white',
  colorDanger: '#df1b41',
  borderRadius: '4px',
  colorPrimaryText: 'white',
  fontSizeSm: '1rem',
}

const StripeElementProvider = ({ match }: DynamicCodeProps) => {
  const [options, setOptions] = useState<object>({})
  const [paymentRequest, setPaymentRequest] = useState<
    PaymentRequest | undefined
  >(undefined)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [subscriptionError, setSubscriptionError] = useState<boolean>(false)
  const [chargeDate, setChargeDate] = useState<string>('')

  const token = useQueryToken()
  const code = match.params.name

  const offer = useOffer(code)

  useDocumentTitle('Rise Thanks You')

  useEffect(() => {
    if (offer) {
      setupStripe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer])

  const isWinback = () => {
    const params = new URLSearchParams(window.location.search)
    return Boolean(params.get('winback'))
  }

  const setupStripe = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/stripe-setup-checkout`,
        {
          token,
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          winback: isWinback(),
        }
      )
      const { client_secret, person_id, charge_date } = response.data

      if (person_id) {
        Analytics.identify(person_id)
      }

      if (charge_date) {
        setChargeDate(charge_date)
      }

      Analytics.track('Custom Stripe Checkout Page Shown', { code })
      setClientSecret(client_secret)

      const key = Environment.getVar('REACT_APP_STRIPE_KEY')
      if (!key) {
        throw new Error('Stripe key must be defined')
      }
      const stripe = await loadStripe(key)

      if (stripe && offer) {
        const labelText = !isWinback()
          ? `Rise Science - FREE for ${offer.daysFree} days, then ${offer.price} after trial`
          : `Rise Science - Only charged when your current subscription expires, then ${offer.price}`
        const paymentRequest = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: labelText,
            amount: 0,
          },
          requestPayerEmail: true, // TODO: think about doing this dynamically??
          requestPayerName: true,
        })
        const canUseAppleOrGooglePay = await paymentRequest.canMakePayment()
        console.log(canUseAppleOrGooglePay)
        if (canUseAppleOrGooglePay) {
          const { applePay, googlePay } = canUseAppleOrGooglePay
          if (applePay) {
            Analytics.track('Apple Pay Shown', { code })
          }
          if (googlePay) {
            Analytics.track('Google Pay Shown', { code })
          }
          // @ts-ignore
          setPaymentRequest(paymentRequest)
        } else {
          Analytics.track('Credit Card Shown', { code })
        }
      }

      const options = {
        clientSecret: client_secret,
        appearance: {
          variables: {
            ...stripeStyle,
          },
        },
      }
      setOptions(options)
    } catch (error) {
      if (error.response?.data === UserAlreadySubscribedMessage) {
        setSubscriptionError(true)
        bypassCheckoutForDuplicateCustomer(code, token)
      } else {
        Sentry.captureException(new Error(error))
        Analytics.track('Unable to set up Stripe Custom Checkout Page', {
          code,
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          email: token,
        })
      }
    }
  }

  return (
    <div
      className={`w-full h-screen mx-auto ${
        window.innerWidth < 640 ? 'bgHeroPhone' : ''
      }`}
    >
      <div
        className={`mx-auto w-px-320 sm:w-px-640  relative z-10 ${
          window.innerWidth > 640 ? 'bgHeroPhone' : ''
        }`}
      >
        <MaybeStagingBadge />
        <div
          style={{ minHeight: window.innerHeight, maxWidth: '350px' }}
          className={`h-screen w-full ${
            window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
          } sm:space-y-0 sm:pt-410`}
        >
          <div className="pt-2 flex flex-col">
            <CheckoutInformation
              token={token}
              offer={offer}
              chargeDate={chargeDate}
            />

            {subscriptionError ? (
              <div className={'text-white'}>Your subscription is active!</div>
            ) : Object.keys(options).length ? (
              <Elements stripe={stripePromise} options={options}>
                <PaymentIntentScreen
                  code={code}
                  paymentRequest={paymentRequest}
                  clientSecret={clientSecret}
                  daysFree={offer?.daysFree}
                  chargeDate={chargeDate}
                />
                <img
                  src={stripeLogo}
                  style={{
                    width: 150,
                    height: 34,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2.5rem',
                  }}
                  alt="Powered by Stripe"
                />
              </Elements>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StripeElementProvider
