import React, { ReactElement } from "react";

import { useStore } from "../../../state/webOnboarding2";
import { withRedirectAndTitle } from "../withRedirectAndTitle";
import { screenConfigs } from "../../../utils/webOnboarding2/constants";
import { PaywallView } from "./PaywallView";
import { UserConfiguration } from "../../../utils/fetchUserConfiguration";
import { redirectToStripe } from "../../../utils/redirectToStripe";
import { sendAnalytics } from "../../../utils/webOnboarding2/webOnboardingAnalytics";


function Paywall(): ReactElement {

    const {
        getStripeConfiguration,
    } =  useStore(state => ({
        getStripeConfiguration: state.getStripeConfiguration,
    }))


    async function redirectToStripeCheckout() {
        await getStripeConfiguration();
        let userConfig: UserConfiguration = useStore.getState()
            .userConfig as UserConfiguration;

        try {
            await redirectToStripe(userConfig);
            sendAnalytics('Stripe Paywall Viewed');
        } catch (e) {
            // TODO Handle error better
        }
    }

    return <PaywallView handleClick={redirectToStripeCheckout} />;
};

export default withRedirectAndTitle(
    Paywall,
    screenConfigs.paywall.documentTitle
);
