import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ActionButton from '../../../screens/WebOnboarding2/components/ActionButton'
import { useQueryToken, useValueParams } from '../../../utils/dynamicCodes/hooks'
import { Slide0, Slide1, Slide2 } from '../components/FeatureCardSlides'
import './EmailConfirmation.css'
import Analytics from '../../../utils/Analytics'
import Environment from '../../../utils/Environment'
import { screenConfigs as emailConfirmationConfig } from '../constants'
import { useTranslation } from 'react-i18next'
import { growthBook } from '../../..'

const slideClassName = (currentSlide: number, index: number) => {
  if (currentSlide === index) return 'active'
  if (currentSlide > index) return 'prev'
  if (currentSlide < index) return 'next'
}

/** The primary entrypoint for the Email Confirmation flow */
function EmailConfirmationFeatureCardScreen() {
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)
  const token = useQueryToken()
  const history = useHistory()
  const person_id_string = useValueParams('person')
  const personId = person_id_string ? parseInt(person_id_string) : null //personId should be an integer for GB
  const localeParam: string = useValueParams('locale') ?? 'US'
  const langParam = useValueParams('lang')
  const isStudent = (useValueParams('student') ?? 'no') === 'yes'

  // We need to set the GB personId before we retrieve the feature value
  if (personId && !growthBook.getAttributes().personId) {
    growthBook.setAttributes({ personId })
  }

  const isEnglish = !langParam || langParam === 'en'

  const checkoutUrl = (() => {
    // Immediately Invoked Function Expression to encapsulate the logic and avoid nested ternaries

    //If not student, fire GB experiment, and proceed to Choose Price,
    if (!isStudent && growthBook.getFeatureValue('web_choose_price_paywall', false)) {
      return emailConfirmationConfig.chooseYourPriceCheckout.url
    }

    if (isEnglish) {
      if (isStudent) {
        return emailConfirmationConfig.emailConfirmationCheckoutStudentPrices.url
      } else {
        return emailConfirmationConfig.emailConfirmationCheckout.url
      }
    } else {
      if (isStudent) {
        return emailConfirmationConfig.designTrialStudentCheckout.url
      } else {
        return emailConfirmationConfig.designTrialCheckout.url
      }
    }
  })()

  const handleContinue = () => {
    switch (currentSlide) {
      case 0:
        setCurrentSlide(1)
        break
      case 1:
        setCurrentSlide(2)
        break
      case 2:
        history.push(`${checkoutUrl}?token=${token}&locale=${localeParam}`)
        break
    }
  }
  useEffect(() => {
    if (personId) {
      Analytics.identify(personId.toString())
    }
  }, [personId])

  useEffect(() => {
    Analytics.track('Email Confirmation Feature Card Screen', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
    })
  }, [token])

  useEffect(() => {
    switch (currentSlide) {
      case 0:
        Analytics.track('Email Confirmation - Email Confirmation Slide', {
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          email: token,
        })
        break
      case 1:
        Analytics.track('Email Confirmation - Change Biology Slide', {
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          email: token,
        })
        break
      case 2:
        Analytics.track('Email Confirmation - Optimize Today Slide', {
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          email: token,
        })
        break
    }
  }, [currentSlide, token])

  return (
    <div className="w-screen overflow-hidden flex flex-col justify-between">
      <div className="content-container">
        <div id="slide-0" className={`slideContainer h-full ${slideClassName(currentSlide, 0)}`}>
          <Slide0 emailAddress={token} />
        </div>
        <div id="slide-1" className={`slideContainer h-full ${slideClassName(currentSlide, 1)}`}>
          <Slide1 />
        </div>
        <div id="slide-2" className={`slideContainer  h-full  ${slideClassName(currentSlide, 2)}`}>
          <Slide2 />
        </div>
      </div>
      <div>
        <div className="p-2">
          <ActionButton
            title={currentSlide === 0 ? t('common.CONTINUE_TO_RISE') : t('common.CONTINUE')}
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  )
}

export default EmailConfirmationFeatureCardScreen
