import React from "react";
import StripeConfirmationBackground from "../images/StripeConfirmationBackground.svg";

import ActionButton from "../components/ActionButton";
import Checkmark from "../../../images/checkmark.svg"
import Bell from "../../../images/Bell.svg"
import FingerTap from "../../../images/Fingertap.svg"


export function PaywallView(props: {
    handleClick: () => void;
}) {
    return (
        <div className="mx-auto w-px-375 sm:w-px-640 relative z-10">
            <div
                className={`w-px-375 md:w-px-640 w-full h-full pb-6 ${
                    window.innerHeight < 800 ? "space-y-20" : "space-y-40"
                } sm:space-y-0 sm:pt-20`}
                style={{
                    backgroundImage: `url(${StripeConfirmationBackground})`,
                    backgroundSize: `cover`,
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="pt-32 flex flex-col">
                    <text className="text-white pt-8 pb-0 m-0 text-3xl text-left px-4 font-semibold">
                        RISE
                    </text>
                    <text className="text-white p-0 m-0 text-2xl text-left px-4">
                        Try 7 days free.
                    </text>

                    <div className="text-white pt-5 text-left px-4">
                        <div className="grid grid-cols-12 gap-1">
                            <img src={Checkmark} alt="" className="mr-2 w-10" />
                            <text className="col-span-9 text-lg">Predict daily energy levels</text>
                        </div>
                        <div className="grid grid-cols-12 gap-1 pt-2">
                            <img src={Checkmark} alt="" className="mr-2 w-10" />
                            <text className="col-span-9 text-lg">Optimize habits & routines</text>
                        </div>
                        <div className="grid grid-cols-12 gap-1 pt-2">
                            <img src={Checkmark} alt="" className="mr-2 w-10" />
                            <text className="col-span-9 text-lg">Manage your sleep debt</text>
                        </div>
                        <div className="grid grid-cols-12 gap-1 pt-2">
                            <img src={Checkmark} alt="" className="mr-2 w-10" />
                            <text className="col-span-9 text-lg">Automatically track sleep</text>
                        </div>
                    </div>

                    <div className="flex justify-end my-3 pb-2 pt-5">
                        <hr className="w-full border-t border-white opacity-25" />
                    </div>

                    <div className={`px-6 pb-4`}>
                        <text className="text-white">
                            7 days free then $4.99/month billed yearly at $59.99
                        </text>
                    </div>

                    <div className="text-white pb-5 text-xs text-left px-4">
                        <div className="grid grid-cols-12 gap-1 pt-2 mx-auto">
                            <img src={Bell} alt="" className="mr-2 w-4 mx-auto" />
                            <text className="col-span-9">Get a reminder before you're charged</text>
                        </div>
                        <div className="grid grid-cols-12 gap-1 pt-2 mx-auto">
                            <img src={FingerTap} alt="" className="mr-2 w-6 mx-auto" />
                            <text className="col-span-9">Cancel anytime during the 7-day trial</text>
                        </div>
                    </div>

                    <div className={`px-3`}>
                        <div className={"w-full px-3"}>
                            <div className="pb-2">
                                <ActionButton
                                    title={"TRY FREE & SUBSCRIBE"}
                                    onClick={props.handleClick}
                                />
                            </div>
                            <div className={`text-center`}>
                                <a
                                    className={`text-xs text-center text-white underline`}
                                    href="https://www.risescience.com/terms"
                                >
                                    Terms of Service and Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
