import React, {ChangeEvent, ReactElement} from "react";
import {useStore} from "../../../state/webOnboarding2";
import {useHistory} from "react-router-dom";
import {SelfReportedWeekdayView} from "./SelfReportedWeekdayView";
import { inBedOptions, wakeUpOptions, screenConfigs } from "../../../utils/webOnboarding2/constants";
import {withRedirectAndTitle} from "../withRedirectAndTitle";


function SelfReportedWeekday(): ReactElement {
    const history = useHistory();

    const {
        error,
        setError,
        weekdayWakeUp,
        setWeekdayWakeUp,
        weekdayInBed,
        setWeekdayInBed,
    } = useStore(state => ({
        error: state.error,
        setError: state.setError,
        weekdayWakeUp: state.weekdayWakeUp,
        setWeekdayWakeUp: state.setWeekdayWakeUp,
        weekdayInBed: state.weekdayInBed,
        setWeekdayInBed: state.setWeekdayInBed,
    }))


    function progressToFreeDayTimesOrConfirmation() {
        setError(false);
        if (nothingSelected(useStore.getState().weekdayWakeUp, useStore.getState().weekdayInBed)) {
            setError(true)
            return
        }

        if (nothingSelected(useStore.getState().freedayWakeUp, useStore.getState().freedayInBed)) {
            history.push(screenConfigs.selfReportedFreeday.url)
        } else {
            // Coming from Confirmation screen -> need to go back
            history.push(screenConfigs.selfReportedConfirmation.url)
        }
    }

    function nothingSelected(wakeUp: number | null, inBed: number | null) {
        return wakeUp === null || inBed === null
    }

    const setSleepTimeFromRadio = (e: ChangeEvent<HTMLInputElement>, setTime: (sleepTime: number) => void) => {
        const sleepTime = parseInt(e.target.value)
        setTime(sleepTime)
    }

    return (
        <SelfReportedWeekdayView
            inBedOptions={Array.from(inBedOptions)}
            wakeUpOptions={Array.from(wakeUpOptions)}
            onChangeWakeUp={(e) => setSleepTimeFromRadio(e, setWeekdayWakeUp)}
            onChangeInBed={(e) => setSleepTimeFromRadio(e, setWeekdayInBed)}
            error={error}
            wakeUpSelected={weekdayWakeUp}
            inBedSelected={weekdayInBed}
            onClick={progressToFreeDayTimesOrConfirmation}
        />
    );
}

export default withRedirectAndTitle(SelfReportedWeekday, screenConfigs.selfReportedWeekday.documentTitle);
