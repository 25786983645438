import React, { useState } from 'react';
import logo from '../../../images/logo.svg';
import ActionButton from '../components/ActionButton';
import Title from '../components/Title';
import {
  SleepDebtAnimation,
  SleepDebtLoadingAnimation,
} from './components/SleepDebtAnimation/SleepDebtAnimation';
import { UiState } from './SleepDebt';

export function SleepDebtView(props: {
  sleepDebtMinutes: number;
  uiState: UiState;
  onClickViewEnergy: () => void;
}) {
  const [showButton, setShowButton] = useState(false);

  return (
    <div className="mx-auto w-px-375 sm:w-px-640  relative z-10">
      <div className={'pt-6 md:py-8'}>
        <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
      </div>
      <div className={`pt-8`}>
        <Title title={`My Sleep`} />
      </div>
      Z
      <div className={` rounded-lg px-3  sm:px-12 overflow-hidden`}>
        {props.uiState === 'finished' && (
          <SleepDebtAnimation
            sleepDebtMinutes={props.sleepDebtMinutes ?? 5*60}
            onRest={() => setShowButton(true)}
          />
        )}
        {props.uiState === 'calculating' && <SleepDebtLoadingAnimation />}
      </div>
      {props.uiState === 'finished' && (
        <div className="pt-4 flex flex-col">
          <div className="pt-4 flex flex-col  pb-8">
            <text className={`text-xl text-white px-3 text-center`}>
              Now, let's see how <b>sleep</b> defines your <b>energy</b> today.
            </text>
          </div>

          <div className={'px-3 pb-4 w-full'}>
            <div style={{ transition: '1000ms', opacity: showButton ? 1 : 0 }}>
              <ActionButton
                title="VIEW MY ENERGY"
                onClick={props.onClickViewEnergy}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
