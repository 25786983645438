import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import useDocumentTitle from '../../../../hooks/useDocumentTitle'
import '../EmailConfirmation.css'
import ChooseYourPriceSelectorView from './ChooseYourPriceSelectorView'
import ChooseYourPriceCheckoutView from './ChooseYourPriceCheckoutView'

import { SupportedCountryCode } from '../../../../utils/currency'
import { useQueryToken, useValueParams } from '../../../../utils/dynamicCodes/hooks'

import {
  ChooseYourOwnPriceProductId as ChooseYourOwnPriceProductCode,
  analyticsChosenPrice,
  get_localizedPrices_chooseYourOwn,
} from '../../chooseYourOwnPrices'
import { ChoosePriceSelectorOptions } from '../../components/ChoosePriceSelector'
import Analytics from '../../../../utils/Analytics'
import Environment from '../../../../utils/Environment'

const allowedLocale = (locale: string): SupportedCountryCode => {
  locale = locale.toUpperCase()

  if (['US', 'GB', 'AU', 'CA', 'DE', 'FR'].includes(locale)) {
    if (locale === 'FR') {
      // FR and DE share EUR formatting and prices
      return 'DE'
    }
    return locale as SupportedCountryCode
  }

  return 'US'
}

const ChooseYourPriceCheckoutScreen = () => {
  const [view, setView] = useState<'selector' | 'checkout'>('selector')
  useTranslation() // will ensure the correct translation is used
  useDocumentTitle('Rise')

  const emailAddress = useQueryToken() ?? ''
  const localeParam: string = useValueParams('locale') ?? 'US'
  const locale = allowedLocale(localeParam)
  const [selectedProductCode, setSelectedProductCode] = useState<ChooseYourOwnPriceProductCode | null>(null)

  const pricing = get_localizedPrices_chooseYourOwn()

  const options: ChoosePriceSelectorOptions = {
    '30d01_annual_70': pricing['30d01_annual_70'][locale],
    '30d05_annual_70': pricing['30d05_annual_70'][locale],
    '30d10_annual_70': pricing['30d10_annual_70'][locale],
  }

  const selectedProduct = options[selectedProductCode ?? '30d01_annual_70']

  useEffect(() => {
    Analytics.identify({
      email: emailAddress,
    })
  }, [emailAddress])

  const handleChange = (productCode: ChooseYourOwnPriceProductCode) => {
    setSelectedProductCode(productCode)
    Analytics.track('Email Confirmation - Choose Price Option Selected', {
      chosen_price: analyticsChosenPrice(productCode),
    })
  }
  return view === 'selector' ? (
    <ChooseYourPriceSelectorView
      locale={locale}
      emailAddress={emailAddress}
      options={options}
      selectedId={selectedProductCode}
      onChange={handleChange}
      onContinue={() => {
        Analytics.track('Email Confirmation - Choose Price Continue Clicked ', {
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
        })
        setView('checkout')
      }}
    />
  ) : (
    <ChooseYourPriceCheckoutView product={selectedProduct} locale={locale} emailAddress={emailAddress} />
  )
}

export default ChooseYourPriceCheckoutScreen
