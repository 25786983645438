import Analytics from "../Analytics";
import { useStore } from "../../state/webOnboarding2";
import MarketingEvents from "../MarketingEvents";
import Cookies from "js-cookie";

export const sendAnalytics = (title: string, payload: object = {}) => {
    Analytics.track(title, {
        code: useStore.getState().code ?? 'web_onboarding',
        email: useStore.getState().email,
        personId: useStore.getState().personId,
        ...payload,
    });
}

export const identifyUserForAnalytics = (payload: object = {}) => {
    Analytics.identify(useStore.getState().personId, {
        ...payload,
        email: useStore.getState().email
    })
}

export type SleepType = 'weekend' | 'weekday';

let sentEvents:string[] = [];

export const sendTooShortSleepEvent = (sleepType: SleepType) => {
    if (!sentEvents.includes(sleepType)) {
        sendAnalytics('Too short sleep entered in Sleep Survey', { schedule: sleepType })
        sentEvents = [...sentEvents, sleepType]
    }
}

export const sendFacebookConversionEvent = (personId: string, email: string) => {
    const currentTimeMs = Math.floor(Date.now())
    const eventId = `sign_up_${personId}_${currentTimeMs}`

    MarketingEvents.sendMarketingEvent(
        MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
        {},
        {eventID: eventId}
    )
    MarketingEvents.sendConversionAPIEvent(MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION, eventId, {
        em: email,
        external_id: `${personId}`,
        fbp: Cookies.get('_fbp')
    })
}
