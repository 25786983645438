import React, {ReactElement, useEffect} from 'react';
import MaybeStagingBadge from "../../components/MaybeStagingBadge";
import logo from '../../images/logo.svg';
import profile from "../../images/profile.svg";
import {useQueryToken} from "../../utils/dynamicCodes/hooks";


function PaymentComplete(): ReactElement {
    const email = useQueryToken();

    useEffect(() => {
        window.location.href = 'nyx://payment_complete/'
    }, [])

    return (
        <div className="w-full h-screen mx-auto" id="new-years-2021">
            <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
                <MaybeStagingBadge />
                <div className={'py-6 md:py-12'}>
                    <img
                        src={logo}
                        alt="RISE"
                        className="self-start"
                        style={{ height: 16 }}
                    />
                </div>
                <div
                    className={`w-px-320 md:w-px-600 ${
                        window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
                    } sm:space-y-0 sm:pt-20`}
                >
                    <div className="pt-8 flex flex-col">
                        <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                            You're all set!
                        </h1>
                        <div className="flex justify-end my-2  pb-2">
                            <hr className="w-full border-t border-white opacity-25" />
                        </div>
                        {email ? (
                            <div
                                className={`opacity-0 text-white text-lg font-medium flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
                                    email && 'opacity-100'
                                }`}
                            >
                                <img src={profile} alt="" className="inline mr-2 w-5" />
                                {email ?? '...'}
                            </div>
                        ) : null}
                        <p className="text-white text-sm">
                            Now, just open the RISE app to start your journey to better sleep and energy.
                        </p>
                        <div className="flex justify-end my-8 pb-2 ">
                            <hr className="w-full border-t border-white opacity-25" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentComplete;
