import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ActionButton from '../../../../screens/WebOnboarding2/components/ActionButton'
import Analytics from '../../../../utils/Analytics'
import Environment from '../../../../utils/Environment'
import ChoosePriceSelector, { ChoosePriceSelectorOptions } from '../../components/ChoosePriceSelector'
import ListItem from '../../components/ListItem'
import { SupportedCountryCode } from '../../../../utils/currency'
import radialBg from '../../assets/dark-radial-bg.png'
import logo from '../../assets/logo@3x.png'
import idCard from '../../assets/id-badge.svg'
import { ChooseYourOwnPriceProductId, localizedCompensationPrice } from '../../chooseYourOwnPrices'
import { useTranslation } from 'react-i18next'

export const stripeStyle = {
  colorPrimary: '#0570de',
  colorBackground: '#30313d',
  colorText: 'white',
  colorDanger: '#df1b41',
  borderRadius: '4px',
  colorPrimaryText: 'white',
  fontSizeSm: '1rem',
}

type ChooseYourPriceSelectorViewProps = {
  locale: SupportedCountryCode
  emailAddress: string
  selectedId: ChooseYourOwnPriceProductId | null
  options: ChoosePriceSelectorOptions
  onChange: (productId: ChooseYourOwnPriceProductId) => void
  onContinue: () => void
}

const ChooseYourPriceSelectorView = ({
  locale,
  emailAddress,
  onContinue,
  selectedId,
  options,
  onChange,
}: ChooseYourPriceSelectorViewProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    Analytics.track('Email Confirmation - Choose Price Viewed', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
      locale,
    })
  }, [emailAddress, locale])

  const handleContinue = () => {
    onContinue()
  }

  return (
    <>
      <Helmet>
        {/* Prevents zooming into the text input when entering a credit card number */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className={`w-full h-screen mx-auto`}>
        <div
          className="flex flex-col relative h-full"
          style={{
            backgroundImage: `url(${radialBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="flex flex-col flex-1 px-5 items-center justify-top pt-5">
            <img src={logo} style={{ width: 60 }} alt="Rise" />
            <div className="text-white px-1 pt-2 pb-5 text-3xl leading-tight text-center">
              {t('choose_your_price_selector_screen.title')}
            </div>
            <div className="px-5 pb-5  text-white text-sm leading-tight text-center">
              {t('choose_your_price_selector_screen.subtitle')}
            </div>
          </div>
          <div className="flex flex-col" style={{ paddingTop: '0px' }}>
            <ChoosePriceSelector
              options={options}
              locale={locale}
              selectedId={selectedId}
              onChange={(id) => {
                onChange(id)
              }}
            />
          </div>
          <div className="flex" style={{ paddingTop: '45px' }}>
            <ListItem
              Icon={<img src={idCard} alt="" />}
              text={t('choose_your_price_selector_screen.compensation', {
                compensationPrice: localizedCompensationPrice[locale],
              })}
            />
          </div>
          <div className="flex flex-col flex-1 px-5 pb-5 items-center justify-center">
            <ActionButton title={t('common.CONTINUE')} onClick={handleContinue} disabled={!selectedId} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseYourPriceSelectorView
