import React, { useState } from 'react'
import { SatisfactionModal } from '../../../onboarding-v3/components'
import badgeIcon from '../assets/badgeIcon.svg'
import reminderBellIcon from '../assets/reminderBellIcon.svg'
import thumbsUpIcon from '../assets/thumbsUpIcon.svg'

import { EmailConfirmationPrice } from '../prices'
import { Trans, useTranslation } from 'react-i18next'

interface ListItemProps {
  icon: string
  text: string | JSX.Element
}
const ListItem = ({ icon, text }: ListItemProps) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      padding: '2px 12px',
      color: 'white',
      fontSize: '14px',
    }}
  >
    <img src={icon} style={{ marginRight: '8px' }} alt="" />
    <span style={{ flex: 1 }}>{text}</span>
  </div>
)

const TrialDetailsList = ({
  trialOption,
}: {
  trialOption: EmailConfirmationPrice
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      {showModal ? (
        <SatisfactionModal onClose={() => setShowModal(false)} />
      ) : null}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <ListItem icon={thumbsUpIcon} text={trialOption.pricingText} />
        <ListItem
          icon={reminderBellIcon}
          text={
            <span onClick={() => setShowModal(true)}>
              <Trans
                i18nKey="checkout_screen.cancel_anytime_and_money_back_guarantee"
                components={{
                  u: <u />,
                }}
              />
            </span>
          }
        />
        <ListItem
          icon={badgeIcon}
          text={
            <span>{t('checkout_screen.reminder_before_your_trial_ends')}</span>
          }
        />
      </div>
    </>
  )
}

export default TrialDetailsList
