import React, { useEffect } from 'react'

import satisfactionBadge from '../assets/satisfaction-badge.png'
import { Modal } from '../../components'

import './SatisfactionModal.css'
import Analytics from '../../utils/Analytics'
import { useTranslation } from 'react-i18next'

type SatisfactionModalProps = {
  /** Handler triggered when the user closes the modal */
  onClose: () => void
}

/**
 * Render a satisfaction guarantee inside a modal
 */
export const SatisfactionModal: React.FC<SatisfactionModalProps> = (props) => {
  const { t } = useTranslation()
  const { onClose } = props

  useEffect(() => {
    Analytics.track('Intro to Rise - Satisfaction modal Viewed')
  }, [])

  const closeModal = () => {
    Analytics.track('Intro to Rise - Satisfaction modal Clicked')
    onClose()
  }

  return (
    <Modal>
      <div className="satisfaction-modal__content">
        <div className="satisfaction-modal__badge">
          <img src={satisfactionBadge} alt="Satisfaction badge" />
        </div>
        <h1 className="satisfaction-modal__headline">
          {t('satisfaction_modal.title')}
        </h1>
        <p className="satisfaction-modal__copy">
          {t('satisfaction_modal.body')}
        </p>
        <div style={{ display: 'flex', marginTop: '1rem' }}>
          <span className="satisfaction-modal__dot">&bull;</span>
          <p
            className="satisfaction-modal__copy"
            style={{ fontSize: '0.875rem' }}
          >
            {t('satisfaction_modal.bullet')}
          </p>
        </div>
      </div>
      <button className="satisfaction-modal__cta" onClick={closeModal}>
        {t('common.GOT_IT')}
      </button>
    </Modal>
  )
}
