import React, {ReactElement, useEffect, useState} from 'react';
import { useStore } from '../../../state/webOnboarding2';
import { screenConfigs } from '../../../utils/webOnboarding2/constants';
import { withRedirectAndTitle } from '../withRedirectAndTitle';
import { EnergyProfileView } from './EnergyProfileView';
import { sendAnalytics } from "../../../utils/webOnboarding2/webOnboardingAnalytics";
import {useHistory} from "react-router-dom";

export type UiState =
  | 'readyToCalculate'
  | 'calculating'
  | 'finished'
  | 'processing';

function EnergyProfile(): ReactElement {
  const [step, setStep] = useState(0);
  const {
    energyDipStart,
    energyDipEnd,
    debtMinutes,
  } = useStore();

  const history = useHistory();

  useEffect(() => {
    if (step === 0) {
      useStore.getState().getCircadianCurve();
      setStep(1)
    } else if (step <= 5) {
      sendAnalytics(`Energy Schedule ${step} Shown`)
    }
  }, [step]);

  const handleClick = () => {
    setStep((_s) => _s + 1);
    if (step >= 5) {
      history.push(screenConfigs.paywall.url)
    }
  };

  const onStepComplete = (completeStep: number) => {};
  return (
    <EnergyProfileView
      sleepDebtMinutes={debtMinutes}
      dipTime={{ start: energyDipStart, end: energyDipEnd }}
      animationStep={step}
      onClick={handleClick}
      onStepComplete={onStepComplete}
    />
  );
}

export default withRedirectAndTitle(
  EnergyProfile,
  screenConfigs.energyProfile.documentTitle
);
