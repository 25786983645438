import React from 'react'
import classNames from 'classnames'

import riseLogo from '../assets/rise-logo.png'
import { ProgressBar, ProgressBarProps } from './ProgressBar'

import './Nav.css'

type NavProps = {
  /** Determines if the nav uses fixed positioning */
  fixed?: boolean
  /** Hide the RISE brand logo */
  hideBrandLogo?: boolean
  /** Event handler called when the user clicks on the try button */
  onSignUp: () => void
  /** Information required to render a progress bar indicating how many more children exist */
  progressBar?: ProgressBarProps
  /** Make the nav background transparent */
  transparent?: boolean
}

export const Nav: React.FC<NavProps> = (props) => {
  const {
    fixed = false,
    hideBrandLogo = false,
    onSignUp,
    progressBar,
    transparent = false,
  } = props

  const navCls = classNames('ob3__layout-grid__nav', {
    'ob3__layout-grid__nav--fixed': fixed,
    'ob3__layout-grid__nav--transparent': transparent,
  })

  return (
    <nav className={navCls}>
      <div className="ob3__layout-grid__nav__group">
        <div className="ob3__layout-grid__nav__items">
          <div className="ob3__layout-grid__nav__brand">
            {hideBrandLogo ? null : (
              <img alt="RISE logo" src={riseLogo} width="56" />
            )}
          </div>
          <button className="ob3__layout-grid__nav__btn" onClick={onSignUp}>
            Try for free
          </button>
        </div>
      </div>

      {progressBar !== undefined ? <ProgressBar {...progressBar} /> : null}
    </nav>
  )
}
