import { format, set } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import ButtonProcessing from '../../../components/ButtonProcessing';
import ActionButton from '../components/ActionButton';
import { EnergyScheduleAnimation } from './components/EnergyAnimation';


export function EnergyProfileView(props: {
  sleepDebtMinutes: number;
  dipTime: { start: number; end: number };
  onClick: () => void;
  onStepComplete?: (step: number) => void;
  animationStep: number;
}) {
  const step = props.animationStep;

  const [enableButton, setEnableButton] = useState(true);

  // will re-enable the button if for some reason it doesn't show up
  const tryAgain = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    tryAgain.current && clearTimeout(tryAgain.current);
    if (!enableButton) {
      // @ts-expect-error
      tryAgain.current = setTimeout(() => {
        setEnableButton(true);
      }, 4000);
    }
  }, [enableButton]);

  useEffect(() => {
    setEnableButton(false);
  }, [props.animationStep]);

  const _onClick = () => {
    setEnableButton(false);
    props.onClick();
  };

  const _onStepComplete = (completeStep: number) => {
    setEnableButton(true);
    props.onStepComplete && props.onStepComplete(completeStep);
  };

  const formattedDipStart = format(
    props.dipTime.start || set(new Date(), { hours: 12, minutes: 35 }),
    'h:mmaaaaa'
  );

  const formattedDipEnd = format(
    props.dipTime.end || set(new Date(), { hours: 15, minutes: 25 }),
    'h:mmaaaaa'
  );

  return (
    <div className="h-screen  mx-auto w-full sm:w-px-640  relative z-10 flex flex-col overflow-hidden">
      <EnergyScheduleAnimation step={step} onStepComplete={_onStepComplete} />

      <div
        className="w-full px-3 pl-8 text-2xl text-white absolute top-1/2"
        style={{
          transform: 'translateY(-50px)',
          transition: '800ms',
          opacity: step === 1 ? 1 : 0,
        }}
      >
        This is your <b>Energy Schedule</b> today.
      </div>

      <div
        className="w-full px-3 pl-8 text-2xl text-white absolute top-1/2"
        style={{
          transform: 'translateY(-50px)',
          transition: '600ms',
          opacity: step === 2 ? 1 : 0,
        }}
      >
        RISE predicts daily energy levels using just your <b>sleep times</b>.
      </div>
      <div
        className="w-full px-3 pl-8 text-2xl text-white absolute top-1/2"
        style={{
          transform: 'translateY(-50px)',
          transition: '600ms',
          opacity: step === 3 ? 1 : 0,
        }}
      >
        For example, you’ll feel an{' '}
        <b>
          energy dip from {formattedDipStart} - {formattedDipEnd}
        </b>{' '}
        today.
      </div>
      <div
        className="w-full px-3 pl-8 text-2xl text-white absolute top-1/2"
        style={{
          transform: 'translateY(-50px)',
          transition: '600ms',
          opacity: step === 4 ? 1 : 0,
        }}
      >
        Get your sleep need to lower your debt & raise your energy potential.
      </div>
      <div
        className="w-full px-3 pl-8 text-2xl text-white absolute top-1/2"
        style={{
          transform: 'translateY(-50px)',
          transition: '600ms',
          opacity: step >= 5 ? 1 : 0,
        }}
      >
        Follow your daily energy to get more out of your day, wind-down and
        sleep.
      </div>

      <div
        className="w-full px-3 flex justify-center"
        style={{ position: 'absolute', bottom: 50 }}
      >
        {enableButton && step > 0 && step <= 5 && (
          <ActionButton title="NEXT" onClick={_onClick} />
        )}
        {step === 6 && (
          <ButtonProcessing testId="processingButton" title="PROCESSING..." />
        )}
      </div>
    </div>
  );
}
