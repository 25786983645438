import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import ButtonError from '../../components/ButtonError'
import ButtonProcessing from '../../components/ButtonProcessing'
import ButtonSignUp from '../../components/ButtonSignUp'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import { useMarketingSourceCodeFromParams } from '../../hooks/useMarketingSourceCodeFromParams'
import MarketingEvents from '../../utils/MarketingEvents'
import logo from '../../images/logo.svg'
import Analytics from '../../utils/Analytics'
import validateEmail from '../../utils/validateEmail'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import shareImage from '../../onboarding-v3/paywall/assets/share_image.png'

const DEFAULT_MARKETING_SOURCE = 'web_funnel_default'

interface SignUpRequestParams {
  email: string
  signUpSource?: string
  marketingSource: {
    code: string
  }
}

type UiState = 'ready' | 'valid_email' | 'processing'

function getPostSignUpURL(email: string, code: string): string {
  const CODES_TO_START_TRIAL = [
    'RISE',
    'JOBBLE',
    'fb',
    'goog',
    'facebook',
    'PERKSPOT',
    'LEON',
    'gift',
    'survey',
  ].map((v) => v.toLowerCase())
  if (CODES_TO_START_TRIAL.includes(code.toLowerCase())) {
    return `/start-trial?token=${email}&code=${code}`
  } else {
    return `/sign-up-success?user_id=${email}`
  }
}

const SignUpPage = () => {
  const history = useHistory()
  const code = useMarketingSourceCodeFromParams()

  const [processing, setProcessing] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [daysFree, setDaysFree] = useState(7)

  const uiState: UiState = ((): UiState => {
    if (processing) {
      return 'processing'
    }
    if (emailIsValid) {
      return 'valid_email'
    }
    return 'ready'
  })()

  useEffect(() => {
    if (uiState === 'ready') {
      Analytics.track('Web Sign Up Page Viewed', {
        code: code,
      })
    }

    //TODO: Likely move to a central place with all the config of codes
    if (['jobble'].includes(code.toLowerCase())) {
      setDaysFree(60)
    }

    if (['perkspot', 'leon', 'gift', 'survey'].includes(code.toLowerCase())) {
      setDaysFree(30)
    }
  }, [uiState, code])

  useEffect(() => {
    const isValid = validateEmail(email)
    setEmailIsValid(isValid)
  }, [email])

  function createAccountEndpoint(_email: string, _code: string | null) {
    setError(false)
    setProcessing(true)
    const params: SignUpRequestParams = {
      signUpSource: 'web',
      email: _email,
      marketingSource: {
        code: _code ?? DEFAULT_MARKETING_SOURCE,
      },
    }

    axios
      .post('https://api.risesci.com/api/web-create-account', params)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Status: ${res.status} ${res.statusText}`)
        }
        const currentTimeMs = Math.floor(Date.now())
        const personId = res.data.person_id
        Analytics.identify(personId, {
          email: params.email, // TODO: should this be params.token?
          marketingSourceCode: params.marketingSource.code,
        })
        Analytics.track('Web Sign Up Success', {
          marketingSourceCode: params.marketingSource.code,
        })
        const eventId = `sign_up_${personId}_${currentTimeMs}`
        MarketingEvents.sendMarketingEvent(
          MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
          {},
          { eventID: eventId }
        )
        MarketingEvents.sendConversionAPIEvent(
          MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
          eventId,
          {
            em: params.email,
            external_id: `${personId}`,
            fbp: Cookies.get('_fbp'),
          }
        )

        const nextURL = getPostSignUpURL(_email, code!)
        history.push(nextURL)

        return res.data
      })
      .catch((_error) => {
        Analytics.track('Web Sign Up Error', {
          error: _error,
          marketingSourceCode: params.marketingSource.code,
          email: params.email,
        })
        setError(true)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  if (code === 'gift') {
    return <Redirect to="/offer/gift30" />
  }

  return (
    <div className="w-full h-screen mx-auto" id="new-years-2021">
      <Helmet>
        <meta
          id="og-image"
          property="og:image"
          content={`${window.location.origin.toString()}${shareImage}`}
        />
      </Helmet>
      <form
        onSubmit={(event) => {
          if (uiState === 'valid_email') {
            createAccountEndpoint(email, code)
          }
          event.preventDefault()
        }}
      >
        <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
          <MaybeStagingBadge />
          <div className={'py-6 md:py-12'}>
            <img
              src={logo}
              alt="RISE"
              className="self-start"
              style={{ height: 16 }}
            />
          </div>
          <div
            className={`w-px-320 md:w-px-600 ${
              window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
            } sm:space-y-0 sm:pt-20`}
          >
            <div className="pt-8 flex flex-col">
              <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                Enter your email address to get{' '}
                <span className={'whitespace-nowrap'}>
                  {`${daysFree}`}-days
                </span>{' '}
                free.
                <br />
              </h1>
              <p className="text-white text-sm">
                After you download the RISE app, you’ll use this same email
                address to log in and start your free {`${daysFree}`} days.
              </p>
              <div className="flex justify-end my-8 pb-2 relative">
                <hr className="w-full border-t border-white opacity-25" />
              </div>
              <div className="text-white pb-3">
                <label className="block text-gray-700 text-sm mb-2 ">
                  Email address
                </label>
                <input
                  type="email"
                  name="name"
                  value={email}
                  className={`w-full md:w-2/3 form-input bg-panelGray-1 border py-3 rounded-none ${
                    error ? 'border-red-1' : 'border-gray-4'
                  }`}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && (
                  <div className="mt-2 text-red-1 text-sm font-bold">
                    Oops, this email looks off. Review or try another.
                  </div>
                )}
              </div>
              {uiState === 'processing' && (
                <ButtonProcessing
                  testId="processingButton"
                  title="PROCESSING..."
                />
              )}

              {uiState === 'valid_email' && (
                <div className={'md:w-64'}>
                  <ButtonSignUp
                    testId="signUpButton"
                    title="CREATE PROFILE & DOWNLOAD"
                    onClick={() => createAccountEndpoint(email, code)}
                  />
                </div>
              )}

              {uiState === 'ready' && (
                <div className={'md:w-64'}>
                  <ButtonError
                    testId="signUpButtonNotReady"
                    title="CREATE PROFILE & DOWNLOAD"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SignUpPage
