import thumbsUp from './images/thumbs_up.svg'
import happyFace from './images/happy_face.png'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Analytics from '../../utils/Analytics'

interface ModalParams {
  code: string
  hide: () => void
}

const defaultCode = 'facebook'

const WebVsAppStoreModal = ({ code, hide }: ModalParams) => {
  // code can be used to route to a specific offer in the future
  const history = useHistory()

  useEffect(() => {
    Analytics.track('Web Vs App Store Prelude - Modal Opened', { code })
  }, [code])

  return (
    <>
      <div
        className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-2 mt-0"
        style={{ maxWidth: '550px', marginRight: 'auto', marginLeft: 'auto' }}
      >
        <div className="relative w-auto my-2 mx-auto max-w-3xl">
          <div
            className="text-white rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none mb-0"
            style={{
              backgroundColor: '#16161a',
              padding: window.innerWidth > 800 ? '15px' : 0,
            }}
          >
            <div
              className="pl-5 pr-5 pt-1 rounded-t"
              style={{ position: 'relative', textAlign: 'center' }}
            >
              <button
                className="z-50 p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => hide()}
                style={{ position: 'absolute', top: 10, left: 10 }}
              >
                <span
                  className="text-white h-6 w-6 text-5xl outline-none focus:outline-none"
                  style={{ fontWeight: 300 }}
                >
                  ×
                </span>
              </button>

              <h3 className="text-5xl font-semibold mt-4">😊</h3>
              <p
                className={'text-3xl mt-0'}
                style={{ lineHeight: 1.1, textAlign: 'left' }}
              >
                Don't Miss the
                <br />
                Exclusive Web Offer
              </p>
              <p className={'leading-5 mt-2'} style={{ textAlign: 'left' }}>
                For the best customer experience, redeem your FREE week on Web.
              </p>
            </div>

            <div className={'ml-4 mr-4'}>
              <ul
                className="list-none mt-6 text-sm"
                style={{ textAlign: 'left' }}
              >
                <li key={1} className="text-white flex items-start pb-4">
                  <img
                    src={thumbsUp}
                    alt=""
                    className="inline"
                    style={{ height: 22, marginRight: 10 }}
                  />
                  We service your Web subscription (with a real person!)
                  including refunds if you are dissatisfied for any reason.
                </li>
                <li key={2} className="text-white flex items-start pb-4">
                  <img
                    src={happyFace}
                    alt=""
                    className="inline"
                    style={{ height: 22, marginRight: 10 }}
                  />
                  In the App Store, ~$20 of your subscription goes to Apple.
                  We’d rather pass those savings on to you.
                </li>
              </ul>
              <p className={'text-sm mt-0'} style={{ textAlign: 'left' }}>
                As an added bonus, by subscribing on web, you’ll be supporting
                small businesses.
              </p>
            </div>
            <div
              className="rounded-b mt-8 ml-2 mr-2"
              style={{ textAlign: 'center' }}
            >
              <button
                onClick={() => {
                  Analytics.track(
                    'Web Vs App Store Prelude - Rise Checkout Clicked',
                    { code }
                  )
                  history.push(`/try-rise/${code ?? defaultCode}`)
                  hide()
                }}
                style={{
                  width: '95%',
                  borderWidth: '3px',
                  letterSpacing: '0.1rem',
                }}
                className={
                  'border border-purple-1 w-full bg-purple-1 text-white rounded-md font-medium tracking-wide transition-opacity duration-500 ease-in-out h-16'
                }
              >
                REDEEM 40% OFF ON WEB
              </button>
              <button
                onClick={() => {
                  Analytics.track(
                    'Web Vs App Store Prelude - App Store Clicked',
                    { code }
                  )
                  window.location.href =
                    'https://apps.apple.com/us/app/rise-energy-sleep-tracker/id1453884781?ppid=ad072ee0-323a-45af-924b-4dfd3026bc60'
                  hide()
                }}
                className={
                  'mt-4 mb-6 border-purple-1 w-full text-white rounded-md font-medium tracking-wide transition-opacity duration-500 ease-in-out h-16'
                }
                style={{
                  width: '95%',
                  borderWidth: '3px',
                  letterSpacing: '0.1rem',
                }}
              >
                CONTINUE TO APP STORE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  )
}

export default WebVsAppStoreModal
