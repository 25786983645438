import React, {ChangeEvent, ReactElement, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {EmailSignUpView} from "./EmailSignUpView";
import validateEmail from "../../../utils/validateEmail";
import {useStore} from "../../../state/webOnboarding2";
import { ApiError, screenConfigs } from "../../../utils/webOnboarding2/constants";
import {withRedirectAndTitle} from "../withRedirectAndTitle";
import {useCodeFromParams} from "../../../utils/webOnboarding2/hooks";

export type UiState = 'ready' | 'valid_email' | 'processing' | 'signUpSuccess';

function EmailSignUp(): ReactElement {
    const history = useHistory();

    const {
        email,
        setEmail,
        isFetching,
        signUpSuccess,
        createAccount,
        error,
        errorInfo,
        setErrorInfo,
        setCode,
    } =  useStore(state => ({
        email: state.email,
        setEmail: state.setEmail,
        isFetching: state.isFetching,
        signUpSuccess: state.signUpSuccess,
        createAccount: state.createAccount,
        error: state.error,
        errorInfo: state.errorInfo,
        setError: state.setError,
        setErrorInfo: state.setErrorInfo,
        setCode: state.setCode,
    }))

    const code = useCodeFromParams();

    const uiState: UiState = ((): UiState => {
        if (signUpSuccess) {
            return 'signUpSuccess';
        }
        if (isFetching) {
            return 'processing';
        }
        if (validateEmail(email)) {
            return 'valid_email';
        }
        return 'ready';
    })();

    useEffect(() => {
        if (uiState === 'ready' && code !== null) {
            setCode(code);
        }
    }, [code, setCode, uiState]);

    const goToNextScreen = () => {
        history.push(screenConfigs.jobSelection.url)
    }

    const tryToCreateAccount = async () => {
        await createAccount()
    }

    const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        setErrorInfo(ApiError.None)
    }

    return <EmailSignUpView
            email={email}
            error={error}
            errorInfo={errorInfo}
            onChange={changeInput}
            uiState={uiState}
            onCreateProfileClick={tryToCreateAccount}
            onAccountCreateSuccess={goToNextScreen} />
};

export default withRedirectAndTitle(EmailSignUp, screenConfigs.emailSignUp.documentTitle);
