import React from "react";
import UserIcon from "../images/UserIcon.svg";
import ActionButton from "./ActionButton";
interface AccountSuccessProps {
  email?: string;
  onClick: () => void;
}

const AccountSuccess = ({ email, onClick }: AccountSuccessProps) => {
  return (
    <div className="pt-4 px-3 flex flex-col">
      <text className={`text-xl text-white pb-2 font-bold`}>
        Account created
      </text>
      <div className={`flex flex-row items-center text-center`}>
        <div className={`pr-2`}>
          <img src={UserIcon} alt="" className="inline w-5" />
        </div>
        <div className="text-white">{email}</div>
      </div>
      <div className={`pt-10`}>
        <text className="text-white text-xl">
          Now, just a few questions to get you to better sleep and energy.
        </text>
      </div>
      <div className={`pt-10`}>
        <ActionButton title={"CONTINUE"} onClick={onClick} />
      </div>
    </div>
  );
};

export default AccountSuccess;
