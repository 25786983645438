import './styles/index.css'

import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import BlackFriday2020 from './screens/BlackFriday2020/BlackFriday2020'
import BlackFriday2021 from './screens/BlackFriday2020/BlackFriday2021'
import NewMemberOffer2020 from './screens/NewMemberOffer2020/NewMemberOffer2020'

import { Page as OnboardingReversion3 } from './onboarding-v3/Page'
import DynamicOfferScreen from './screens/DynamicOfferScreen/DynamicOfferScreen'
import DynamicSuccess from './screens/DynamicOfferScreen/DynamicSuccess'
import PaymentComplete from './screens/DynamicOfferScreen/PaymentComplete'
import { KeywordGreeting } from './screens/KeywordGreeting'
import NewYears2021 from './screens/NewYears2021/NewYears2021'
import PartnerConnectPage from './screens/PartnerConnect/PartnerConnectPage'
import Redirect from './screens/Redirect/Redirect'
import RenewalOfferScreen from './screens/RenewalOfferScreen/RenewalOfferScreen'
import SignUpPage from './screens/SignUpPage/SignUpPage'
import SignUpSuccessPage from './screens/SignUpSuccess/SignUpSuccess'
import StartTrialScreen from './screens/StartTrialScreen/StartTrialScreen'
import StripeCustomSuccess from './screens/StripeCustomCheckout/StripeCustomSuccess'
import StripeElementProvider from './screens/StripeCustomCheckout/StripeElementProvider'
import ThankYou from './screens/ThankYou/ThankYou'
import WebVsAppStorePrelude from './screens/WebVsAppStore/WebVsAppStorePrelude'
import WorldSleepDay2021 from './screens/WorldSleepDay2021/WorldSleepDay2021'
import { WebOnboarding2Routes } from './utils/webOnboarding2/WebOnboarding2Routes'

import { Experiment, Variant } from '@marvelapp/react-ab-test'
import { LocalizationHandler } from './i18n/LocalizationHandler'
import WebOnboardingV3Paywall from './onboarding-v3/paywall/WebOnboardingV3Paywall'
import { screenConfigs as emailConfirmationConfig } from './projects/emailConfirmation-0424/constants'
import DesignTrialCheckoutScreen from './projects/emailConfirmation-0424/screens/DesignTrialCheckoutScreen'
import EmailConfirmationCheckoutScreen from './projects/emailConfirmation-0424/screens/EmailConfirmationCheckoutScreen'
import EmailConfirmationFeatureCardScreen from './projects/emailConfirmation-0424/screens/EmailConfirmationFeatureCardScreen'
import EmailConfirmationTrialScreen from './projects/emailConfirmation-0424/screens/EmailConfirmationTrialScreen'
import StudentEmailConfirmationCheckoutScreen from './projects/emailConfirmation-0424/screens/StudentEmailConfirmationCheckoutScreen'
import OpenInApp from './screens/OpenInApp/OpenInApp' // no TS on this project
import { customVariant, originalVariant, webOnboardingV3Routing } from '.'

import ChooseYourPriceTrialReminderScreen from './projects/emailConfirmation-0424/screens/ChooseYourPrice/ChooseYourPriceTrialReminderScreen'
import ChooseYourPriceCheckoutScreen from './projects/emailConfirmation-0424/screens/ChooseYourPrice/ChooseYourPriceCheckoutScreen'

export const Routes: React.FC = () => {
  return (
    <Router>
      <LocalizationHandler>
        <Switch>
          <Route path="/thankyou">
            <ThankYou />
          </Route>
          <Route path="/redirect">
            <Redirect />
          </Route>
          <Route path="/new-member-offer">
            <NewMemberOffer2020 />
          </Route>
          <Route path="/black-friday-2020">
            <BlackFriday2020 />
          </Route>
          <Route path="/black-friday-2021">
            <BlackFriday2021 />
          </Route>
          <Route path="/new-years-2021">
            <NewYears2021 />
          </Route>
          <Route path="/world-sleep-day-2021">
            <WorldSleepDay2021 />
          </Route>
          <Route path="/sign-up">
            <SignUpPage />
          </Route>
          <Route path="/sign-up-success">
            <SignUpSuccessPage />
          </Route>
          <Route path="/renewal-offer">
            <RenewalOfferScreen />
          </Route>
          <Route path="/start-trial">
            <StartTrialScreen />
          </Route>
          <Route path="/partner-connect">
            <PartnerConnectPage />
          </Route>
          <Route path="/greetings">
            <KeywordGreeting />
          </Route>
          <Route path="/intro-to-rise">
            <Experiment name={webOnboardingV3Routing}>
              <Variant name={originalVariant}>
                <OnboardingReversion3 />
              </Variant>
              <Variant name={customVariant}>
                <Route
                  path=""
                  component={() => {
                    window.location.href =
                      'https://apps.apple.com/us/app/rise-energy-sleep-tracker/id1453884781?ppid=719990e9-e0ee-408c-9811-1bb5fc41eebc'
                    return null
                  }}
                />
              </Variant>
            </Experiment>
          </Route>
          <Route path="/start-rise/:name" component={WebOnboardingV3Paywall} />
          <Route path="/offer/success" component={DynamicSuccess} />
          <Route path="/offer/:name" component={DynamicOfferScreen} />
          <Route path="/payment-complete" component={PaymentComplete} />
          <Route path="/try-rise/:name" component={StripeElementProvider} />
          <Route path="/payment-success/" component={StripeCustomSuccess} />
          <Route path="/support-app-devs/:name" component={WebVsAppStorePrelude} />
          <Route path="/open-in-app">
            <OpenInApp />
          </Route>
          <Route path={emailConfirmationConfig.emailConfirmationFeatures.url}>
            <EmailConfirmationFeatureCardScreen />
          </Route>
          <Route path={emailConfirmationConfig.emailConfirmationTrialConfirmation.url}>
            <EmailConfirmationTrialScreen />
          </Route>
          <Route path={emailConfirmationConfig.emailConfirmationCheckout.url}>
            <EmailConfirmationCheckoutScreen
              initialOption={'annual_70'}
              otherOption={'30d099_annual_70'}
              isStudent={false}
            />
          </Route>
          <Route path={emailConfirmationConfig.emailConfirmationCheckoutStudentPrices.url}>
            <StudentEmailConfirmationCheckoutScreen
              initialOption={'annual_36'}
              otherOption={'30d249_annual_36'}
              isStudent={true}
            />
          </Route>
          <Route path={emailConfirmationConfig.chooseYourPriceCheckout.url}>
            <ChooseYourPriceCheckoutScreen />
          </Route>
          <Route path={emailConfirmationConfig.chooseYourPriceCheckoutTrialConfirmation.url}>
            <ChooseYourPriceTrialReminderScreen />
          </Route>
          <Route path={emailConfirmationConfig.designTrialCheckout.url}>
            <DesignTrialCheckoutScreen initialOption={'annual_70'} otherOption={'30d099_annual_70'} isStudent={false} />
          </Route>
          <Route path={emailConfirmationConfig.designTrialStudentCheckout.url}>
            <DesignTrialCheckoutScreen initialOption={'annual_36'} otherOption={'30d249_annual_36'} isStudent={true} />
          </Route>
          {/* NOTE: Because WebOnboarding2Routes is returning a React Fragment
            It appears that routes defined after that aren't rendered properly.
        */}
          <WebOnboarding2Routes />
        </Switch>
      </LocalizationHandler>
    </Router>
  )
}
