import React from 'react'

import radialBg from '../assets/radial-bg.png'

import './GradientPhone.css'

/**
 * Render an image of the RISE app on device over a gradient background.
 *
 * The child element should be the <img> of the phone you want to render.
 *
 * @example
 * ```
 *  <GradientPhone>
 *   <img alt="Circadian curve on RISE app" src={phone} />
 * </GradientPhone>
 * ```
 */
export const GradientPhone: React.FC = (props) => {
  const { children } = props
  const el = React.Children.toArray(children)[0]

  if (!React.isValidElement(el)) {
    return null
  }

  const img = React.cloneElement(el, {
    className: 'ob3__gradient-phone__phone',
    width: 350,
  })

  return (
    <div className="ob3__gradient-phone">
      {img}
      <img
        alt="Gradient background"
        className="ob3__gradient-phone__bg"
        src={radialBg}
      />
    </div>
  )
}
