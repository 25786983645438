import React, {ReactElement} from "react";
import {useHistory} from "react-router-dom";
import {JobSelectionView} from "./JobSelectionView";
import {useStore} from "../../../state/webOnboarding2";
import { jobOptions, screenConfigs } from "../../../utils/webOnboarding2/constants";
import {withRedirectAndTitle} from "../withRedirectAndTitle";

export type UiState = 'ready' | 'jobSelected' | 'processing' | 'error';

function JobSelection(): ReactElement {
    const history = useHistory();

    const {
        isFetching,
        error,
        jobSelected,
        setJobSelected,
        sendJob,
    } = useStore(state => ({
        isFetching: state.isFetching,
        error: state.error,
        jobSelected: state.jobSelected,
        setJobSelected: state.setJobSelected,
        sendJob: state.sendJob,
    }))

    // TODO: is this helpful?
    const uiState: UiState = ((): UiState => {
        if (jobSelected) {
            return 'jobSelected';
        }
        if (isFetching) {
            return 'processing';
        }
        if (error) {
            return 'error';
        }
        return 'ready';
    })();

    async function saveJob() {
        await sendJob();
        const error = useStore.getState().error
        if (!error) {
            history.push(screenConfigs.selfReportedWeekday.url)
        }
    }

    return <JobSelectionView
            jobs={Array.from(jobOptions)}
            onChange={(e) => setJobSelected(e.target.value)}
            error={error}
            onJobSelectedSave={saveJob}
            jobSelected={jobSelected}
            uiState={uiState} />
};

export default withRedirectAndTitle(JobSelection, screenConfigs.jobSelection.documentTitle);
