import { ProductCode } from './products'
import Environment from '../../utils/Environment'
import i18n from '../../i18n/i18n'
import { SupportedCountryCode } from '../../utils/currency'

export type EmailConfirmationPrice = {
  code: string
  price: number
  daysFree: number
  sheetLabel: string // Text to show on the Apple/Google Pay sheets
  productCode: ProductCode
  title: string | JSX.Element
  subtitle: string
  pricingText: string | JSX.Element // Text to show on Paywall when selected
}

export type AnnualPriceData = {
  annual: string
  monthly: string
}

export const annualPrices: Record<SupportedCountryCode, AnnualPriceData> = {
  US: {
    annual: '$69.99',
    monthly: '$5.83',
  },
  GB: {
    annual: '£59.99',
    monthly: '£4.99',
  },
  CA: {
    annual: 'CA$99.99',
    monthly: 'CA$8.33',
  },
  AU: {
    annual: 'AU$119.99',
    monthly: 'AU$9.99',
  },
  // DE formatting is shared with FR
  DE: {
    annual: '69,99 €',
    monthly: '5,83 €',
  },
}

export type SharedPriceData = Omit<EmailConfirmationPrice, 'code'>

export const createPriceDisplayData = (
  countryCode: SupportedCountryCode,
  introPrice: number,
  introPriceDisplay: string,
  daysFree: number,
  productCode: ProductCode
): SharedPriceData => {
  const planPrice = annualPrices[countryCode].annual
  const monthlyPlanPrice = annualPrices[countryCode].monthly
  const sheetLabel = i18n.t('checkout_screen.sheet_label', {
    daysFree,
    planPrice,
  })
  const subtitle = i18n.t('checkout_screen.days_free', { daysFree })
  const pricingTextFreeTrial = i18n.t('checkout_screen.pricing_text_free_trial', {
    daysFree,
    planPrice,
    monthlyPlanPrice,
  })
  const pricingTextPaidTrial = i18n.t('checkout_screen.pricing_text_paid_trial', {
    daysFree,
    introPriceDisplay,
    planPrice,
    monthlyPlanPrice,
  })
  const pricingText = introPrice === 0 ? pricingTextFreeTrial : pricingTextPaidTrial

  return {
    price: introPrice,
    daysFree: daysFree,
    sheetLabel: sheetLabel,
    productCode: productCode,
    title: introPriceDisplay,
    subtitle: subtitle,
    pricingText: pricingText,
  }
}

export type LocalizedPriceData = Record<SupportedCountryCode, EmailConfirmationPrice>

const displayData_annual_70 = (countryCode: SupportedCountryCode) => {
  return createPriceDisplayData(countryCode, 0, i18n.t('common.Free'), 7, 'annual_70')
}

export const get_localizedPrices_annual_70 = (): LocalizedPriceData => {
  return {
    US: {
      code: Environment.isProduction ? 'price_1MilI9H7jib4x1Gy878EYV6N' : 'price_1Mii1jH7jib4x1Gy0aGKzWkH',
      ...displayData_annual_70('US'),
    },
    GB: {
      code: Environment.isProduction ? 'price_1MilIAH7jib4x1GyxiA88980' : 'price_1Mii1lH7jib4x1Gy1X3OtcHk',
      ...displayData_annual_70('GB'),
    },
    CA: {
      code: Environment.isProduction ? 'price_1MilIAH7jib4x1GygCU31irs' : 'price_1Mii1kH7jib4x1GyRsG0LWqE',
      ...displayData_annual_70('CA'),
    },
    AU: {
      code: Environment.isProduction ? 'price_1MilIAH7jib4x1GyP9zHlENB' : 'price_1Mii1kH7jib4x1GyH2lOzCX7',
      ...displayData_annual_70('AU'),
    },
    DE: {
      code: Environment.isProduction ? 'price_1MilI9H7jib4x1GyMSOek7RF' : 'price_1Mii1kH7jib4x1GyaUO6JoRt',
      ...displayData_annual_70('DE'),
    },
  }
}
const displayData_30d099_annual_70 = (
  countryCode: SupportedCountryCode,
  introPrice: number,
  introPriceDisplay: string
) => {
  return createPriceDisplayData(countryCode, introPrice, introPriceDisplay, 30, '30d099_annual_70')
}

export const get_localizedPrices_30d099_annual_70 = (): LocalizedPriceData => {
  return {
    US: {
      code: Environment.isProduction ? 'price_1P9A0cH7jib4x1GyRl3Q50ED' : 'price_1P8mHXH7jib4x1GyrfxlPgq8',
      ...displayData_30d099_annual_70('US', 99, '$0.99'),
    },
    GB: {
      code: Environment.isProduction ? 'price_1PK59uH7jib4x1GyoO7sijZC' : 'price_1PK58UH7jib4x1Gyrqghmjib',
      ...displayData_30d099_annual_70('GB', 99, '£0.99'),
    },
    CA: {
      code: Environment.isProduction ? 'price_1PK59tH7jib4x1GyVHvbQLTR' : 'price_1PK58TH7jib4x1GyMtkZ9MmR',
      ...displayData_30d099_annual_70('CA', 99, 'CA$0.99'),
    },
    AU: {
      code: Environment.isProduction ? 'price_1PK59tH7jib4x1GyyzYWMKRD' : 'price_1PK58TH7jib4x1GySTMR77HZ',
      ...displayData_30d099_annual_70('AU', 199, 'AU$1.99'),
    },
    DE: {
      code: Environment.isProduction ? 'price_1PK59tH7jib4x1GyBE1i1YIQ' : 'price_1PK58SH7jib4x1GyhZIpeKqC',
      ...displayData_30d099_annual_70('DE', 99, '0,99 €'),
    },
  }
}
