import { UserConfiguration } from './fetchUserConfiguration';
import * as Sentry from '@sentry/react';
import Analytics from './Analytics';
import Environment from './Environment';
import { loadStripe } from '@stripe/stripe-js';

export async function redirectToStripe(userConfig: UserConfiguration) {
  const key = Environment.getVar('REACT_APP_STRIPE_KEY');
  if (!key) {
    throw new Error('Stripe key must be defined');
  }
  const stripe = await loadStripe(key);
  try {
    if (!stripe) {
      throw new Error('Stripe not initialized');
    }
    Analytics.track('Redirecting to Stripe', userConfig);

    if (userConfig?.checkout_session_id) {
      stripe.redirectToCheckout({
        sessionId: userConfig?.checkout_session_id,
      });
    } else {
      stripe.redirectToCheckout({
        lineItems: [
          {
            price: userConfig.stripe_price, //e.g "price_1Ha08lH7jib4x1GypeM4ERge",
            quantity: 1,
          },
        ],
        mode: 'subscription',
        successUrl: userConfig.success_url,
        cancelUrl: userConfig.cancel_url,
        clientReferenceId: userConfig.person_id,
        customerEmail: userConfig.email,
      });
    }

    return Promise.resolve();
  } catch (e) {
    Analytics.track('Error: Failed to redirect to stripe', {
      ...userConfig,
      ...e,
    });
    Sentry.captureException(e);
    return Promise.reject(e);
  }
}
