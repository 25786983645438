import React from 'react'

import heroArc from '../assets/hero-arc.png'
import {
  BodyCopy,
  BrandHeadline,
  Center,
  DoubleColumnSlide,
  ImageContainer,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const SleepDebt: React.FC = () => {
  useSlideTrackEvt('SleepDebt')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline ternary>Getting started</BrandHeadline>
        <LargeHeadline>Calculate your Sleep Debt.</LargeHeadline>
        <BodyCopy>
          RISE determines your current Sleep Debt by analyzing the sleep data
          and compares with your Sleep Need. This is the key metric RISE is
          built on.
        </BodyCopy>
      </div>
      <Center>
        <ImageContainer>
          <img alt="Hero arc graphic" src={heroArc} />
        </ImageContainer>
      </Center>
    </DoubleColumnSlide>
  )
}
