/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react'

import Analytics from '../../utils/Analytics'

/**
 * Send an event indicating that a slide was viewed
 */
export const useSlideTrackEvt = (slideName: string) => {
  useEffect(() => {
    Analytics.track(`Intro To Rise - ${slideName} viewed`, {})
  }, [])
}
