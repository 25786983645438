import React, { HTMLAttributes } from 'react'

import classNames from 'classnames'

import './Text.css'

export const BodyCopy: React.FC<HTMLAttributes<HTMLParagraphElement>> = (
  props
) => {
  const { className, ...rest } = props
  const cls =
    'ob3__body-copy' + (className !== undefined ? ` ${className}` : '')

  return <p className={cls} {...rest} />
}

type BrandHeadlineProps = {
  /** Display the headline in secondary colors */
  secondary?: boolean
  /** Display the headline in ternary colors */
  ternary?: boolean
}

export const BrandHeadline: React.FC<BrandHeadlineProps> = (props) => {
  const { children, secondary = false, ternary = false } = props
  const cls = classNames('ob3__brand-headline', {
    'ob3__brand-headline--secondary': secondary,
    'ob3__brand-headline--ternary': ternary,
  })

  return <h1 className={cls}>{children}</h1>
}

export const LargeHeadline: React.FC = ({ children }) => (
  <p className="ob3__large-headline">{children}</p>
)

export const MediumHeadline: React.FC<HTMLAttributes<HTMLHeadingElement>> = (
  props
) => {
  const { className, children, ...rest } = props
  const cls =
    'ob3__medium-headline' + (className !== undefined ? ` ${className}` : '')

  return (
    <h3 className={cls} {...rest}>
      {children}
    </h3>
  )
}

/** Wrap text in gold */
export const GoldText: React.FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const { className, ...rest } = props
  const cls =
    'ob3__gold-text' + (className !== undefined ? ` ${className}` : '')
  return <span className={cls} {...rest} />
}

/** Wrap text in purple */
export const PurpleText: React.FC<HTMLAttributes<HTMLSpanElement>> = (
  props
) => {
  const { className, ...rest } = props
  const cls =
    'ob3__purple-text' + (className !== undefined ? ` ${className}` : '')
  return <span className={cls} {...rest} />
}
