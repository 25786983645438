import { EmailConfirmationPrice, get_localizedPrices_30d099_annual_70, get_localizedPrices_annual_70 } from './prices'
import { SupportedCountryCode } from '../../utils/currency'
import { get_localizedPrices_30d249_annual_36, get_localizedPrices_annual_36 } from './studentPrices'

export type ProductCode = 'annual_70' | '30d099_annual_70' | 'annual_36' | '30d249_annual_36'

export const getEmailConfirmationProducts = (): Record<
  ProductCode,
  Record<SupportedCountryCode, EmailConfirmationPrice>
> => {
  return {
    annual_70: get_localizedPrices_annual_70(),
    '30d099_annual_70': get_localizedPrices_30d099_annual_70(),
    annual_36: get_localizedPrices_annual_36(),
    '30d249_annual_36': get_localizedPrices_30d249_annual_36(),
  }
}
