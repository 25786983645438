import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { emitter, experimentDebugger } from '@marvelapp/react-ab-test'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Routes } from './Routes'
import reportWebVitals from './reportWebVitals'
import './styles/index.css'
import Analytics from './utils/Analytics'
import LoadingScreen from './screens/LoadingScreen'
import Environment from './utils/Environment'

export const webOnboardingV3Routing = 'Web Onboarding V3 - Routing'
export const originalVariant = 'Web Onboarding V3 Shown'
export const customVariant = 'App Store Shown'

// Create a GrowthBook instance

export const growthBook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GB_KEY,
  enableDevMode: !Environment.isProduction,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    Analytics.track('temp_growthbook_experiment', {
      experimentId: experiment.key ?? 'N/A',
      experimentValue: result.value ?? 'N/A',
      variationId: result.key ?? 'N/A',
      variantName: result.name ?? 'N/A',
      inExperiment: result?.inExperiment ?? 'N/A',
      resultValue: result?.value ?? 'N/A',
      gbEnvironment: Environment.isProduction ? 'production' : 'development',
      growthBookReady: growthBook.ready ?? 'N/A',
      surface: 'web',
    })
  },
  onFeatureUsage(featureKey, result) {
    Analytics.track('temp_growthbook_feature_used', {
      experimentId: featureKey,
      source: result.source,
      variationId: result?.experimentResult?.key ?? 'N/A',
      variantName: result.experimentResult?.name ?? 'N/A',
      inExperiment: result.experimentResult?.inExperiment ?? 'N/A',
      experimentValue: result.experimentResult?.value ?? 'N/A',
      resultValue: result.value ?? 'N/A',
      gbEnvironment: Environment.isProduction ? 'production' : 'development',
      growthBookReady: growthBook.ready ?? 'N/A',
      surface: 'web',
    })
  },
})

emitter.defineVariants(webOnboardingV3Routing, [originalVariant, customVariant], [100, 0])

declare global {
  interface Window {
    analytics: any
    branch?: any
  }
  const firstparty: any
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://515cce9b05cd4e429ab5f076ab101225@o107506.ingest.sentry.io/5518818',
    environment: process.env.REACT_APP_ENV,
  })
}
console.log('Get some sleep ♥ Rise')

// @ts-ignore
emitter.addPlayListener(function (experimentName, variantName) {
  Analytics.track(`Displaying experiment ${experimentName} variant ${variantName}`)
})

experimentDebugger.enable()

const App: React.FC = () => {
  const [gbReady, setGBReady] = useState(false)
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    timeout = setTimeout(() => {
      console.log('DEBUG: GrowthBook Timed Out')
      Analytics.track('Web: GrowthBook Timed Out')
      Sentry.captureException('Web: GrowthBook Timed Out')
      setGBReady(true)
    }, 1500)
    growthBook
      .init()
      .then((r) => {
        Analytics.track('Web: GrowthBook Init Success')
      })
      .catch((e) => {
        Analytics.track('Web: GrowthBook Init Error', {
          error: e,
        })
        Sentry.captureException(e)
      })
      .finally(() => {
        timeout && clearTimeout(timeout)
        setGBReady(true)
      })
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [])

  if (!gbReady) {
    return <LoadingScreen />
  }
  return (
    <React.StrictMode>
      <GrowthBookProvider growthbook={growthBook}>
        <Routes />
      </GrowthBookProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
