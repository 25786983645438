interface ScreenConfig {
    url: string;
    documentTitle: string;
}

// TODO: update component names to reflect user-facing terminology
export const screenConfigs: Record<string, ScreenConfig> = {
    emailSignUp: {
        url: '/start-web-trial',
        documentTitle: 'Start Rise Trial'
    },
    jobSelection: {
        url: '/goals',
        documentTitle: 'Sleep Goals'
    },
    selfReportedWeekday: {
        url: '/weekday-sleep',
        documentTitle: 'Weekday Sleep'
    },
    selfReportedFreeday: {
        url: '/weekend-sleep',
        documentTitle: 'Weekend Sleep'
    },
    selfReportedConfirmation: {
        url: '/sleep-confirmation',
        documentTitle: 'Sleep Confirmation'
    },
    sleepNeed: {
        url: '/sleep-need',
        documentTitle: 'Sleep Need'
    },
    sleepDebt: {
        url: '/sleep-debt',
        documentTitle: 'Sleep Debt'
    },
    energyProfile: {
        url: '/energy-schedule',
        documentTitle: 'Energy Schedule'
    },
    stripeConfirmation: {
        url: '/payment-confirmation',
        documentTitle: 'Payment Confirmation'
    },
    paywall: {
        url: '/web-paywall',
        documentTitle: 'Web Paywall'
    },
}

export const getDocumentTitleByUrl = (url: string): string => {
    const foundConfig = Object.values(screenConfigs).find(config => config.url === url);
    return foundConfig ? foundConfig.documentTitle : url;
}

const baseApiUrl = 'https://api.risesci.com'

export const apiUrls = {
    createAccount: `${baseApiUrl}/api/web-create-account`,
    sendJob: `${baseApiUrl}/api/profile-tag-entry`,
    calculateSelfReportedSleep: `${baseApiUrl}/api/sleep-profile/calculate-by-self-reported-sleep`,
    getStripeConfig: 'https://api.risesci.com/api/web-payments-configuration', // TODO: configure locally
    getCircadianCurve: `${baseApiUrl}/api/circadian-curve`
}

export enum ApiError {
    None,
    NetworkError,
    EmailTaken,
}

export const jobOptions = new Map([
    ['less_tired_during_day', 'Less exhausted during the day'],
    // not an unintentional mispelling - in the prod db
    ['chronice_health_issue', 'Chronic physical/mental health issue'],
    ['improve_productivity', 'Improve productivity'],
    ['wake_up_refreshed', 'Wake up refreshed'],
    ['reduce_anxiety', 'Reduce anxiety'],
    ['optimize_fitness', 'Optimize fitness'],
    ['understand_sleep', 'Understand my sleep patterns'],
    ['solve_sleep_disorder', 'Solve sleep disorder'],
]);

export const getLabelForSleepTime = (options: Map<number, string>, time: number | null) : string | undefined => {
    if (time === null) {
        return undefined
    }
    return options.get(time)
}

export const inBedOptions = new Map([
    [22, '9:30pm - 10:30pm'],
    [23, '10:30pm - 11:30pm'],
    [24, '11:30pm - 12:30am'],
    [25, '12:30am - 1:30am'],
    [26, '1:30am - 2:30am'],
    [27, 'After 2:30am'],
])

export const wakeUpOptions = new Map([
    [5, 'Before 5:30am'],
    [6, '5:30am - 6:30am'],
    [7, '6:30am - 7:30am'],
    [8, '7:30am - 8:30am'],
    [9, '8:30am - 9:30am'],
    [10, '9:30am - 10:30am'],
    [11, 'After 10:30am'],
])
