import * as Sentry from '@sentry/react';

import Analytics from './Analytics';
import Environment from './Environment';
import axios from 'axios';
import getRedirectUrls from './getRedirectUrls';
import getRedirectUrlsForNewSignUpTrial from './getRedirectUrlsForNewSignUpTrial';

export interface UserConfiguration {
  person_id: string;
  stripe_price: string;
  email: string;
  checkout_session_id?: string;
  success_url: string;
  cancel_url: string;
}
interface RequestParams {
  token: string;
  env: string;
  success_url: string;
  cancel_url: string;
  coupon?: string;
}


// token is an email, coupon can be null
export async function fetchUserConfiguration({
  token,
  coupon,
  newUser=false,
}: {
  token: string | null;
  coupon?: string;
  newUser: boolean;
}): Promise<UserConfiguration> {
  if (!token) {
      console.log('no token provided!')
    return Promise.reject('No token provided');
  }
  const env = Environment.getVar('REACT_APP_ENV') ?? test;

  return new Promise((resolve, reject) => {
    //Setup Sentry Identity
    Sentry.setUser({ email: token });
    let redirectUrls: {
        success: string,
        cancel: string
    }
    if(newUser){
        redirectUrls = getRedirectUrlsForNewSignUpTrial(token, coupon!);
    } else {
        redirectUrls = getRedirectUrls(token);
    }


    let params: RequestParams = {
      token,
      env,
      success_url: redirectUrls.success,
      cancel_url: redirectUrls.cancel,
    };
    if (coupon) {
      params.coupon = coupon;
    }
    axios
      .get('https://api.risesci.com/api/web-payments-configuration', {
        params,
      })

      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Status: ${res.status} ${res.statusText}`);
        }
        return res.data;
      })
      .then((config) => {
        //Update Sentry Identity
        Sentry.setUser({ email: token, ...config });
        //Setup Analytics Identity
        Analytics.identify(config.person_id, config);
        Analytics.track('Retrieved user config', config);

        resolve(config);
      })
      .catch((e) => {
        if (e) {
          Analytics.track('Error retrieving user config', {
            ...e,
            token,
          });
          Sentry.captureException(e);
        }
        return reject(e);
      });
  });
}
