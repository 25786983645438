import React, {ReactElement, useEffect} from "react";


import {StripeConfirmationView} from "./StripeConfirmationView";
import {useStore} from "../../../state/webOnboarding2";
import {withRedirectAndTitle} from "../withRedirectAndTitle";
import { format } from 'date-fns/fp'
import {screenConfigs} from "../../../utils/webOnboarding2/constants";


function StripeConfirmation(): ReactElement {

    const {
        email,
        reminderDate,
        setReminderDate,
    } =  useStore(state => ({
        email: state.email,
        reminderDate: state.reminderDate,
        setReminderDate: state.setReminderDate,
    }))

    useEffect(() => {
        if (useStore.getState().reminderDate == null) {
            const date = new Date()
            date.setDate(date.getDate() + 7);
            setReminderDate(format("MMMM do", date)) // i.e. August 10th
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <StripeConfirmationView email={email} reminderDate={reminderDate} />
    );
};

export default withRedirectAndTitle(StripeConfirmation, screenConfigs.stripeConfirmation.documentTitle);
