import logos from '../DynamicOfferScreen/recommended-logos.png'
import circleCheck from '../../images/circle_check.svg'
import React from 'react'
import './Checkout.css'
import { Offer } from '../../utils/getDiscountOffer'

type CheckoutInformationProps = {
  token: string | null
  offer: Offer | null
  chargeDate: string | null
}
const CheckoutInformation: React.FC<CheckoutInformationProps> = ({
  token,
  offer,
  chargeDate,
}) => {
  const isWinback = () => {
    const params = new URLSearchParams(window.location.search)
    return Boolean(params.get('winback'))
  }

  const lineThroughPrice = () => {
    if (token && offer) {
      if (offer.originalPrice) {
        return offer.originalPrice
      }
      return offer.billingInterval === 'year' ? '$59.99' : '$9.99'
    }
    if (offer) {
      return offer.billingInterval === 'year' ? '$89.99' : '$12.99'
    }
    return '$89.99'
  }

  const showLineThrough = () => {
    const originalPrice = lineThroughPrice()
    if (offer) {
      return originalPrice !== offer.price
    }
    return true
  }

  const monthlyPriceDisplay = () => {
    if (offer?.billingInterval === 'month') {
      return ''
    }
    if (offer?.perMonth) {
      return `(${offer.perMonth}/mo)`
    }
    return '($4.99/mo)'
  }

  const offerPriceDisplay = () => {
    if (offer?.price) {
      return offer.price
    }
    return '$59.99'
  }

  const billingIntervalDisplay = () => {
    if (offer?.billingInterval) {
      return offer.billingInterval.toUpperCase() + 'LY'
    }
    return 'YEARLY'
  }

  const chargeDateDisplay = () => {
    if (chargeDate) {
      return new Date(chargeDate).toLocaleDateString()
    }
    return ''
  }

  return (
    <>
      <p className="text-white text-xs pt-2 pb-0 z-10">Recommended by:</p>
      <div className="pb-4 pt-0 z-10" style={{ textAlign: 'center' }}>
        <img
          src={logos}
          alt=""
          className="inline pr-4"
          style={{ height: 50 }}
        />
      </div>
      <div className="z-10 header">
        <p className="text-white tryFree" style={{ fontSize: '2.2em' }}>
          {isWinback() ? 'Extend benefits' : 'Try FREE today'}
        </p>
        <p
          className="text-white font-semibold pl-2 pr-2"
          style={{
            fontSize: '2.2em',
            display: 'inline-block',
            backgroundColor: '#FD8206',
          }}
        >
          to rise easier
        </p>
        <br />
        <p
          className="text-white font-semibold pl-2 pr-2"
          style={{
            fontSize: '2.2em',
            display: 'inline-block',
            backgroundColor: '#FD8206',
          }}
        >
          tomorrow
        </p>
      </div>

      <div
        className="pt-4"
        style={{ visibility: offer ? 'visible' : 'hidden' }}
      >
        <p className="text-white">
          {!isWinback() ? (
            <>
              <span style={{ fontSize: '2em' }}>$0</span> DUE TODAY, THEN{' '}
              {showLineThrough() ? (
                <span className="line-through">{lineThroughPrice()}</span>
              ) : (
                ''
              )}{' '}
              {offerPriceDisplay()} BILLED {billingIntervalDisplay()}{' '}
              {monthlyPriceDisplay()}
            </>
          ) : (
            <>
              <span style={{ fontSize: '2em' }}>$0</span> DUE TODAY, THEN{' '}
              {offer?.discountPercentage} OFF AT {offer?.price} BILLED{' '}
              {billingIntervalDisplay()} {monthlyPriceDisplay()}
            </>
          )}
        </p>
      </div>
      <ul className="list-none mt-3 text-xs text-white">
        <li key={1} className="flex font-medium items-start pb-3">
          <img
            src={circleCheck}
            alt=""
            className="inline"
            style={{ height: 18, marginRight: 10 }}
          />
          {chargeDateDisplay()
            ? `You'll only be charged on ${chargeDateDisplay()} and reminded about your subscription before then`
            : "You'll be reminded before your trial ends"}
        </li>
        <li key={2} className="flex font-medium items-start pb-3">
          <img
            src={circleCheck}
            alt=""
            className="inline"
            style={{ height: 18, marginRight: 10 }}
          />
          Money back guarantee, easy 1 click cancel
        </li>
      </ul>
    </>
  )
}

export default CheckoutInformation
