import React, { ReactElement, useEffect } from 'react'
import Analytics from '../../utils/Analytics'
import { useMarketingSourceCodeFromParams } from '../../hooks/useMarketingSourceCodeFromParams'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import logo from '../../images/logo.svg'
import profile from '../../images/profile.svg'
import riseAppLockup from '../SignUpSuccess/riseAppLockup-new.png'
import appStore from '../SignUpSuccess/appStore.png'
import successRedirectInstructions from '../SignUpSuccess/successRedirectInstructions.png'
import playStore from '../SignUpSuccess/playStore.png'
import { useOffer, useQueryToken } from '../../utils/dynamicCodes/hooks'
import checkmark from '../../images/checkmark.svg'

function DynamicSuccess(): ReactElement {
  const email = useQueryToken()
  const code = useMarketingSourceCodeFromParams()
  const offer = useOffer(code)

  useEffect(() => {
    if (offer) {
      const params = new URLSearchParams(window.location.search)
      const report = params.get('report')
      if (report) {
        Analytics.onReady(() => {
          Analytics.track('Stripe Checkout Completed', { ...offer })
          // make sure a page reload does not send this event again
          window.history.pushState(
            {},
            'Rise',
            window.location.href.replace('&report=1', '')
          )
        })
      }
    }
  }, [offer])

  const isWinback = () => {
    return window.location.search.includes('chargeDate')
  }

  const isSurvey = () => {
    return window.location.search.includes('survey')
  }

  const isParent = () => {
    return window.location.search.includes('parent')
  }

  const chargeDateDisplay = () => {
    const params = new URLSearchParams(window.location.search)
    const chargeDate = params.get('chargeDate')
    if (chargeDate) {
      return new Date(chargeDate).toLocaleDateString()
    }
    return ''
  }

  const discountToShow = () => {
    if (offer) {
      if (offer.discountPercentage && offer.discountPercentage !== '0%') {
        return offer.discountPercentage
      }
      if (offer.fixedDiscount && offer.fixedDiscount !== '$0.00') {
        return offer.fixedDiscount
      }
      return `free ${offer.daysFree}-day`
    }
    return ''
  }

  const isPersonId = (value?: string | null) => value?.match(/^[0-9]+$/)

  return (
    <div className="w-full h-screen mx-auto" id="new-years-2021">
      <div className="mx-auto w-px-320 sm:w-px-320 md:w-px-640 relative z-10">
        <MaybeStagingBadge />
        <div className={'py-6 md:py-12'}>
          <img
            src={logo}
            alt="RISE"
            className="self-start"
            style={{ height: 16 }}
          />
        </div>
        <div
          className={`w-px-320 md:w-px-600 ${
            window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
          } sm:space-y-0 sm:pt-20`}
        >
          <div className="flex flex-col">
            <h1 className="text-white pb-3 text-3xl font-bold leading-tight">
              {isWinback()
                ? 'Your subscription has been extended! 🎉'
                : isParent()
                ? "Your teen's subscription is ready to use! 🎉"
                : 'Your subscription is active and ready to use! 🎉'}
            </h1>
            <div className="flex justify-end my-2  pb-2">
              <hr className="w-full border-t border-white opacity-25" />
            </div>
            {!offer ? null : offer?.whitelistStatus ? (
              <>
                {email ? (
                  <div
                    className={`opacity-0 text-white text-lg font-medium flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
                      email && 'opacity-100'
                    }`}
                  >
                    <img src={profile} alt="" className="inline mr-2 w-5" />
                    {email ?? '...'}
                  </div>
                ) : null}
                <p className="text-white text-sm">
                  Now, visit your app store and search "Rise Sleep" to download
                  the RISE app. Use this same email to get started in the app.
                  Email{' '}
                  <a href="mailto:support@risescience.com">
                    support@risescience.com
                  </a>{' '}
                  if you need help.
                </p>
              </>
            ) : isWinback() ? (
              <>
                <ul className="list-none">
                  <li key={2} className="text-white pb-3 flex items-start">
                    <img
                      src={checkmark}
                      alt=""
                      className="inline"
                      style={{ height: 25, marginRight: 10 }}
                    />
                    Enjoy longer access to sleep debt tracking, energy schedule
                    predictions, and new feature upgrades
                  </li>
                  <li key={1} className="text-white pb-3 flex items-start">
                    <img
                      src={checkmark}
                      alt=""
                      className="inline"
                      style={{ height: 25, marginRight: 10 }}
                    />
                    Your{' '}
                    <strong style={{ display: 'contents' }}>
                      {discountToShow()}
                    </strong>{' '}
                    discount has been applied and will only be charged on{' '}
                    {chargeDateDisplay()}
                  </li>
                </ul>
                <p className={'text-white pt-4'}>
                  Email{' '}
                  <a href="mailto:support@risescience.com">
                    support@risescience.com
                  </a>{' '}
                  if you need help.
                </p>
              </>
            ) : (
              <>
                <ul className="list-none">
                  <li key={2} className="text-white pb-3 flex items-start">
                    <img
                      src={checkmark}
                      alt=""
                      className="inline"
                      style={{ height: 25, marginRight: 10 }}
                    />
                    {isSurvey()
                      ? 'Open the RISE App'
                      : isParent()
                      ? "Open the RISE App (on your teen's device)"
                      : 'Download or open the RISE App'}
                  </li>
                  {!isPersonId(email) ? (
                    <li key={3} className="text-white pb-3 flex items-start">
                      <img
                        src={checkmark}
                        alt=""
                        className="inline"
                        style={{ height: 25, marginRight: 10 }}
                      />
                      Use&nbsp;
                      <strong style={{ display: 'contents' }}>
                        {email ? email : 'your email'}
                      </strong>{' '}
                      to log in
                    </li>
                  ) : null}
                  <li key={1} className="text-white pb-3 flex items-start">
                    <img
                      src={checkmark}
                      alt=""
                      className="inline"
                      style={{ height: 25, marginRight: 10 }}
                    />
                    Your{' '}
                    <strong style={{ display: 'contents' }}>
                      {discountToShow()}
                    </strong>{' '}
                    discount has been applied
                  </li>
                  <li key={4} className="text-white flex items-start">
                    <img
                      src={checkmark}
                      alt=""
                      className="inline"
                      style={{ height: 25, marginRight: 10 }}
                    />
                    {
                      'Manage your subscription anytime in the App by going to Profile > Subscriptions'
                    }
                  </li>
                </ul>
                <p className={'text-white pt-4'}>
                  Email{' '}
                  <a href="mailto:support@risescience.com">
                    support@risescience.com
                  </a>{' '}
                  if you need help.
                </p>
              </>
            )}
            <div className="flex justify-end my-4 pb-2 ">
              <hr className="w-full border-t border-white opacity-25" />
            </div>
          </div>
        </div>
        <div>
          <img
            src={riseAppLockup}
            alt="RISE: Sleep & Energy Tracker - Better Health, Energy & Focus"
            className="self-start mb-6"
            style={{ maxHeight: 83.5 }}
          />

          <div className="flex">
            <a
              href="https://apps.apple.com/us/app/rise-sleep-energy-tracker/id1453884781"
              onClick={() =>
                Analytics.track('Store Redirect Clicked', { store: 'appStore' })
              }
              className="block mr-4"
            >
              <img
                src={appStore}
                alt="Download on the App Store"
                className="self-start"
                style={{ height: 50 }}
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.risesci.nyx"
              onClick={() =>
                Analytics.track('Store Redirect Clicked', {
                  store: 'playStore',
                })
              }
              className="block"
            >
              <img
                src={playStore}
                alt="Get it on Google Play"
                className="self-start"
                style={{ height: 50 }}
              />
            </a>
          </div>

          {code === 'gympass' && (
            <div className={'py-6 md:py-12'}>
              <img
                src={successRedirectInstructions}
                alt="Redirect Instructions"
                className="self-start mb-6"
                style={{}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DynamicSuccess
