import React from 'react'

import bedCurve from '../assets/bed-curve.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const HealthSync: React.FC = () => {
  useSlideTrackEvt('HealthSync')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline ternary>Getting started</BrandHeadline>
        <LargeHeadline>Syncs securely with existing health data.</LargeHeadline>
        <BodyCopy>
          By connecting with Apple&apos;s HealthKit, RISE calculates your Sleep
          Debt and Circadian Rhythm starting on your first day. We use the
          world&apos;s leading biomathematical fatigue model, analyzing sleep
          data in order to accurately predict your energy.
        </BodyCopy>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img alt="Bed with energy curve" src={bedCurve} />
      </div>
    </DoubleColumnSlide>
  )
}
