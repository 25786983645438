import React from 'react'

import classNames from 'classnames'

import { SingleColumnSlide } from '../components'
import { useSlideTrackEvt } from '../hooks'

import './Testimonial.css'

/**
 * Users personality
 */
export enum Personality {
  EarlyRiser = 'Early Riser',
  NightOwl = 'Night Owl',
}

type TestimonialProps = {
  /** Users image */
  img?: string
  /** Name of person to attribute the testimonial */
  name: string
  /** The testimonial users personality */
  personality: Personality
}

/**
 * Presents a 5 star testimonial attributed to an individual
 */
export const Testimonial: React.FC<TestimonialProps> = (props) => {
  const { children, img, name, personality } = props
  useSlideTrackEvt(`Testimonial (${name})`)

  const personalityCls = classNames('ob3__testimonial__personality', {
    'ob3__testimonial__personality--early-riser':
      personality === Personality.EarlyRiser,
    'ob3__testimonial__personality--night-owl':
      personality === Personality.NightOwl,
  })

  return (
    <SingleColumnSlide>
      <div className="ob3__testimonial">
        <p className="ob3__testimonial__body">{children}</p>
        <div className="ob3__testimonial__user">
          {img !== undefined ? (
            <img
              alt="Testimonial user"
              className="ob3__testimonial__user__img"
              src={img}
              width="80"
            />
          ) : null}
          <div className="ob3__testimonial__user__details">
            <span className="ob3__testimonial__name">{name}</span>
            <span className={personalityCls}>{personality}</span>
          </div>
        </div>
      </div>
    </SingleColumnSlide>
  )
}
