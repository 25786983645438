import { addDays } from 'date-fns'
import React, { useEffect } from 'react'
import PillButton from '../../../components/PillButton'
import ActionButton from '../../../screens/WebOnboarding2/components/ActionButton'
import bellIcon from '../assets/bellIcon.svg'
import logo from '../assets/logo@3x.png'
import openOutsideIcon from '../assets/openOutsideIcon.svg'
import timeline from '../assets/timeline@3x.png'
import './EmailConfirmation.css'
import { useQueryToken } from '../../../utils/dynamicCodes/hooks'
import { useLocation } from 'react-router-dom'
import Analytics from '../../../utils/Analytics'
import Environment from '../../../utils/Environment'
import { Trans, useTranslation } from 'react-i18next'

export function useDaysFree() {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  if (params.get('days')) {
    return parseInt(decodeURIComponent(params.get('days') as string))
  }
  return 0
}

function EmailValidationTrialConfirmation() {
  const { t, i18n } = useTranslation()
  const daysFree = useDaysFree()
  const trialLengthDays = daysFree
  const trialEndDate = addDays(new Date(), trialLengthDays).toLocaleDateString(
    i18n.language,
    { month: 'long', day: 'numeric' }
  )
  const reminderDate = addDays(
    new Date(),
    trialLengthDays - 1
  ).toLocaleDateString(i18n.language, { month: 'long', day: 'numeric' })
  const emailAddress = useQueryToken()

  const handleContinue = () => {
    Analytics.track('Email Confirmation Finish Setup Clicked', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
    })
    window.location.href = 'nyx://'
  }

  useEffect(() => {
    Analytics.track('Email Confirmation Finish Setup Viewed', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: emailAddress,
    })
  }, [emailAddress])

  return (
    <div className="safe-full-screen w-screen overflow-hidden flex flex-col justify-between text-white">
      <div className="">
        <div className="flex flex-row items-center pt-10 pb-16">
          <img src={logo} alt={t('{{brand}}')} width={34} height={34} />
          <div className="ml-2">
            <div className="text-xl font-bold leading-none">
              {t('{{brand}}')}
            </div>
            <div className="text-xl font-bold text-yellow-2 leading-none">
              {t('confirmation_screen.title')}
            </div>
          </div>
        </div>
        <div className="flex flex-column relative mr-10 ml-2 text-xs leading-tight">
          <img
            src={timeline}
            alt=""
            width={20}
            className="timeline-image"
            style={{ marginTop: '5px' }}
          />
          <div>
            <div className="absolute left-8   " style={{ top: '0px' }}>
              <h2
                className="text-lg
              "
              >
                {t('confirmation_screen.step_1_title')}
              </h2>
              {t('confirmation_screen.step_1_subtitle')}
              <div className="pt-2">
                <PillButton
                  title={t('common.finish_setup')}
                  onPress={handleContinue}
                  icon={openOutsideIcon}
                />
              </div>
            </div>
            <div className="absolute left-8  " style={{ top: '92px' }}>
              <h2 className="text-lg ">
                {t('confirmation_screen.step_2_title')}
              </h2>
              {t('confirmation_screen.step_2_subtitle')}
            </div>
            <div className="absolute left-8" style={{ top: '172px' }}>
              <h2 className="text-lg">
                {reminderDate}
                <img
                  src={bellIcon}
                  alt="bell"
                  className="w-5 h-5 ml-2 inline"
                />
              </h2>
              <Trans
                components={{ highlight: <span className="text-yellow-2" /> }}
                i18nKey="confirmation_screen.step_3_subtitle"
                values={{ emailAddress }}
              />
            </div>
            <div className="absolute left-8 " style={{ top: '265px' }}>
              <h2 className="text-xl ">{trialEndDate}</h2>{' '}
              {t('confirmation_screen.step_4_subtitle')}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="p-2">
          <ActionButton
            icon={openOutsideIcon}
            title={t('common.FINISH_SETUP')}
            onClick={handleContinue}
          />

          <div className="my-2 text-center text-sm text-purple-2 font-bold">
            <a href="mailto:support@risescience.com">
              {t('common.Contact_us')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailValidationTrialConfirmation
