import React from "react";

interface RadioButtonProps {
  jobValue: string | number;
  jobLabel: string;
  key: number;
  jobSelected: string | number | null;
  jobOption: string;
}
const RadioButton = ({
  jobValue,
  jobLabel,
  key,
  jobSelected,
  jobOption,
}: RadioButtonProps) => {
  return (
    <label
      className={`flex flex-row py-3 justify-between border-t border-panelGray-2 px-3 text-white`}
      key={key}
      id={jobValue as string}
      htmlFor={jobLabel}
    >
      {jobLabel}
        <input
          id={jobLabel}
          className="form-radio object-right"
          type="radio"
          name={jobOption}
          value={jobValue}
          checked={jobValue === jobSelected}
        />
    </label>
  );
};

export default RadioButton;
