import React from 'react'

import classNames from 'classnames'

import backArrow from './back-arrow.png'
import backArrowDisabled from './back-arrow-disabled.png'

import './Footer.css'

type FooterProps = {
  /** Boolean indicating if the user can navigate backward */
  canMoveBackward: boolean
  /** Handler called when the user clicks the back button */
  onMoveBackClick: () => void
  /** Handler called when the user clicks the next button  */
  onMoveForwardClick: () => void
}

export const Footer: React.FC<FooterProps> = (props) => {
  const { canMoveBackward, onMoveBackClick, onMoveForwardClick } = props

  const prevBtnCls = classNames('ob3__layout-grid__footer__btn', {
    'ob3__layout-grid__footer__btn--outline--disabled': !canMoveBackward,
  })

  return (
    <footer className="ob3__layout-grid__footer">
      <div className="ob3__layout-grid__footer__row">
        <button
          disabled={!canMoveBackward}
          className={prevBtnCls}
          onClick={onMoveBackClick}
          style={{
            padding: '12px 16px',
          }}
        >
          <img
            src={canMoveBackward ? backArrow : backArrowDisabled}
            alt="Back"
          />
        </button>
        <button
          className="ob3__layout-grid__footer__btn ob3__layout-grid__footer__btn--solid"
          onClick={onMoveForwardClick}
          style={{ width: '120px' }}
        >
          NEXT
        </button>
      </div>
    </footer>
  )
}
