import * as Sentry from '@sentry/react';

import React, { useEffect, useState } from 'react';
import {
    UserConfiguration,
    fetchUserConfiguration,
} from '../../utils/fetchUserConfiguration';

import Analytics from '../../utils/Analytics';
import Background from '../../components/Background';
import ButtonError from '../../components/ButtonError';
import ButtonProcessing from '../../components/ButtonProcessing';
import ButtonRedeem from '../../components/ButtonRedeem';
import { ErrorMessage } from '../../utils/ErrorMessage';
import List from '../../components/List';
import MaybeStagingBadge from '../../components/MaybeStagingBadge';
import getDiscountOffer from '../../utils/getDiscountOffer';
import logo from '../../images/logo.svg';
import profile from '../../images/profile.svg';
import { redirectToStripe } from '../../utils/redirectToStripe';
import stopwatch from '../../images/stopwatch.svg';
import stripeLogo from '../../images/stripe.png';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useTokenFromParams } from '../../hooks/useTokenFromParams';

const listItems = [
    'Predict daily energy levels',
    'Manage your Sleep Debt',
    '16 scientifically proven habits',
    'Automatically track sleep',
];

type UiState = 'loading' | 'ready' | 'processing' | 'error';
const BlackFriday2021 = () => {
    const [userConfig, setUserConfig] = useState<UserConfiguration | null>(null);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<ErrorMessage | null>(null);
    const token = useTokenFromParams();
    const offer = getDiscountOffer('black_friday_2020');

    useDocumentTitle('Black Friday 2021 | Rise Thanks You');

    const uiState: UiState = ((): UiState => {
        if (error) return 'error';
        if (userConfig && !processing) return 'ready';
        if (!userConfig) return 'loading';
        if (processing) return 'processing';
        return 'ready';
    })();

    useEffect(() => {
        if (uiState === 'ready') {
            Analytics.track('Redeem offer shown', {
                stripe_price: userConfig?.stripe_price,
            });
            Analytics.track('Black Friday 2021 Page Viewed');
        }
    }, [uiState, userConfig?.stripe_price]);

    useEffect(() => {
        if (error) {
            Sentry.captureException(new Error(error));
        }
    }, [error]);

    async function redirectToStripeCheckout() {
        if (!userConfig) return;
        setProcessing(true);
        try {
            Analytics.track('Redeem offer clicked', {
                stripe_price: userConfig.stripe_price,
            });
            await redirectToStripe(userConfig);
        } catch (e) {
            setProcessing(false);
            setError(e);
        }
    }

    useEffect(() => {
        if (!token) {
            setError(ErrorMessage.NoToken);
            return;
        }

        fetchUserConfiguration({ token, coupon: offer.coupon, newUser: false})
            .then((config) => {
                setUserConfig(config);
            })
            .catch((e) => {
                setError(ErrorMessage.FetchingConfiguration);
            });
    }, [offer.coupon, token]);

    return (
        <div className="w-full h-screen mx-auto" id="black-friday-2021">
            <Background />

            <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
                <MaybeStagingBadge />
                <div
                    className={`w-px-320 ${
                        window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
                    } sm:space-y-0 sm:pt-48`}
                >
                    <div
                        className={`opacity-0 border-b text-shadow border-white border-opacity-25 text-white flex items-center py-2 transition-opacity duration-500 ease-in-out' ${
                            token && 'opacity-100'
                        }`}
                        style={{ textShadow: '1px 1px 5px  #000' }}
                    >
                        <img src={profile} alt="" className="inline mr-2 w-5" />
                        {token ?? '...'}
                    </div>

                    <div className="pt-8 flex flex-col">
                        <div style={{ height: 20 }}>
                            <img src={logo} alt="RISE" className="self-start" />
                        </div>
                        <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                            Black Friday 2021 <br />
                            {offer.discountPercentage} off
                        </h1>

                        <List items={listItems} />

                        <div className="flex justify-end pt-4 pb-2 relative">
                            <hr
                                className="absolute left-0 right-0  border-t border-white opacity-25"
                                style={{ top: 28 }}
                            />
                            <div
                                className="relative badge px-2 py-0 border-0 bg-gradient-to-tr from-purple-1 to-orange-1 flex items-center"
                                style={{ height: 24 }}
                            >
                                <img
                                    src={stopwatch}
                                    alt=""
                                    className="inline-block mr-1 -mt-1"
                                    style={{ width: 13 }}
                                />
                                THIS WEEK ONLY
                            </div>
                        </div>
                        <div className="text-white pb-3">
                            <p className="font-light text-gray-3">Subscribe to RISE</p>
                            <p>
                <span className="text-2xl font-medium line-through text-gray-3">
                  {offer.originalPrice}
                </span>{' '}
                                <span className="text-2xl font-medium  text-white">
                  {offer.price}
                </span>
                                <span> per year</span>
                            </p>
                            <p className="font-light text-gray-3">Money-back guarantee</p>
                        </div>
                        {uiState === 'error' && (
                            <ButtonError
                                testId="errorButton"
                                title={error ? error.toUpperCase() : 'Error processing request'}
                            />
                        )}
                        {uiState === 'loading' && (
                            <ButtonProcessing
                                testId="retrievingButton"
                                title="RETRIEVING YOUR OFFER..."
                            />
                        )}
                        {uiState === 'ready' && (
                            <ButtonRedeem
                                testId="redeemButton"
                                title="REDEEM MY OFFER"
                                onClick={redirectToStripeCheckout}
                            />
                        )}
                        {uiState === 'processing' && (
                            <ButtonProcessing
                                testId="processingButton"
                                title="PROCESSING..."
                            />
                        )}
                        <div className="pt-8 flex justify-end">
                            <img
                                src={stripeLogo}
                                style={{ width: 150, height: 34 }}
                                alt="Powered by Stripe"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlackFriday2021;
