import React from 'react'
import './ListItem.css'

type Props = {
  Icon: React.ReactNode
  text: string
}

function ListItem({ Icon, text }: Props) {
  return (
    <div className="list-item">
      <div className="icon">{Icon}</div>
      <p>{text}</p>
    </div>
  )
}

export default ListItem
