import React from 'react'
import './GradientTrialBox.css'
import { Trans, useTranslation } from 'react-i18next'
import { SupportedCountryCode, countryCodeToCurrency, formatCurrency } from '../../../utils/currency'

type Props = {
  locale: SupportedCountryCode
  price: number
  introPrice: number
}

function GradientTrialBox({ locale, price, introPrice }: Props) {
  const { t } = useTranslation()
  const currency = countryCodeToCurrency(locale)
  const perWeekPrice = price / 52
  const perWeekPriceRounded = Math.round(perWeekPrice)
  const perWeekTranslationKey =
    perWeekPriceRounded > perWeekPrice
      ? 'choose_your_price_checkout_screen.per_week_price_less'
      : 'choose_your_price_checkout_screen.per_week_price_over'
  const introPriceString = formatCurrency({
    value: introPrice,
    countryCode: locale,
    currency,
    minimumFractionDigits: 0,
  })
  const annualPriceString = formatCurrency({
    value: price,
    countryCode: locale,
    currency,
    minimumFractionDigits: 2,
  })
  return (
    <div className="gradient-trial-box">
      <div className="gradient-trial-box-title">
        {t('choose_your_price_checkout_screen.n_day_trial', { daysFree: 30 })}
      </div>
      <div className="gradient-trial-box-subtitle">
        <span>
          <Trans
            i18nKey="choose_your_price_checkout_screen.pricing_text_paid_trial_choose_your_price"
            values={{
              introPriceString,
              annualPriceString,
            }}
            components={{
              strong: <strong />,
            }}
          />
        </span>
        <p>
          {t(perWeekTranslationKey, {
            pricePerWeek: formatCurrency({
              value: perWeekPriceRounded,
              countryCode: locale,
              currency,
              minimumFractionDigits: 0,
            }),
          })}
        </p>
      </div>
    </div>
  )
}

export default GradientTrialBox
