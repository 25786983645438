import React, { ReactElement } from 'react';
import checkmark from '../images/checkmark.svg';

interface Props {
  items: string[];
}

function List({ items }: Props): ReactElement {
  return (
    <ul className="list-none">
      {items.map((item, index) => {
        return (
          <li key={index} className="feature-list-item h-8">
            <img src={checkmark} alt="" className="inline w-8" />
            {item}
          </li>
        );
      })}
    </ul>
  );
}

export default List;
