import React, { ReactElement, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { SleepNeedView } from "./SleepNeedView";
import { useStore } from "../../../state/webOnboarding2";
import { withRedirectAndTitle } from "../withRedirectAndTitle";
import { screenConfigs } from "../../../utils/webOnboarding2/constants";
import {
  minutesToHoursAndMinutes,
} from "../../../utils/webOnboarding2/formatting";
import { sendAnalytics } from "../../../utils/webOnboarding2/webOnboardingAnalytics";

function SleepNeed(): ReactElement {
  const history = useHistory();

  const { sleepNeed } = useStore((state) => ({
    sleepNeed: state.sleepNeed,
  }));

  useEffect(() => {
    sendAnalytics('Sleep need Shown', { sleepNeed })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sleepNeedString: string = minutesToHoursAndMinutes(sleepNeed);
  const userSleepNeed = Number(sleepNeed);

  return (
    <SleepNeedView
      sleepNeed={sleepNeedString}
      sleepNeedDecimal={userSleepNeed}
      onClick={() => history.push(screenConfigs.sleepDebt.url)}
    />
  );
}

export default withRedirectAndTitle(SleepNeed, screenConfigs.sleepNeed.documentTitle);
