import React from 'react'

import EmailConfirmationCheckoutScreen, {
  EmailConfirmationCheckoutScreenProps,
} from './EmailConfirmationCheckoutScreen'

const StudentEmailConfirmationCheckoutScreen = (
  props: EmailConfirmationCheckoutScreenProps
) => {
  const { initialOption, otherOption } = props

  return (
    <EmailConfirmationCheckoutScreen
      initialOption={initialOption}
      otherOption={otherOption}
      isStudent={true}
    />
  )
}

export default StudentEmailConfirmationCheckoutScreen
