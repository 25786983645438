import React, { ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../state/webOnboarding2";
import { screenConfigs } from "../../../utils/webOnboarding2/constants";
import { withRedirectAndTitle } from "../withRedirectAndTitle";
import { SleepDebtView } from "./SleepDebtView";
import { sendAnalytics } from "../../../utils/webOnboarding2/webOnboardingAnalytics";


export type UiState = "calculating" | "finished";

function SleepDebt(): ReactElement {
  const history = useHistory();

  const { debtMinutes } = useStore();

  useEffect(() => {
    sendAnalytics('Sleep debt Shown', { debtMinutes })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Since Sleep Debt comes back as part of the self reported payload, we don't really need to load anything
  // unless we want some "fake work"
  const uiState =  'finished'

  return (
    <SleepDebtView
      sleepDebtMinutes={debtMinutes}
      uiState={uiState}
      onClickViewEnergy={() => history.push(screenConfigs.energyProfile.url)}
    />
  );
}

export default withRedirectAndTitle(SleepDebt, screenConfigs.sleepDebt.documentTitle);
