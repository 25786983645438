import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import './Modal.css'

type ModalProps = {
  /** Content to render within the modal */
  children: React.ReactNode
}

/**
 * Render content into a Modal using portals
 */
export const Modal: React.FC<ModalProps> = (props) => {
  const { children } = props
  const el = useRef(document.createElement('div'))

  useEffect(() => {
    const currentEl = el.current
    document.querySelector('#modal')?.appendChild(currentEl)
    document.body.classList.add('no-scroll')

    return () => {
      document.querySelector('#modal')?.removeChild(currentEl)
      document.body.classList.remove('no-scroll')
    }
  }, [])

  return ReactDOM.createPortal(
    <div className="modal__overlay">
      <div className="modal_content">{children}</div>
    </div>,
    el.current
  )
}
