import { useLocation } from 'react-router-dom';

export function useTokenFromParams() {
  const l = useLocation();
  const params = new URLSearchParams(l.search);
  // TODO: if '+' in params, it will be encoded here as a space
  return params.get('token') || params.get('user_id');
}

export function useSourceFromParams() {
  const l = useLocation();
  const params = new URLSearchParams(l.search);
  return params.get('source');
}
