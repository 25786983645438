import React from 'react'

/**
 * <div/> wrapped to quickly center content
 */
export const Center: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const { style, ...rest } = props
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', ...style }}
      {...rest}
    />
  )
}
