import React, { ReactElement } from 'react';

interface Props {
  title: string;
  testId: string;
  className?: string;
}

function ButtonError({ title, testId, className }: Props): ReactElement {
  return (
    <button
      data-test-id={testId}
      disabled
      className={
        'btn cursor-not-allowed opacity-50 text-sm font-medium tracking-widest hover:border-opacity-0 bg-gray-2 border-opacity-0 h-16'
      }
    >
      {title}
    </button>
  );
}

export default ButtonError;
