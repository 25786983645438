import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BASE_URL, useQueryToken } from '../../utils/dynamicCodes/hooks'
import axios, { AxiosError } from 'axios'
import validateEmail from '../../utils/validateEmail'
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js'
import { stripeStyle } from './StripeElementProvider'
import ButtonProcessing from '../../components/ButtonProcessing'
import Analytics from '../../utils/Analytics'
import MarketingEvents from '../../utils/MarketingEvents'
import Cookies from 'js-cookie'
import Environment from '../../utils/Environment'
import {
  redirectToSuccess,
  reportRepeatCheckout,
  UserAlreadySubscribedMessage,
} from '../../utils/dynamicCodes/redirects'
import * as Sentry from '@sentry/react'
import { Scroll } from '../../components'
import i18n from '../../i18n/i18n'
import { useTranslation } from 'react-i18next'

const sendsToSentryIfUncommon = (error: any) => {
  if (error.response?.data === UserAlreadySubscribedMessage) {
    return
  }
  if (error.response?.data?.error !== 'Email already taken.') {
    return
  }
  Sentry.captureException(new Error(error))
}

const completeCheckout = async (
  /**  Dynamic Checkout is used for our iOS/Stripe Applications
   *    - does things like apply tax and other...things
   * */
  isDynamicCheckout: boolean,
  code: string,
  token: string,
  email: string,
  payment_method_id: string
) => {
  const dynamicCheckoutEndpoint = `${BASE_URL}/api/stripe-dynamic-checkout`
  const standardCheckoutEndpoint = `${BASE_URL}/api/stripe-complete-checkout`
  const endpoint = isDynamicCheckout
    ? dynamicCheckoutEndpoint
    : standardCheckoutEndpoint

  Analytics.track('Attempting to complete custom Stripe checkout', { code })
  try {
    await axios.post(endpoint, {
      token,
      payment_method_id,
      email,
      code,
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
    })
    Analytics.track('Stripe Checkout Completed', { code })
    return { error: null, success: true }
  } catch (error) {
    sendsToSentryIfUncommon(error)
    Analytics.track('Failed to complete custom Stripe checkout', {
      error,
      code,
    })
    return { error: error as AxiosError, success: false }
  }
}

const buttonText = (isWinback: boolean, daysFree: number | undefined) => {
  if (isWinback) {
    return i18n.t('checkout_screen.redeem_offer')
  }
  if (daysFree) {
    if (daysFree === 7) {
      return i18n.t('checkout_screen.redeem_1_week_free')
    }
    return i18n.t('checkout_screen.redeem_x_days_free', {
      daysFree,
    })
  }
  return i18n.t('checkout_screen.redeem_free_week')
}

const isWinback = () => {
  const params = new URLSearchParams(window.location.search)
  return Boolean(params.get('winback'))
}

const isSurvey = () => {
  const params = new URLSearchParams(window.location.search)
  return Boolean(params.get('survey'))
}

const isParent = () => {
  const params = new URLSearchParams(window.location.search)
  return Boolean(params.get('parent'))
}

export interface PaymentIntentParams {
  code: string
  paymentRequest?: PaymentRequest
  clientSecret?: string
  token?: string | null
  daysFree?: number
  chargeDate?: string | null
  survey?: boolean
  parent?: boolean
  ctaText?: string
  dynamicCheckout?: boolean //use this for iOS/Stripe Applications - add does other stuff like applies tax and :hand wavy: things.
  onPurchaseSuccess?: ({
    existingAccount,
  }: {
    existingAccount: boolean
  }) => void
  onPurchaseFailure?: () => void
  paymentRequestLoading?: boolean
  onCreditCardChoice?: () => void
}

const PaymentIntentScreen = ({
  code,
  paymentRequest,
  clientSecret,
  daysFree,
  chargeDate,
  ctaText,
  dynamicCheckout,
  onPurchaseSuccess,
  onPurchaseFailure,
  paymentRequestLoading,
  onCreditCardChoice,
}: PaymentIntentParams) => {
  const { t } = useTranslation()
  const [shouldAskForEmail, setShouldAskForEmail] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [accountCreationError, setAccountCreationError] =
    useState<boolean>(false)
  const [stripeError, setStripeError] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)

  const createdAccount = useRef(false)
  const [accountCreationEmail, setAccountCreationEmail] = useState<
    string | null
  >(null)

  const [showCreditCardInput, setShowCreditCardInput] = useState<boolean>(false)

  const elements = useElements()
  const stripe = useStripe()
  let token = useQueryToken()

  const el = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (token) {
      // this is doing double duty - checking if token is invalid and/or a phone number
      const isValid = validateEmail(token)
      setShouldAskForEmail(!isValid)
    } else {
      setShouldAskForEmail(true)
    }
  }, [token])

  const createAccount = useCallback(
    async (email: string) => {
      if (createdAccount.current && email === accountCreationEmail) {
        Analytics.track('Skipping account creation with identical email', {
          email,
          code,
        })
        return { error: null, success: true }
      }

      try {
        const response = await axios.post(
          `${BASE_URL}/api/web-create-account`,
          {
            signUpSource: 'web',
            email,
            marketingSource: {
              code,
            },
          }
        )
        if (response.status !== 200) {
          throw new Error(`Status: ${response.status} ${response.statusText}`)
        }
        createdAccount.current = true
        setAccountCreationEmail(email)
        const currentTimeMs = Math.floor(Date.now())
        const { person_id: personId } = response.data

        Analytics.identify(personId, {
          email,
          marketingSourceCode: code,
        })

        Analytics.track('Web Sign Up Success', {
          marketingSourceCode: code,
        })

        const eventId = `sign_up_${personId}_${currentTimeMs}`

        MarketingEvents.sendMarketingEvent(
          MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
          {},
          { eventID: eventId }
        )
        MarketingEvents.sendConversionAPIEvent(
          MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
          eventId,
          {
            em: email,
            external_id: `${personId}`,
            fbp: Cookies.get('_fbp'),
            // fbc: Cookies.get('_fbc')
            // what about fbc? we don't appear to be handling this
          }
        )
        return { error: null, success: true }
      } catch (error) {
        // An error here is likely from using an email address already in our system
        sendsToSentryIfUncommon(error)
        Analytics.track('Web Sign Up Error', {
          error,
          marketingSourceCode: code,
          email,
        })
        setAccountCreationError(true)
        return { error, success: false }
      }
    },
    [accountCreationEmail, code]
  )

  // Apple Pay Handler: If user can use Apple/Google Pay, set up the payment request handler
  useEffect(() => {
    const handler = async (event: PaymentRequestPaymentMethodEvent) => {
      Analytics.track(`Attempt to use ${event.walletName}`, { code })
      // this is utilized by the Apple/Google Play flow - creates an account if necessary and validates the Payment Method.
      // Creates a Subscription via Hypnos before redirecting to our generic success page - DynamicSuccess

      const { paymentMethod, payerEmail } = event

      let personIdentifier = token

      if (personIdentifier == null && payerEmail) {
        const { error } = await createAccount(payerEmail)
        if (error) {
          setAccountCreationError(true)
          Analytics.track(`Failed to use ${event.walletName}`, { code })
          event.complete('fail')
          return
        } else {
          personIdentifier = payerEmail
        }
      }

      if (!personIdentifier) {
        Analytics.track(`Failed to use ${event.walletName}`, {
          error: 'Token unexpectedly null',
          code,
        })
        sendsToSentryIfUncommon('Token unexpectedly null during checkout')
        event.complete('fail')
        return
      }

      const { error } = await completeCheckout(
        dynamicCheckout ?? false,
        code,
        personIdentifier,
        payerEmail ?? '', // email should be '' when going through Apple Pay / Google Pay
        paymentMethod.id
      )

      if (error == null) {
        Analytics.track(`Successfully used ${event.walletName}`, { code })
        event.complete('success')
        if (onPurchaseSuccess) {
          onPurchaseSuccess && onPurchaseSuccess({ existingAccount: false })
        } else {
          window.location.href = `${
            document.location.origin
          }/offer/success?token=${encodeURIComponent(
            personIdentifier
          )}&code=${code}${isWinback() ? `&chargeDate=${chargeDate}` : ''}${
            isSurvey() ? `&survey=1` : ''
          }${isParent() ? `&parent=1` : ''}`
        }
      } else if (error.response?.data === UserAlreadySubscribedMessage) {
        reportRepeatCheckout(code, personIdentifier)
        if (onPurchaseSuccess) {
          onPurchaseSuccess && onPurchaseSuccess({ existingAccount: true })
        } else {
          redirectToSuccess(code, personIdentifier)
        }
      } else {
        Analytics.track(`Failed to use ${event.walletName}`, { error, code })
        event.complete('fail')
        onPurchaseFailure && onPurchaseFailure()
      }
    }

    // Listen for event to handle Apple/Google Pay
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', handler)
    }

    return () => {
      // Unsubscribe from the event when the useEffect is re-rendered
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', handler)
      }
    }
  }, [
    chargeDate,
    code,
    createAccount,
    dynamicCheckout,
    onPurchaseFailure,
    onPurchaseSuccess,
    paymentRequest,
    token,
  ])

  const confirmPayment = async (event: any) => {
    // this is utilized by the CC form flow - creates an account if necessary and validates the Setup Intent..
    // Creates a Subscription via Hypnos before redirecting to our generic success page - DynamicSuccess
    setProcessing(true)
    event.preventDefault()

    Analytics.track(`Attempt to use credit card`, { code })

    if (stripe === null || elements === null || clientSecret === undefined) {
      setProcessing(false)
      return
    }

    const card = elements.getElement(CardElement)
    if (card === null) {
      setProcessing(false)
      return
    }

    let personIdentifier = token

    const needToCreateAccount = email && personIdentifier == null

    if (needToCreateAccount) {
      // If user has already gone through flow, but encounters a Stripe issue, skip Account Creation
      const { error } = await createAccount(email)
      if (error) {
        setAccountCreationError(true)
        setProcessing(false)
        Analytics.track(`Failed to use ${event.walletName}`, { error, code })
        return
      } else {
        personIdentifier = email
      }
    }

    if (!personIdentifier) {
      setProcessing(false)
      return
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card,
      },
    })

    if (!result.error && result.setupIntent?.payment_method) {
      const { error } = await completeCheckout(
        dynamicCheckout ?? false,
        code,
        personIdentifier,
        email,
        result.setupIntent.payment_method as string
      )
      if (!error) {
        if (onPurchaseSuccess) {
          onPurchaseSuccess && onPurchaseSuccess({ existingAccount: false })
        } else {
          window.location.href = `${
            document.location.origin
          }/offer/success?token=${encodeURIComponent(
            personIdentifier
          )}&code=${code}${isWinback() ? `&chargeDate=${chargeDate}` : ''}${
            isSurvey() ? `&survey=1` : ''
          }${isParent() ? `&parent=1` : ''}`
        }
      } else if (error.response?.data === UserAlreadySubscribedMessage) {
        reportRepeatCheckout(code, email)
        if (onPurchaseSuccess) {
          onPurchaseSuccess && onPurchaseSuccess({ existingAccount: true })
        } else {
          redirectToSuccess(code, email)
        }
      } else {
        Analytics.track(`Failed to use credit card`, { error, code })
        setStripeError('Could not complete checkout')
        onPurchaseFailure && onPurchaseFailure()
      }
    } else if (result.error) {
      setProcessing(false)
      Analytics.track(`Failed to use credit card`, {
        error: result.error,
        code,
      })
      // This point will only be reached if there is an immediate error when
      // confirming the payment - for example, payment details incomplete
      if (result.error.message) {
        setStripeError(result.error.message)
      } else {
        setStripeError('Could not complete checkout')
      }
    } else {
      Analytics.track(`Failed to use credit card`, { code })
    }
    setProcessing(false)
  }

  if (paymentRequestLoading) {
    return null
  }

  return (
    <>
      <Scroll
        el={el}
        onElReached={() => Analytics.track('Stripe Form Scrolled into View')}
      >
        {paymentRequest ? (
          <div className="mt-4">
            {accountCreationError && (
              <text className="text-red-1 text-sm font-bold">
                {t('common.this_email_is_not_available')}
              </text>
            )}
            <PaymentRequestButtonElement
              options={{
                style: {
                  paymentRequestButton: {
                    type: 'subscribe',
                    theme: 'light',
                  },
                },
                paymentRequest,
              }}
            />

            <p
              className="text-white text-xs mt-2"
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                onClick={() => {
                  setShowCreditCardInput(true)
                  onCreditCardChoice && onCreditCardChoice()
                }}
              >
                <p className="underline">{t('common.or_use_a_credit_card')}</p>
              </button>
            </p>
          </div>
        ) : null}
        <form
          className={`${paymentRequest ? '' : 'mt-3'}`}
          onSubmit={confirmPayment}
        >
          {shouldAskForEmail ? (
            <>
              {accountCreationError && !paymentRequest && (
                <text className="text-red-1 text-sm font-bold">
                  {t('common.this_email_is_not_available')}
                </text>
              )}
              <input
                disabled={stripeError.length > 0}
                className="mt-2 text-white email-input"
                style={{
                  width: '100%',
                  backgroundColor: '#30313d',
                  border: '1px solid #4b4d5e',
                  fontWeight: 400,
                  height: '48px',
                  fontFamily:
                    'Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                  ...stripeStyle,
                }}
                placeholder={t('common.Email')}
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setAccountCreationError(false)
                }}
                onFocus={() => {
                  Analytics.track('Email Field Focused')
                }}
                ref={(ref) => (el.current = ref)}
              />
            </>
          ) : null}
          {(shouldAskForEmail || showCreditCardInput || !paymentRequest) &&
          !paymentRequestLoading ? (
            <div
              className="mt-2 mb-2 border-solid"
              style={{
                border: '1px solid #4b4d5e',
                borderRadius: '4px',
                backgroundColor: '#30313d',
                padding: '1.5px',
                paddingLeft: '12px',
              }}
            >
              <CardElement
                options={{
                  iconStyle: 'solid',
                  style: {
                    base: {
                      lineHeight: '3em',
                      backgroundColor: '#30313d',
                      iconColor: '#c4f0ff',
                      color: '#fff',
                      fontWeight: 300,
                      '::placeholder': {
                        color: '#fff',
                      },
                    },
                    invalid: {
                      iconColor: '#FFC7EE',
                      color: '#FFC7EE',
                    },
                  },
                }}
                onFocus={() => {
                  Analytics.track('Credit Card Form Focused')
                }}
              />
            </div>
          ) : null}

          {stripeError.length > 0 ? (
            <text className="text-red-1 text-sm">{stripeError}</text>
          ) : null}

          {(shouldAskForEmail || showCreditCardInput || !paymentRequest) &&
          !paymentRequestLoading ? (
            processing ? (
              <ButtonProcessing
                className={'max-w-full'}
                testId="processingButton"
                title={t('common.processing')}
              />
            ) : (
              <button
                id="submit"
                disabled={shouldAskForEmail && !validateEmail(email)}
                className={`mb-2 btn max-w-full  transition-opacity duration-500 ease-in-out h-16 ${
                  shouldAskForEmail && !validateEmail(email)
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }`}
              >
                {ctaText ?? buttonText(isWinback(), daysFree)}
              </button>
            )
          ) : null}
        </form>
      </Scroll>
    </>
  )
}

export default PaymentIntentScreen
