import React, { ReactElement, useEffect } from 'react';

import logo from '../../images/logo.svg';
import riseR from '../../images/R.svg';
import linkedIn from '../../images/LinkedIn.svg';
import twitter from '../../images/Twitter.svg';
import email from '../../images/email.svg';
import message from '../../images/message.svg';

import { useTokenFromParams } from '../../hooks/useTokenFromParams';

import AppSMSForm from '../../components/AppSMSForm';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Analytics from '../../utils/Analytics';

function ThankYou(): ReactElement {
  const token = useTokenFromParams();
  useDocumentTitle('Get a Download Link | Rise Thanks You');
  useEffect(() => {
    if (token) {
      Analytics.identify(token, { token });
    }
    Analytics.track('Thank You viewed', {
      token,
    });
  }, [token]);
  return (
    <div className="w-full h-screen mx-auto">
      <div className="mx-auto h-full max-w-3xl px-8 flex flex-col justify-between ">
        <div>
          <div className="flex justify-between py-4 border-b border-white border-opacity-25">
            <img
              src={logo}
              alt="RISE"
              className="self-start"
              style={{ width: 66 }}
            />
            <a
              onClick={() => {
                Analytics.track('Get Rise clicked');
              }}
              href="https://apps.apple.com/us/app/rise-track-sleep-circadian/id1453884781"
              className="order border-2 border-purple-1 max-w-xs bg-black px-5 py-1 text-white rounded-full font-medium text-sm tracking-wide"
            >
              GET RISE
            </a>
          </div>
          <div className="pt-24 max-w-lg">
            <h1 className="font-bold text-white text-4xl pb-8">Thank you!</h1>
            <div className="space-y-10">
              <div className="font-light text-white text-opacity-75">
                {token && (
                  <div className="pb-1 font-medium text-2xl text-white flex">
                    <img src={email} alt="" className="inline-block mr-2" />
                    {token}
                  </div>
                )}
                An email with your subscription confirmation and download link
                are on the way to you. Please check your spam folder to see if
                the email was routed there.
              </div>
              <div className="font-light text-white text-opacity-75">
                <div className="pb-1 font-medium text-2xl text-white flex">
                  <img src={message} alt="" className="inline-block mr-2" />
                  Download now
                </div>
                Enter your mobile number and we’ll send a one-time sms to
                download RISE on your iPhone.
              </div>
            </div>
            <div className="sm:w-1/2">
              <AppSMSForm />
            </div>
          </div>
        </div>
        <div className="py-4 border-t border-white border-opacity-25 text-white sm:flex">
          <div className="space-y-2">
            <a
              href="https://risescience.com"
              className="flex hover:underline"
              onClick={() => {
                Analytics.track('RiseScience.com clicked');
              }}
            >
              <div className="w-6 flex">
                <img src={riseR} alt="" className="ml-1" />
              </div>
              RiseScience.com
            </a>
            <a
              href="https://www.linkedin.com/company/rise-science/"
              className="flex hover:underline"
              onClick={() => {
                Analytics.track('LinkedIn clicked');
              }}
            >
              <div className="w-6 flex">
                <img src={linkedIn} alt="" />
              </div>
              LinkedIn
            </a>
            <a
              href="https://twitter.com/risescience"
              className="flex hover:underline"
              onClick={() => {
                Analytics.track('Twitter clicked');
              }}
            >
              <div className="w-6 flex">
                <img src={twitter} alt="" />
              </div>
              Twitter
            </a>
          </div>
          <div className="sm:flex-1 py-8 sm:py-0 sm:text-right text-sm text-gray-3">
            Made with low sleep debt in Chicago. © Rise Science 2020-2021
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
