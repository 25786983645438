import React from 'react'

type PillButtonProps = {
  title: string
  onPress: () => void
  icon?: string
}
const PillButton = ({ title, onPress, icon }: PillButtonProps) => {
  return (
    <button
      onClick={onPress}
      className="bg-purple-1 text-white rounded-full h-8 px-3 flex items-center justify-center"
    >
      {icon && <img src={icon} alt="icon" className="w-3 h-3 mr-1.5" />}
      <span className="text-sm">{title}</span>
    </button>
  )
}

export default PillButton
