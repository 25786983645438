import React, { useEffect, useState } from 'react'
import Analytics from '../../utils/Analytics'
import ButtonError from '../../components/ButtonError'
import ButtonProcessing from '../../components/ButtonProcessing'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import logo from '../../images/logo.svg'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import validateEmail from '../../utils/validateEmail'
import MarketingEvents from '../../utils/MarketingEvents'
import Cookies from 'js-cookie'
import ButtonSignUp from '../../components/ButtonSignUp'
import {
  abbreviateFixedDiscount,
  BASE_URL,
  useInviteIdFromParams,
  useOffer,
  useValueParams,
} from '../../utils/dynamicCodes/hooks'
import { DynamicCodeProps } from '../../utils/dynamicCodes/types'
import LoadingSpinner from '../../components/LoadingSpinner'

const DEFAULT_MARKETING_SOURCE = 'web_funnel_default'

interface SignUpRequestParams {
  email: string
  signUpSource?: string
  marketingSource: {
    code: string
  }
  invite_id?: string
  gympass_user_id?: string
}

type UiState = 'ready' | 'valid_email' | 'processing' | 'loading'
const NewUserOffer = ({ match }: DynamicCodeProps) => {
  const code = match.params.name

  const [processing, setProcessing] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [email, setEmail] = useState<string>('')
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [daysFree, setDaysFree] = useState(7)
  const history = useHistory()
  const offer = useOffer(code)
  const invite_id = useInviteIdFromParams()
  const gympassUserId = useValueParams('gympass_user_id')
  const [linkExistingUser, setLinkExistingUser] = useState<boolean>(false)

  const uiState: UiState = ((): UiState => {
    if (!offer) {
      return 'loading'
    }
    if (processing) {
      return 'processing'
    }
    if (emailIsValid) {
      return 'valid_email'
    }
    return 'ready'
  })()

  useEffect(() => {
    if (offer?.daysFree) {
      setDaysFree(offer.daysFree)
    }
  }, [offer])

  useEffect(() => {
    if (uiState === 'ready') {
      Analytics.track('Web Sign Up Page Viewed', {
        code: code,
      })
    }
  }, [code, uiState])

  useEffect(() => {
    const isValid = validateEmail(email)
    setEmailIsValid(isValid)
  }, [email])

  function createAccountEndpoint(_email: string, _code: string | null) {
    setError(false)
    setErrorMessage(null)
    setProcessing(true)
    const params: SignUpRequestParams = {
      signUpSource: 'web',
      email: _email,
      marketingSource: {
        code: _code ?? DEFAULT_MARKETING_SOURCE,
      },
    }

    if (invite_id !== null) {
      params['invite_id'] = invite_id
    }

    if (gympassUserId !== null) {
      params['gympass_user_id'] = gympassUserId
    }

    const url = linkExistingUser
      ? `${BASE_URL}/api/gympass/update-user`
      : `${BASE_URL}/api/web-create-account`

    axios
      .post(url, params)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Status: ${res.status} ${res.statusText}`)
        }
        const currentTimeMs = Math.floor(Date.now())
        const personId = res.data.person_id
        Analytics.identify(personId, {
          email: params.email, // TODO: should this be params.token?
          marketingSourceCode: params.marketingSource.code,
        })
        Analytics.track('Web Sign Up Success', {
          marketingSourceCode: params.marketingSource.code,
          invite_id,
        })
        const eventId = `sign_up_${personId}_${currentTimeMs}`
        MarketingEvents.sendMarketingEvent(
          MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
          {},
          { eventID: eventId }
        )
        MarketingEvents.sendConversionAPIEvent(
          MarketingEvents.FB_STANDARD_EVENTS.COMPLETE_REGISTRATION,
          eventId,
          {
            em: params.email,
            external_id: `${personId}`,
            fbp: Cookies.get('_fbp'),
          }
        )

        if (offer?.whitelistStatus) {
          history.push(
            `/offer/success?token=${encodeURIComponent(_email)}&code=${_code}`
          )
          return res.data
        }

        const existingUserURL = `/offer/${code}?token=${encodeURIComponent(
          _email
        )}&new_user=1`
        history.push(existingUserURL)
        return res.data
      })
      .catch((_error) => {
        Analytics.track('Web Sign Up Error', {
          error: _error,
          errorMessage: _error.response?.data?.error,
          marketingSourceCode: params.marketingSource.code,
          email: params.email,
          gympassUserId: gympassUserId ? gympassUserId : null,
        })
        if (
          _error.response.data?.error &&
          _error.response.data?.error.includes('Gympass')
        ) {
          setErrorMessage(_error.response.data.error)
        }
        setError(true)
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <div className="w-full h-screen mx-auto" id="new-years-2021">
      <form
        onSubmit={(event) => {
          if (uiState === 'valid_email') {
            createAccountEndpoint(email, code)
          }
          event.preventDefault()
        }}
      >
        <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
          <MaybeStagingBadge />
          <div className={'py-6 md:py-12'}>
            <img
              src={logo}
              alt="RISE"
              className="self-start"
              style={{ height: 16 }}
            />
          </div>
          <div
            className={`w-px-320 md:w-px-600 ${
              window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
            } sm:space-y-0 sm:pt-20`}
          >
            <div
              className="pt-8 flex flex-col"
              style={{
                visibility: uiState === 'loading' ? 'hidden' : 'visible',
                position: 'relative',
              }}
            >
              <LoadingSpinner uiState={uiState} />
              {offer?.whitelistStatus ? (
                <>
                  <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                    {linkExistingUser
                      ? 'Enter your RISE email to gain access with Gympass.'
                      : 'Enter your email address to get started with the RISE Sleep and Energy app.'}
                    <br />
                  </h1>
                  <p className="text-white text-sm">
                    {linkExistingUser
                      ? 'Enter the email address you used to sign up with RISE.'
                      : 'After you download the RISE app, you’ll use this same email address to log in.'}
                  </p>
                  {gympassUserId && !linkExistingUser ? (
                    <p className="text-white text-sm">
                      Already have a RISE account? Click{' '}
                      <span
                        className="text-purple-2"
                        onClick={() => setLinkExistingUser(true)}
                      >
                        here
                      </span>
                      .
                    </p>
                  ) : linkExistingUser ? (
                    <p className="text-white text-sm">
                      Don't have a RISE account? Click{' '}
                      <span
                        className="text-purple-2"
                        onClick={() => setLinkExistingUser(false)}
                      >
                        here
                      </span>
                      .
                    </p>
                  ) : null}
                </>
              ) : offer?.fixedDiscount ? (
                <>
                  <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                    <span className={'whitespace-nowrap'}>
                      {`${daysFree}`} days
                    </span>{' '}
                    free. Get {abbreviateFixedDiscount(offer)} off when you
                    join.
                  </h1>
                  <p className="text-white text-sm">
                    After you download the RISE app, you’ll use this same email
                    address to log in.
                  </p>
                </>
              ) : (
                <>
                  <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">
                    Enter your email address to get{' '}
                    <span className={'whitespace-nowrap'}>
                      {`${daysFree}`}-days
                    </span>{' '}
                    free.
                    <br />
                  </h1>
                  <p className="text-white text-sm">
                    After you download the RISE app, you’ll use this same email
                    address to log in and start your free {`${daysFree}`} days.
                  </p>
                </>
              )}
              <div className="flex justify-end my-8 pb-2 relative">
                <hr className="w-full border-t border-white opacity-25" />
              </div>
              <div className="text-white pb-3">
                <label className="block text-gray-700 text-sm mb-2 ">
                  Email address
                </label>
                <input
                  type="email"
                  name="name"
                  value={email}
                  className={`w-full md:w-2/3 form-input bg-panelGray-1 border py-3 rounded-none ${
                    error ? 'border-red-1' : 'border-gray-4'
                  }`}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && (
                  <div className="mt-2 text-red-1 text-sm font-bold">
                    {errorMessage !== null
                      ? errorMessage
                      : 'Oops, this email looks off. Review or try another.'}
                  </div>
                )}
              </div>
              {uiState === 'processing' && (
                <ButtonProcessing
                  testId="processingButton"
                  title="PROCESSING..."
                />
              )}

              {uiState === 'valid_email' && (
                <div className={'md:w-64'}>
                  <ButtonSignUp
                    testId="signUpButton"
                    title={
                      linkExistingUser
                        ? 'LINK WITH GYMPASS'
                        : 'CREATE PROFILE & DOWNLOAD'
                    }
                    onClick={() => createAccountEndpoint(email, code)}
                  />
                </div>
              )}

              {uiState === 'ready' && (
                <div className={'md:w-64'}>
                  <ButtonError
                    testId="signUpButtonNotReady"
                    title={
                      linkExistingUser
                        ? 'LINK WITH GYMPASS'
                        : 'CREATE PROFILE & DOWNLOAD'
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewUserOffer
