import React from 'react'

import sleepNeed from '../assets/sleep-need-no-text.png'
import {
  BodyCopy,
  BrandHeadline,
  Center,
  DoubleColumnSlide,
  ImageContainer,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const SleepNeed: React.FC = () => {
  useSlideTrackEvt('SleepNeed')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline ternary>Getting started</BrandHeadline>
        <LargeHeadline>
          Find <em>your</em> Sleep Need.
        </LargeHeadline>
        <BodyCopy>
          While most adults need 7-9 hours of sleep, the exact amount your body
          needs is unique depending on your genetics, age, and chronotype.
        </BodyCopy>
      </div>
      <Center>
        <ImageContainer>
          <img alt="Sleep need graphic" src={sleepNeed} />
        </ImageContainer>
      </Center>
    </DoubleColumnSlide>
  )
}
