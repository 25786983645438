import React, { ReactElement, useEffect, useState } from 'react';
import logo from '../../images/logo.svg';
import { useTokenFromParams } from '../../hooks/useTokenFromParams';
import { fetchUserConfiguration } from '../../utils/fetchUserConfiguration';
import { redirectToStripe } from '../../utils/redirectToStripe';
import Background from '../../components/Background';
import MaybeStagingBadge from '../../components/MaybeStagingBadge';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Analytics from '../../utils/Analytics';

type UiState = 'loading' | 'error';

function Redirect(): ReactElement {
  const [hasError, setError] = useState<boolean>(false);
  const token = useTokenFromParams();
  useDocumentTitle('New Member Offer | Rise Thanks You');
  const uiState: UiState = ((): UiState => {
    if (hasError) return 'error';
    return 'loading';
  })();

  useEffect(() => {
    Analytics.track('Headless redirect loaded');
    fetchUserConfiguration({ token, newUser: false })
      .then((config) => {
        return config;
      })
      .then(redirectToStripe)
      .catch((e) => {
        Analytics.track('Error with headless redirect', { ...e });
        setError(true);
      });
  }, [token]);

  return (
    <div className="w-full h-screen mx-auto">
      <Background disableImage />

      <div className="mx-auto w-px-320 sm:w-px-640 relative z-10">
        <div className="flex justify-between py-4">
          <img
            src={logo}
            alt="RISE"
            className="self-start"
            style={{ width: 66 }}
          />
        </div>
        <MaybeStagingBadge />
        {uiState === 'loading' && (
          <div className="flex flex-col justify-center items-center h-screen">
            <svg
              className="animate-spin -mt-48  w-12 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="2"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <div className="text-white mt-8 animate-pulse">
              Retrieving your offer...
            </div>
          </div>
        )}
        {uiState === 'error' && (
          <div className="flex flex-col  justify-center items-center h-screen">
            <div className="text-6xl -mt-48 ">😞</div>
            <div className="text-white mt-2 ">
              Sorry, we can't find your offer.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Redirect;
