import React from 'react'

import {
  BodyCopy,
  BrandHeadline,
  Content,
  DoubleColumnSlide,
  LargeHeadline,
  LawSidebar,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

import './Welcome.css'

export const Welcome: React.FC = () => {
  useSlideTrackEvt('Welcome')

  return (
    <DoubleColumnSlide>
      <div>
        <Content>
          <BrandHeadline>Welcome to RISE</BrandHeadline>
          <LargeHeadline>
            Waking up is about to get a whole lot easier.
          </LargeHeadline>
          <BodyCopy>
            Built on the 2 laws of sleep science, Sleep Debt and Circadian
            Rhythm, RISE helps you to understand the dynamics at play during
            your sleeping and waking hours, and empowers you to change your
            habits in favor of your personal sleep goals.
          </BodyCopy>
        </Content>
      </div>
      <div className="ob3__welcome-slide__lower">
        <LawSidebar condensed num={1} />
        <LawSidebar condensed num={2} />
      </div>
    </DoubleColumnSlide>
  )
}
