import React from 'react'
import { useLocation } from 'react-router-dom'

import userChase from './assets/users/chase-m.png'
import userMorgane from './assets/users/morgane-m.png'
import { ImagePreload, Layout } from './components'
import { Personality } from './slides/Testimonial'
import {
  Biology,
  CircadianRhythm,
  CircadianRhythmToSchedule,
  CustomizeHabits,
  Devices,
  EnableNotifications,
  HealthSync,
  LawOneTired,
  LawTwoDetails,
  Learn,
  SeeHowRiseCanHelp,
  SleepDebt,
  SleepNeed,
  Testimonial,
  TrackProgressOne,
  TrackSleepDebt,
  Welcome,
} from './slides'

/**
 * Onboarding Page
 */
export const Page: React.FC = () => {
  // Use search params to indicate to the layout if it is complete or not. When complete
  // the user will be shown the last slide first
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  return (
    <React.Fragment>
      <ImagePreload />
      <Layout isComplete={searchParams.has('complete')}>
        <Welcome />
        <LawOneTired />
        <Testimonial
          img={userChase}
          name="Chase M."
          personality={Personality.EarlyRiser}
        >
          RISE helped me understand how vital sleep truly is. In only a few
          weeks,{' '}
          <strong>
            I found myself more focused, energetic, and productive
          </strong>{' '}
          at work.
        </Testimonial>
        <LawTwoDetails />
        <Testimonial
          img={userMorgane}
          name="Morgane M."
          personality={Personality.NightOwl}
        >
          I&apos;m kinder to myself when I feel down or less energized.{' '}
          <strong>I know what to do</strong> to get my energy back when I need
          it.
        </Testimonial>
        <Biology />
        <Devices />
        <HealthSync />
        <SleepNeed />
        <SleepDebt />
        <CircadianRhythm />
        <SeeHowRiseCanHelp />
        <TrackSleepDebt />
        <CircadianRhythmToSchedule />
        <CustomizeHabits />
        <EnableNotifications />
        <Testimonial name="Johan G." personality={Personality.EarlyRiser}>
          <strong>I feel more rested</strong> when going to bed during my
          melatonin window.
        </Testimonial>
        <TrackProgressOne />
        <Learn />
      </Layout>
    </React.Fragment>
  )
}
