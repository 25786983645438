import { Elements } from '@stripe/react-stripe-js'
import { PaymentRequest, Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import MaybeStagingBadge from '../../../components/MaybeStagingBadge'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import PaymentIntentScreen from '../../../screens/StripeCustomCheckout/PaymentIntentScreen'
import Analytics from '../../../utils/Analytics'
import Environment from '../../../utils/Environment'
import { useQueryToken, useValueParams } from '../../../utils/dynamicCodes/hooks'
import { reportRepeatCheckout } from '../../../utils/dynamicCodes/redirects'
import TrialDetailsList from '../components/TrialDetailsList'
import TrialSelectionCells from '../components/TrialSelectionCells'
import {
  createCheckoutDetails,
  createPaymentRequest,
  createStripeInstance,
} from '../setupStripeForEmailConfirmationPaywall'
import './EmailConfirmation.css'
import { getEmailConfirmationProducts, ProductCode } from '../products'
import { SupportedCountryCode } from '../../../utils/currency'
import { useTranslation } from 'react-i18next'

export const stripeStyle = {
  colorPrimary: '#0570de',
  colorBackground: '#30313d',
  colorText: 'white',
  colorDanger: '#df1b41',
  borderRadius: '4px',
  colorPrimaryText: 'white',
  fontSizeSm: '1rem',
}

export type EmailConfirmationCheckoutScreenProps = {
  initialOption: ProductCode
  otherOption: ProductCode
  isStudent: boolean
}

const allowedLocale = (locale: string): SupportedCountryCode => {
  locale = locale.toUpperCase()

  if (['US', 'GB', 'AU', 'CA', 'DE'].includes(locale)) {
    return locale as SupportedCountryCode
  }
  const otherEuroCountries = [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'GR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PT',
    'SK',
    'SI',
    'ES',
  ]
  if (otherEuroCountries.includes(locale)) {
    // FR and DE share EUR formatting and prices
    return 'DE'
  }
  return 'US'
}

const EmailConfirmationCheckoutScreen = (props: EmailConfirmationCheckoutScreenProps) => {
  useTranslation() // will ensure the correct translation is used in `getEmailConfirmationProducts`
  const { initialOption, otherOption, isStudent } = props
  useDocumentTitle('Rise')
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null)
  const token = useQueryToken()
  const localeParam: string = useValueParams('locale') ?? 'US'
  const locale = allowedLocale(localeParam)
  const history = useHistory()
  const [trialOption, setTrialOption] = useState<ProductCode>(initialOption)

  const emailConfirmationProducts = getEmailConfirmationProducts()
  const product = emailConfirmationProducts[trialOption][locale]

  const [options, setOptions] = useState<StripeElementsOptions>({})
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined)
  const [paymentRequestLoading, setPaymentRequestLoading] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [duplicateCustomer, setDuplicateCustomer] = useState<boolean>(false)
  const [chargeDate, setChargeDate] = useState<string>('')

  useEffect(() => {
    Analytics.track('Email Confirmation Checkout Viewed', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
      locale,
      isStudent,
    })
  }, [token, locale, isStudent])

  // Setup Client Secret and Stripe Instance
  useEffect(() => {
    const setupStripe = async () => {
      if (token && !stripeInstance) {
        const checkoutDetails = await createCheckoutDetails(token)
        if ('error' in checkoutDetails) {
          if (checkoutDetails.duplicateCustomer) {
            setDuplicateCustomer(true)
          } else {
            Analytics.track('Unable to set up Stripe Custom Checkout Page', {
              env: Environment.getVar('REACT_APP_ENV') ?? 'test',
              email: token,
            })
          }
        } else {
          const stripe = await createStripeInstance()
          if (stripe) {
            setStripeInstance(stripe)
          }
          setClientSecret(checkoutDetails.client_secret)
          setChargeDate(checkoutDetails.charge_date)
          setOptions(checkoutDetails.options)
        }
      }
    }
    if (token) {
      setupStripe()
    }
  }, [stripeInstance, token])

  // Fire analytics for user who has a duplicate customer
  useEffect(() => {
    if (duplicateCustomer) {
      reportRepeatCheckout(product.code, token)
    }
  }, [duplicateCustomer, product.code, token])

  // Create Payment Request - this will be recreated when the user changes the trial option
  useEffect(() => {
    if (stripeInstance) {
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: product.sheetLabel,
            amount: product.price,
          },
        })
      } else {
        createPaymentRequest(stripeInstance, product.code, product.price, product.sheetLabel, locale).then(
          (paymentRequest) => {
            setPaymentRequest(paymentRequest)
            setPaymentRequestLoading(false)
          }
        )
      }
    } else {
      console.log('Unable to setup payment request, stripeInstance is null')
    }
  }, [paymentRequest, product.code, product.price, product.sheetLabel, stripeInstance, trialOption, locale])

  const handleTrialSelection = (trialOption: ProductCode) => {
    Analytics.track('Email Confirmation Trial Selection', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
      trialOption,
    })
    setTrialOption(trialOption)
  }

  const handlePurchaseSuccess = ({ existingAccount }: { existingAccount: boolean }) => {
    Analytics.track('Email Confirmation Purchase Success', {
      env: Environment.getVar('REACT_APP_ENV') ?? 'test',
      email: token,
      trialOption,
    })
    history.push(`/email-confirmation-trial?token=${token}&days=${product.daysFree}`)
  }
  const refToScroll = useRef<HTMLDivElement>(null)

  const onCreditCardChoice = () => {
    refToScroll.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Helmet>
        {/* Prevents zooming into the text input when entering a credit card number */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className={`w-full h-screen mx-auto checkout-screen-background`}>
        <video
          autoPlay
          muted
          playsInline
          loop={true}
          id="Rise"
          className="fixed top-0 left-0 w-full object-cover object-top"
          style={{ marginTop: '-5vh' }}
        >
          <source
            src="https://nyx-content-6xgk79jynn.s3.amazonaws.com/onboarding/paywall-v1b-edit-1290x2796-compressed-fast.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>

        <div
          ref={refToScroll}
          style={{
            position: 'absolute',
            left: 0,
            top: '25vh',
            backgroundColor: 'transparent',
            height: '1px',
            width: '100%',
          }}
        />
        <div className={`checkout-screen-content`}>
          <div style={{ minHeight: window.innerHeight }} className={`h-screen w-full`}>
            <div className="pt-2 flex flex-col">
              <TrialSelectionCells
                onSelect={handleTrialSelection}
                selection={trialOption}
                products={[
                  getEmailConfirmationProducts()[initialOption][locale],
                  getEmailConfirmationProducts()[otherOption][locale],
                ]}
                isStudent={isStudent}
              />
              <div style={{ paddingTop: '12px' }}>
                <TrialDetailsList trialOption={getEmailConfirmationProducts()[trialOption][locale]} />
              </div>
              <div>
                {duplicateCustomer ? (
                  <div className={'text-white'}>Your subscription is active!</div>
                ) : Object.keys(options).length && stripeInstance ? (
                  <div className={'pl-5 pr-5'}>
                    <Elements stripe={stripeInstance} options={options}>
                      <PaymentIntentScreen
                        code={product.code}
                        paymentRequest={paymentRequest}
                        clientSecret={clientSecret}
                        daysFree={product.daysFree}
                        chargeDate={chargeDate}
                        dynamicCheckout={true}
                        onPurchaseSuccess={handlePurchaseSuccess}
                        paymentRequestLoading={paymentRequestLoading}
                        onCreditCardChoice={onCreditCardChoice}
                        ctaText={product.daysFree === 7 ? `REDEEM 7 DAYS FREE` : `REDEEM ${product.daysFree} DAYS`}
                      />
                    </Elements>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <MaybeStagingBadge />
      </div>
    </>
  )
}

export default EmailConfirmationCheckoutScreen
