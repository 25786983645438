import React from 'react'

interface RiseRadioButtonProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

export const RiseRadioButton: React.FC<RiseRadioButtonProps> = ({
  checked,
  onChange,
}) => {
  return (
    <div
      className="border-2 border-purple-2"
      style={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',

        padding: 3,
        cursor: 'pointer',
      }}
      onClick={() => onChange(!checked)}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          backgroundColor: checked ? 'white' : 'black',
        }}
      />
    </div>
  )
}
