import React, { ReactElement } from 'react';

interface Props {
  title: string;
  testId: string;
  onClick: () => void;
  className?: string;
}

function ButtonRedeem({
  title,
  testId,
  onClick,
  className,
}: Props): ReactElement {
  return (
    <button
      data-test-id={testId}
      className={`btn transition-opacity duration-500 ease-in-out h-16 ${className}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default ButtonRedeem;
