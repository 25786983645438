import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import {getDocumentTitleByUrl, screenConfigs} from "./constants";
import EmailSignUp from "../../screens/WebOnboarding2/EmailSignUp/EmailSignUp";
import JobSelection from "../../screens/WebOnboarding2/JobSelection/JobSelection";
import SelfReportedWeekday from "../../screens/WebOnboarding2/SelfReportedWeekday/SelfReportedWeekday";
import SelfReportedFreeday from "../../screens/WebOnboarding2/SelfReportedFreeDay/SelfReportedFreeDay";
import SelfReportedConfirmation from "../../screens/WebOnboarding2/SelfReportedConfirmation/SelfReportedConfirmation";
import SleepNeed from "../../screens/WebOnboarding2/SleepNeed/SleepNeed";
import SleepDebt from "../../screens/WebOnboarding2/SleepDebt/SleepDebt";
import EnergyProfile from "../../screens/WebOnboarding2/EnergyProfile/EnergyProfile";
import StripeConfirmation from "../../screens/WebOnboarding2/StripeConfirmation/StripeConfirmation";
import { sendAnalytics } from "./webOnboardingAnalytics";
import Paywall from "../../screens/WebOnboarding2/Paywall/Paywall";


export const WebOnboarding2Routes = () => {
    const history = useHistory();

    useEffect(() => {
        trackPageView(); // To track the first view upon load
        history.listen(trackPageView); // To track the subsequent views
    }, [history]);

    const trackPageView = () => {
        const viewName = getDocumentTitleByUrl(window.location.pathname);
        sendAnalytics(`${viewName} Viewed (Web Onboarding 2.0)`)
    }

    return (
        <React.Fragment>
            <Route path={screenConfigs.emailSignUp.url}>
                <EmailSignUp/>
            </Route>
            <Route path={screenConfigs.jobSelection.url}>
                <JobSelection/>
            </Route>
            <Route path={screenConfigs.selfReportedWeekday.url}>
                <SelfReportedWeekday/>
            </Route>
            <Route path={screenConfigs.selfReportedFreeday.url}>
                <SelfReportedFreeday/>
            </Route>
            <Route path={screenConfigs.selfReportedConfirmation.url}>
                <SelfReportedConfirmation/>
            </Route>
            <Route path={screenConfigs.sleepNeed.url}>
                <SleepNeed/>
            </Route>
            <Route path={screenConfigs.sleepDebt.url}>
                <SleepDebt/>
            </Route>
            <Route path={screenConfigs.energyProfile.url}>
                <EnergyProfile/>
            </Route>
            <Route path={screenConfigs.paywall.url}>
                <Paywall />
            </Route>
            <Route path={screenConfigs.stripeConfirmation.url}>
                <StripeConfirmation/>
            </Route>
        </React.Fragment>
    )
}
