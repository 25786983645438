import React from 'react'

import appInteractions from '../assets/in-app-interactions.png'
import {
  BodyCopy,
  BrandHeadline,
  Content,
  DoubleColumnSlide,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const Biology: React.FC = () => {
  useSlideTrackEvt('Biology')

  return (
    <DoubleColumnSlide>
      <div>
        <Content>
          <BrandHeadline ternary>Getting started</BrandHeadline>
          <LargeHeadline>A program based on your biology.</LargeHeadline>
          <BodyCopy>
            While everyone experiences energy peaks and dips throughout the day,
            when you feel them depends on several factors that are specific to
            you including your age, gender, and health conditions.
          </BodyCopy>
        </Content>
      </div>
      <div>
        <img alt="Interactions with the RISE app" src={appInteractions} />
      </div>
    </DoubleColumnSlide>
  )
}
