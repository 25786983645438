import React from 'react'

import phone from '../assets/phones/circadian-rhythm-to-schedule-phone.png'
import {
  BodyCopy,
  BrandHeadline,
  DoubleColumnSlide,
  GradientPhone,
  LargeHeadline,
} from '../components'
import { useSlideTrackEvt } from '../hooks'

export const CircadianRhythmToSchedule: React.FC = () => {
  useSlideTrackEvt('CircadianRhythmToSchedule')

  return (
    <DoubleColumnSlide>
      <div>
        <BrandHeadline secondary>Using RISE</BrandHeadline>
        <LargeHeadline>Plan the day with your energy schedule.</LargeHeadline>
        <BodyCopy>
          Anticipate your energy peaks and dips during your day so you can
          schedule activities that either provide a boost or allow for the rest
          you need.
        </BodyCopy>
      </div>
      <div>
        <GradientPhone>
          <img alt="Circadian curve on RISE app" src={phone} />
        </GradientPhone>
      </div>
    </DoubleColumnSlide>
  )
}
